import React from 'react'
import { Table, Space, Row, Col, Card, Divider } from 'antd';
import { EditOutlined, DeleteOutlined } from '@ant-design/icons'

const { Meta } = Card;

function ViewVendor() {
  return (
    <>
      <Row gutter={24} wrap={false}>
        <Col flex="310px">
          <Card
            style={{ padding: '15px' }}
          >
            <Meta
              title="OTHER"
              style={{ textAlign: 'center' }}
            />
            <div style={{ marginTop: '10px', textAlign: 'center' }}>
              <p> other@ctrade.us</p>
            </div>
            <Divider />
            <div className='profile-info'>
              <p><strong>Phone:</strong> 12345678900</p>
              <p><strong>Address:</strong> Sharjah</p>
              <p><strong>Town:</strong> Sharjah</p>
              <p><strong>City:</strong> Sharjah</p>
              <p><strong>Country:</strong> United Arab Emirates</p>
              <p><strong>Secondary Email :</strong> ------ ----</p>

            </div>
            <Divider />
            <div style={{ textAlign: 'center' }}>
              <Space>
                <EditOutlined style={{ fontSize: '20px', cursor: 'pointer' }} />
                <DeleteOutlined style={{ fontSize: '20px', cursor: 'pointer' }} />
              </Space>
            </div>
          </Card>
        </Col>
        <Col flex="1">
          <div style={{ padding: '15px', border: '1px solid #ccc', borderRadius: '8px', backgroundColor: "#FFFFFF" }}>
            <Row gutter={12}>
              <Col span={12}>
                <h1 style={{ fontWeight: "bold" }}>Browse Vendors</h1>
              </Col>
            </Row>
            <Table style={{ marginTop: '10px' }} />
          </div>
        </Col>
      </Row>

      <style>
        {`
  .profile-info {
    display: flex;
    flex-direction: column; /* Stack items vertically */
    gap: 15px; /* Space between each <p> element */
  }

  .profile-info p {
    display: flex; /* Makes each <p> a flex container */
    align-items: center; /* Vertically align <strong> and text */
  }

  .profile-info strong {
    margin-right: 25px; /* Space between <strong> and <p> content */
    width: 100px; /* You can adjust this value to ensure equal space between all labels */
    text-align: left; /* Optional: Align the <strong> to the left */
  }
`}
      </style>
    </>
  )
}

export default ViewVendor

