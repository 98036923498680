import React, { useState } from 'react';
import { Button, Col, DatePicker, Form, Input, Row, Select, Table, Upload } from 'antd';
import { DeleteOutlined } from '@ant-design/icons'

function LoadingAdd() {
    const [form] = Form.useForm();

    const columns = [
        {
            title: "Vehicle",
            dataIndex: 'Vehicle',
            key: 'Vehicle',
        },
        {
            title: "Chassis Number",
            dataIndex: 'Chassis_Number',
            key: 'Chassis_Number',
        },
        {
            title: (<div>
                <DeleteOutlined style={{ fontSize: "18px" }} />
            </div>),
            key: 'Delete',
            render: (text, { _id }) => (
                <Button type='primary' size="small">
                    <DeleteOutlined />
                </Button>
            )
        },
    ]
    const data = [{


        Vehicle: "FORD",
        Chassis_Number: "1FDUF5HT0RDA27180",


    }]
    return (
        <div style={{ padding: '15px', border: '1px solid #ccc', borderRadius: '8px', backgroundColor: "#FFFFFF" }}>
            <Row gutter={26} style={{ marginBottom: 10, padding: '5px', }}  >
                <Col span={12}>
                    <h1 style={{ fontWeight: 'bold' }}>Add Plans</h1>
                </Col>
            </Row>
            <Form
                requiredMark={false}
                form={form}
                layout="vertical"
                initialValues={{ layout: "vertical" }}  >
                <Row gutter={24} >

                    <Col span={8}>
                        <Form.Item label="Booking Number">
                            <Input placeholder="" />
                        </Form.Item>
                    </Col >
                    <Col span={8}>
                        <Form.Item label="Container Number">
                            <Input placeholder="" />
                        </Form.Item>
                    </Col >
                    <Col span={8}>
                        <Form.Item label="Load Number">
                            <Input placeholder="" />
                        </Form.Item>
                    </Col >
                    <Col span={6}>
                        <Form.Item label="Add New Car">
                            <Input placeholder="" />
                        </Form.Item>
                    </Col >
                    <Col span={6}>
                        <Form.Item label="Add Reload Car">
                            <Input placeholder="" />
                        </Form.Item>
                    </Col >
                    <Col span={6}>
                        <Form.Item
                            label="Shipping Line"
                            name="Shipping Line"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please input!',
                                },
                            ]}
                        >
                            <Select placeholder="" />
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item label="Location">
                            <Input placeholder="" />
                        </Form.Item>
                    </Col >
                    <Col span={24}>
                        <Form.Item style={{ textAlign: 'center', marginTop: 30 }}>
                            <Button type="primary" style={{ width: '50%' }}>Add</Button>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>

            <Form
                requiredMark={false}
                layout="vertical"
                initialValues={{ layout: "vertical" }} >
                <Row gutter={24} style={{ padding: 20 }}>
                    <Col span={8}>
                        <Form.Item
                            label="Destination"
                            name="Destination"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please input!',
                                },
                            ]}
                        >
                            <Select placeholder="BENGHAZI" />
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item
                            label="Container Size"
                            name="Container Size"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please input!',
                                },
                            ]}
                        >
                            <Select placeholder="BENGHAZI" />
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item label="Pull Out Date ">
                            <DatePicker style={{ width: '100%' }} placeholder="mm/dd/yyyy" />
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item label="Gate In Date">
                            <DatePicker style={{ width: '100%' }} placeholder="mm/dd/yyyy" />
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item label="Customized Date">
                            <DatePicker style={{ width: '100%' }} placeholder="mm/dd/yyyy" />
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item label="ETA Date">
                            <DatePicker style={{ width: '100%' }} placeholder="mm/dd/yyyy" />
                        </Form.Item>
                    </Col>
                </Row>
            </Form>


            <Table columns={columns} pagination={false} scroll={{ x: "max-content" }} dataSource={data} style={{ marginTop: '5px' }} />

            <Row gutter={24} style={{ marginTop: 40, display: 'flex', justifyContent: 'flex-end' }}>
                            <Col span={4}>
                                <Upload
                                    id="uploadInput"
                                    action="/upload"
                                    listType="picture"

                                >
                                    <Button
                                        type="dashed"
                                        style={{
                                            width: '100%',
                                            border: '1px solid #ccc',
                                            padding: 15,

                                        }}
                                    >
                                        <img src="/images/Group 1178.png" alt="" width="25px" />
                                        Upload Image
                                    </Button>
                                </Upload>
                            </Col>

                            <Col span={6}>
                                <Button type="primary" style={{ width: '100%' }}>
                                SAVE
                                </Button>
                            </Col>
                            <Col span={6}>
                                <Button type="primary" style={{ width: '100%' }}>
                                LOAD
                                </Button>
                            </Col>
                        </Row>
        </div>

    );
};

export default LoadingAdd

