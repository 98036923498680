
import React, { useState } from 'react'
import { Table, Space, Button, Row, Col, Input, Select, Modal, Form,  Upload } from 'antd';
import { SearchOutlined, FilePdfOutlined } from '@ant-design/icons'
import { Divider } from 'antd';
import { Option } from 'antd/es/mentions';
import { CiSquarePlus } from "react-icons/ci";


function ExpenseVoucher() {
  const [isModalShow, setIsModalShow] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const [showAllRows, setShowAllRows] = useState(false);
  const showModal = () => setIsModalShow(true);
  const openModal = () => setIsModalOpen(true)

  const handleOk = () => {
    setIsModalShow(false)
    setIsModalOpen(false)
  }
  const handleCancel = () => {
    setIsModalShow(false)
    setIsModalOpen(false)
  }
  const handlePlusClick = () => {
    setShowAllRows(true);
  };

  const options = [
    {
      value: '10',
      label: '10',
    },
    {
      value: '9',
      label: '9',
    },
  ]
  const columns = [
    {
      title: "Id",
      dataIndex: 'Id',
      key: '_id',
      render: (text, record, index) => index + 1,
    },
    {
      title: "Payment Date",
      dataIndex: 'PaymentDate',
      key: 'PaymentDate',
    },
    {
      title: "PV Number",
      dataIndex: 'PVNumber',
      key: 'PVNumber',
    },
    {
      title: "Company",
      dataIndex: 'Company',
      key: 'Company',
    },
    {
      title: "Vendor",
      dataIndex: 'Vendor',
      key: 'Vendor',
    },
    {
      title: "Total Amount($)",
      dataIndex: 'TotalAmount($)',
      key: 'Total Amount($)',
    },
    {
      title: "Issue Date",
      dataIndex: 'IssueDate',
      key: 'IssueDate',
    },

    {
      title: 'Action',
      key: 'Action',
      render: (text, { _id }) => (
        <Space size='small'>
          <Button type='primary' size="small">
            <FilePdfOutlined />
          </Button>
        </Space >
      )
    },
  ];
  const data = [
    
  ];

  const columns2 = [
    {
      title: "Expense Account",
      dataIndex: 'ExpenseAccount',
      key: 'ExpenseAccount',
      render: (text, record) => (
        <Select
          defaultValue={text}
          style={{ width: '100%' }}  >
          <Option value="User1">User1</Option>
          <Option value="User2">User2</Option>
          <Option value="User3">User3</Option>
        </Select>
      ),
    },
    {
      title: "Description",
      dataIndex: "Description",
      key: "Description",
      render: (text) => (
        <Input
          defaultValue={text}
          style={{ width: "100%" }}
        />
      ),
    },
    {
      title: "Due Amount",
      dataIndex: "DueAmount",
      key: "NoOfVehicles",
      render: (text) => (
        <Input
          defaultValue={text}
          style={{ width: "100%" }}
        />
      ),
    },
    {
      title: "Pay Amount",
      dataIndex: 'PayAmount',
      key: 'PayAmount',
      render: (text, record) => (
        <Input
          defaultValue={text}
          style={{ width: "100%" }}
        />
      ),
    },
    {
      title: "Bal. Amount",
      dataIndex: "Bal.Amount",
      key: "Bal.Amount",
      render: (text) => (
        <Input
          defaultValue={text}
          style={{ width: "100%" }}
        />
      ),
    },
  ]
  const data2 = [
    {
      key: '13574',
      ShippingLine: '',
      Destination: '',
      ContainerSize: '',
      Vehicle: '',
      FrightCharges: '',
      NoOfVehicles: '',
      LoadingCharges: "",

    }, {
      key: '13574',
      ShippingLine: '',
      Destination: '',
      ContainerSize: '',
      Vehicle: '',
      FrightCharges: '',
      NoOfVehicles: '',
      LoadingCharges: "",

    },


  ]
  const displayedData = showAllRows ? data2 : data2.slice(0, 1);
  return (
    <div style={{ padding: '15px', border: '1px solid #ccc', borderRadius: '8px', backgroundColor: "#FFFFFF" }}>
      <Row gutter={[24, 16]} align="middle" >
        <Col xs={24} md={8}>
          <h1 style={{ fontWeight: "bold", margin: 0 }}>Expense Voucher</h1>
        </Col>
        <Col xs={24} md={16} style={{ textAlign: "right" }}>
          <Space wrap>
            <Button type="primary" onClick={showModal}>Add New Expense</Button>
            <Button type="primary" onClick={openModal}>Add Payable Payments</Button>
          </Space>
        </Col>
      </Row>
      <Divider style={{ marginTop: 15 }} />
      <Row gutter={24} style={{ marginTop: '15px', background: "#F2F2F7", padding: "10px", borderRadius: '4px' }} className='flex justify-between'>
        <Col span={10}  >
          <Space direction="horizontal" size={12} >
            <p>Show</p>
            <Select defaultValue="10"
              options={options}
              size="small"
              // dropdownMatchSelectWidth={false} // Prevent dropdown from inheriting the small width
              style={{
                width: 60,
                height: 25,
                fontSize: 14,
              }} />
            <p>entries</p>
          </Space>
        </Col>
        <Col span={14} style={{ textAlign: "right" }} >
          <Input
            placeholder="Search"
            prefix={<SearchOutlined />}
            className="w-auto lg:w-2/5"
          />
        </Col>
      </Row>


      <Modal
        title={<div style={{ width: "100%" }}>Make Expense</div>}
        open={isModalShow}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={null}
        closable={true}
        width={700}
        bodyStyle={{ padding: "15px" }}
      >
        <Form layout="vertical" requiredMark={false}>
          <Row gutter={24}>
            <Col span={8}>
              <Form.Item
                label="Expense Account"
                name="ExpenseAccount"
                rules={[{ required: true, message: "Please select an appointment type" }]}
              >
                <Select placeholder="Select Inter-Company">
                  <Select.Option value="Online">Online</Select.Option>
                  <Select.Option value="Offline">Offline</Select.Option>
                </Select>
              </Form.Item>
            </Col>

            <Col span={8}>
              <Form.Item
                label="Sub-Account"
                name="Sub-Account"
                rules={[{ required: true, message: "Please select an appointment type" }]}
              >
                <Select placeholder="Select Inter-Company">
                  <Select.Option value="Online">Online</Select.Option>
                  <Select.Option value="Offline">Offline</Select.Option>
                </Select>
              </Form.Item>
            </Col>

            <Col span={8}>
              <Form.Item
                label="Voucher Number"
                name="VoucherNumber"
                rules={[{ required: true, message: "Please enter the end time" }]}
              >
                <Input placeholder="2024-11-06" />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                label="Voucher Date"
                name="VoucherDate"
                rules={[{ required: true, message: "Please enter the end time" }]}
              >
                <Input placeholder="2024-11-06" />
              </Form.Item>
            </Col>

            <Col span={8}>
              <Form.Item
                label="Vendor Name"
                name="VendorName"
                rules={[{ required: true, message: "Please select an appointment type" }]}
              >
                <Select placeholder="Select type">
                  <Select.Option value="Online">Online</Select.Option>
                  <Select.Option value="Offline">Offline</Select.Option>
                </Select>
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                label="Vendor Invoice No"
                name="VendorInvoiceNo"
                rules={[{ required: true, message: "Please enter the end time" }]}
              >
                <Input placeholder="2024-11-06" />
              </Form.Item>
            </Col>

            <Col span={8}>
              <Form.Item
                label="Amount"
                name="Amount"
                rules={[{ required: true, message: "Please select an appointment type" }]}
              >
                <Select placeholder="Select type">
                  <Select.Option value="Online">Online</Select.Option>
                  <Select.Option value="Offline">Offline</Select.Option>
                </Select>
              </Form.Item>
            </Col>

            <Col span={8}>
              <Form.Item
                label="Container No"
                name="ContainerNo"
                rules={[{ required: true, message: "Please enter the end time" }]}
              >
                <Input placeholder="2024-11-06" />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                label="Booking No"
                name="Booking No"
                rules={[{ required: true, message: "Please enter the end time" }]}
              >
                <Input placeholder="2024-11-06" />
              </Form.Item>
            </Col>
            <Col span={16}>
              <Form.Item
                label="Due Date"
                name="DueDate"
                rules={[{ required: true, message: "Please enter the end time" }]}
              >
                <Input placeholder="2024-11-06" />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                label="Attachment" >
                <Upload
                  id="uploadInput"
                  action="/upload"
                  listType="picture"
                  style={{
                    width: '100%', // Ensures the upload spans the full width of its container
                  }}   >
                  <Button
                    type="dashed"
                    style={{
                      width: '100%',
                      border: '1px solid #ccc',
                      padding: 18,
                    }}>
                    <div style={{ paddingRight: 10, borderRight: "1px solid #ccc" }}>
                      <img src="/images/Group 1178.png" alt="" width="30px" />
                    </div>
                    No File Chosen
                  </Button>
                </Upload>
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                label="Description" >
                <Input.TextArea
                  placeholder="Enter special notes here..."
                  rows={5}
                />
              </Form.Item>
            </Col>

          </Row>
          <div style={{ display: 'flex', justifyContent: 'flex-end', gap: 10 }}>
            <Col span={8} style={{ textAlign: "right" }}>
              <Form.Item style={{ marginTop: 10, textAlign: "right" }}>
                <Button type="primary" style={{ width: '100%' }}>Save For Future Payment</Button>
              </Form.Item>
            </Col>
            <Col span={6} style={{ textAlign: "right" }}>

              <Form.Item style={{ marginTop: 10, textAlign: "right" }}>
                <Button type="primary" style={{ width: '100%' }}>Make a Payment</Button>
              </Form.Item>

            </Col>
          </div>
        </Form>
      </Modal>

      <Modal
        title={<div style={{ width: "100%" }}>Make a Payment</div>}
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={null}
        closable={true}
        width={700}
        bodyStyle={{ padding: "15px" }}
      >
        <Form layout="vertical" requiredMark={false}>
          <Row gutter={24}>
            <Col span={8}>
              <Form.Item
                label="Expense Account"
                name="ExpenseAccount"
                rules={[{ required: true, message: "Please select an appointment type" }]}
              >
                <Select placeholder="Select Inter-Company">
                  <Select.Option value="Online">Online</Select.Option>
                  <Select.Option value="Offline">Offline</Select.Option>
                </Select>
              </Form.Item>
            </Col>

            <Col span={8}>
              <Form.Item
                label="Sub-Account"
                name="Sub-Account"
                rules={[{ required: true, message: "Please select an appointment type" }]}
              >
                <Select placeholder="">
                  <Select.Option value="Online">Online</Select.Option>
                  <Select.Option value="Offline">Offline</Select.Option>
                </Select>
              </Form.Item>
            </Col>

            <Col span={8}>
              <Form.Item
                label="Voucher Number"
                name="VoucherNumber"
                rules={[{ required: true, message: "Please enter the end time" }]}
              >
                <Input placeholder="2024-11-06" />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                label="Voucher Date"
                name="VoucherDate"
                rules={[{ required: true, message: "Please enter the end time" }]}
              >
                <Input placeholder="2024-11-06" />
              </Form.Item>
            </Col>

            <Col span={8}>
              <Form.Item
                label="Vendor Name"
                name="VendorName"
                rules={[{ required: true, message: "Please select an appointment type" }]}
              >
                <Select placeholder="">
                  <Select.Option value="Online">Online</Select.Option>
                  <Select.Option value="Offline">Offline</Select.Option>
                </Select>
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                label="Vendor Invoice No"
                name="VendorInvoiceNo"
                rules={[{ required: true, message: "Please enter the end time" }]}
              >
                <Input placeholder="" />
              </Form.Item>
            </Col>
            <Col span={16}>
              <Form.Item
                label="Special Note:" >
                <Input.TextArea
                  placeholder="Enter special notes here..."
                  rows={5}
                />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                label="Attachment" >
                <Upload
                  id="uploadInput"
                  action="/upload"
                  listType="picture"
                  style={{
                    width: '100%', // Ensures the upload spans the full width of its container
                  }}   >
                  <Button
                    type="dashed"
                    style={{
                      width: '100%',
                      border: '1px solid #ccc',
                      padding: 18,
                    }}>
                    <div style={{ paddingRight: 10, borderRight: "1px solid #ccc" }}>
                      <img src="/images/Group 1178.png" alt="" width="30px" />
                    </div>
                    No File Chosen
                  </Button>
                </Upload>
              </Form.Item>
            </Col>
            <Col span={24}>
              <Table columns={columns2} scroll={{ x: "max-content" }} dataSource={displayedData} pagination={false} rowKey="Id" style={{ marginTop: '10px' }} />
              <div className='flex justify-between mt-5'>
                <p>
                  Total Paying Amount
                </p>
                <p>
                  $0.00
                </p>
              </div>
              <Divider />
              <div style={{ marginTop: 20 }} className='flex flex-col justify-center items-center'>
                <CiSquarePlus style={{ cursor: 'pointer', fontSize: '25px' }} onClick={handlePlusClick} />
              </div>
            </Col>
          </Row>
        </Form>
        <Form.Item style={{ textAlign: 'right', marginTop: 20 }}>
          <Button type="primary" style={{ width: '20%' }}>Make a Payment</Button>
        </Form.Item>
      </Modal>


      <Table columns={columns} scroll={{ x: "max-content" }} dataSource={data} style={{ marginTop: '10px' }} />
    </div>
  )
}

export default ExpenseVoucher
