import React, { useState } from 'react'
import { Table, Space, Tag, Button, Row, Col, Modal, Form, Input, DatePicker, TimePicker, Checkbox, } from 'antd';
import { EyeOutlined, EditOutlined, DeleteOutlined } from '@ant-design/icons'
import ScheduleForm from './ScheduleForm';

function BookSchedule() {

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [editData, setEditData] = useState(null);
  
  const handleEdit = (record) => {
    setEditData(record); 
    setModalVisible(true);
  };
  const handleModalClose = () => {
    setModalVisible(false);
  }

  const showModal = () => setIsModalOpen(true);
  const visibleModal=()=> setIsModalVisible(true)

  const handleOk = () => {
    setIsModalOpen(false)
    // setIsModalShow(false)
    setIsModalVisible(false)
  }
  const handleCancel = () => {
    setIsModalOpen(false)
    // setIsModalShow(false)
    setIsModalVisible(false)
  }

 const columns = [
    {
      title: "#",
      dataIndex: '#',
      key: '#   ',
      render: (text, record, index) => index + 1,
    },
    {
      title: "Date",
      dataIndex: 'Date',
      key: 'Date',
    },
    {
      title: "Start time",
      dataIndex: 'Starttime',
      key: 'Starttime',
    },
    {
      title: "End time",
      dataIndex: 'Endtime',
      key: 'Endtime',
    },
    {
      title: "Appointment Allowed",
      dataIndex: 'AppointmentAllowed',
      key: 'AppointmentAllowed',
    }, {
      title: "Appointment Confirm",
      dataIndex: 'AppointmentConfirm',
      key: 'AppointmentConfirm',
    },
    {
      title: "Appointment Left",
      dataIndex: 'AppointmentLeft',
      key: 'AppointmentLeft',
    },
    {
      title: "Status",
      dataIndex: 'Status',
      key: 'Status',
      render: (status) => (
        <Tag color="#06D652" style={{ border: '1px solid #06D652', padding: '5px' }}>
          {status}
        </Tag>
      ),
    },
    {
      title: "Actions",
      key: 'Actions',
      render: (text,record) => (
        <Space size='small'>
          <Button type='primary' size="small" onClick={() => handleEdit(record)}>
            <EditOutlined />
          </Button>

          <Button type='primary' size="small" onClick={showModal}>
            {/* / <a href="/quotationview"> */}
            <EyeOutlined />
            {/* </a> */}
          </Button>

          <Button type='primary' size="small" onClick={visibleModal}>
            <DeleteOutlined />
          </Button>
        </Space >
      )
    },

  ];
  const data = [
    {
      key: '1',
      Date: "07 Jan-2023",
      Starttime: "2023-02-05",
      Endtime: "2023-01-24",
      AppointmentAllowed: "10",
      AppointmentConfirm: "0",
      AppointmentLeft: "10",
      Status: "Active"

    },
    {
      key: '1',
      Date: "07 Jan-2023",
      Starttime: "2023-02-05",
      Endtime: "2023-01-24",
      AppointmentAllowed: "10",
      AppointmentConfirm: "0",
      AppointmentLeft: "10",
      Status: "Active"

    },
    {
      key: '1',
      Date: "07 Jan-2023",
      Starttime: "2023-02-05",
      Endtime: "2023-01-24",
      AppointmentAllowed: "10",
      AppointmentConfirm: "0",
      AppointmentLeft: "10",
      Status: "Active"

    },
    {
      key: '1',
      Date: "07 Jan-2023",
      Starttime: "2023-02-05",
      Endtime: "2023-01-24",
      AppointmentAllowed: "10",
      AppointmentConfirm: "0",
      AppointmentLeft: "10",
      Status: "Active"

    },
    {
      key: '1',
      Date: "07 Jan-2023",
      Starttime: "2023-02-05",
      Endtime: "2023-01-24",
      AppointmentAllowed: "10",
      AppointmentConfirm: "0",
      AppointmentLeft: "10",
      Status: "Active"

    },
  ];

  return (
    <div style={{ padding: '15px', border: '1px solid #ccc', borderRadius: '8px', backgroundColor: "#FFFFFF" }}>
      <Row gutter={16}>
        <Col span={12}>
          <h1 style={{ fontWeight: "bold" }}>Booking Schedule</h1>
        </Col>
      </Row>

      {/* Edit modal */}
      <ScheduleForm  visible={modalVisible} onClose={handleModalClose} editData={editData}/>
      {/* view modal */}
      <Modal
        title={<div style={{ width: "100%" }}>View Booking Schedule</div>}
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={null}
        closable={true}
        width={600}
        bodyStyle={{ padding: "15px" }}
      >
        <Form layout="vertical" requiredMark={false}>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item label="Date" name="date" rules={[{ required: true, message: "Please select a date" }]}>
                <DatePicker style={{ width: "100%" }} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="Start Time"
                name="start_time"
                rules={[{ required: true, message: "Please select a start time" }]} >
                <TimePicker style={{ width: "100%" }} />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                label="End Time"
                name="end_time"
                rules={[{ required: true, message: "Please select an end time" }]}>
                <TimePicker style={{ width: "100%" }} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="Allowed Appointments"
                name="allowed_appointments"
                rules={[{ required: true, message: "Please enter the number of allowed appointments" }]}
              >
                <Input type="number" placeholder="Enter number" />
              </Form.Item>
            </Col>
          </Row>
          <Form.Item>
            <Checkbox>Appointment Active</Checkbox>
          </Form.Item>
        </Form>
      </Modal>

      {/* delete modal */}
      <Modal
        title={<div style={{ textAlign: 'center' }}>Confirm</div>}
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        centered
        bodyStyle={{
          padding: "20px",
        }}
        width={300}
        footer={
          <div className='flex justify-center gap-3'>
            <Button type="default" onClick={handleCancel} style={{ width: "30%" }}>
              Cancel
            </Button>
            <Button type="primary" onClick={handleOk} style={{ width: "30%" }}>
              Confirm
            </Button>
          </div>
        }
      >
        <p style={{ textAlign: 'center' }}>Are you sure you want to booking date this record? Please note it will also delete all the details of this booking date.</p>
      </Modal>

      <Table columns={columns} scroll={{ x: "max-content" }} dataSource={data} style={{ marginTop: '20px' }} />
    </div>
  )
}

export default BookSchedule