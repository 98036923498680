import React, { useState } from 'react';
import { Button, Col, Form, Input, Row, Select } from 'antd';
import { useParams } from 'react-router-dom';

function VendorForm() {
      const { id } = useParams();  // Get 'id' from the URL
    console.log("vendor",id)
    const [form] = Form.useForm();

    const isEditMode = id !== undefined;
    return (
        <div style={{ padding: '15px', border: '1px solid #ccc', borderRadius: '8px', backgroundColor: "#FFFFFF" }}>

            <Row   gutter={26}  style={{  marginBottom: 10, padding: '5px',  }}  >

                <Col span={12}>
                    <h1 style={{ fontWeight: 'bold' }}>  {isEditMode ? 'Edit Vendor' : 'Add Vendors'}</h1>
                </Col>
            </Row>

            <Form
                requiredMark={false}
                form={form}
                layout="vertical"
                initialValues={{ layout: "vertical" }}
               
            >
                <Row gutter={24} >
                    <Col span={6}>
                        <Form.Item
                            label="Vendor Category"
                            name="Vendor Category"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please input!',
                                },
                            ]}
                        >
                            <Select placeholder="OTHER" />
                        </Form.Item>
                    </Col>


                    <Col span={6}>
                        <Form.Item label="Name">
                            <Input placeholder="OTHER" />
                        </Form.Item>
                    </Col >

                    <Col span={6}>
                        <Form.Item label="Email">
                            <Input placeholder="other@ctrade.us" />
                        </Form.Item>
                    </Col >
                    <Col span={6}>
                        <Form.Item label="Town">
                            <Input placeholder="" />
                        </Form.Item>
                    </Col >
                    <Col span={6}>
                        <Form.Item label="Address">
                            <Input placeholder="" />
                        </Form.Item>
                    </Col >
                    <Col span={6}>
                        <Form.Item label="City">
                            <Input placeholder="" />
                        </Form.Item>
                    </Col >

                    <Col span={6}>
                        <Form.Item
                            label="Country"
                            name="Country"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please input!',
                                },
                            ]}
                        >
                            <Select placeholder="" />
                        </Form.Item>
                    </Col>

                    <Col span={6}>
                        <Form.Item label="Phone">
                            <Input placeholder="" />
                        </Form.Item>
                    </Col >


                    <Col span={24}>
                        <Form.Item style={{ textAlign: 'center', marginTop: 30 }}>
                            <Button type="primary" style={{ width: '50%' }}> {isEditMode ? 'Update Vendor' : 'Add Vendor'}</Button>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>

        </div>

    );
};

export default VendorForm

