import React from 'react'
import { Table, Space, Button, Row, Col, Input } from 'antd';
import { EditOutlined, SearchOutlined, FilePdfOutlined, FileAddOutlined, FileTextOutlined, EyeOutlined, DeleteOutlined } from '@ant-design/icons'
import { Link } from 'react-router-dom';
function LoadingPlan() {
    const columns = [
        {
            title: "#",
            dataIndex: '#',
            key: '_id',
            render: (text, record, index) => index + 1,
        },
        {
            title: "Container ID",
            dataIndex: 'ContainerID',
            key: 'ContainerID',
        },
        {
            title: "Container Number",
            dataIndex: 'ContainerNumber',
            key: 'ContainerNumber',
        },
        {
            title: "Location",
            dataIndex: 'Location',
            key: 'Location',
        },


        {
            title: 'Actions',
            key: 'Actions',
            render: (text, { _id }) => (
                <Space size='small'>
                       <Link to={"/loadings/edits"}>
                    <Button type='primary' size="small">
                        <EditOutlined />
                    </Button>
                    </Link>
                    <Link to={"/loadings/views"}>
                    <Button type='primary' size="small">
                        <EyeOutlined />
                    </Button>
                    </Link>
                    <Button type='primary' size="small">
                        <DeleteOutlined />
                    </Button>
                    <Link to={"/loadings/generates-plans"}>
                    <Button type='primary' size="small">
                        <FilePdfOutlined />
                    </Button>
                    </Link>
                    <Button type='primary' size="small">
                        <FileAddOutlined />
                    </Button>
                    <Link to={"/loadings/vehical-details"}>
                    <Button type='primary' size="small">
                        <FileTextOutlined />
                    </Button>
                    </Link>
                </Space >
            )
        },
    ];
    const data = [
        {
            key: '1',
            ContainerID: 'HOUSTON 755-102',
            ContainerNumber: 'TCNU5226866',
            Location: 'HOUSTON 755',
        },
        {
            key: '1',
            ContainerID: 'HOUSTON 755-102',
            ContainerNumber: 'TCNU5226866',
            Location: 'HOUSTON 755',
        },
        {
            key: '1',
            ContainerID: 'HOUSTON 755-102',
            ContainerNumber: 'TCNU5226866',
            Location: 'HOUSTON 755',
        },
    ];
    return (
        <div style={{ padding: '15px', border: '1px solid #ccc', borderRadius: '8px', backgroundColor: "#FFFFFF" }}>
            <Row gutter={16}  >
                <Col span={10}  >
                    <h5 className='font-bold'> Loaded Container</h5>
                </Col>
                <Col span={14} style={{ textAlign: "right" }} >
                    <Input
                        placeholder="Search"
                        prefix={<SearchOutlined />}
                        className="w-auto lg:w-2/5"
                    />
                </Col>
            </Row>
            <Table columns={columns} scroll={{ x: "max-content" }}dataSource={data} style={{ marginTop: '10px' }} />
        </div>
    )
}
export default LoadingPlan
