import React from 'react'
import { Table, Space,  Row, Col, Input } from 'antd';
import {  SearchOutlined } from '@ant-design/icons'


const data = [
  {
    key: '653',
    User: "SC - GREENVILLE",
    Event: "425.00",
    Module: "SOUTH CAROLINA",
    Identifier: "",
    ActionPerformedAt: "2024-12-03 20:28:40",
  },
  {
    key: '653',
    User: "SC - GREENVILLE",
    Event: "425.00",
    Module: "SOUTH CAROLINA",
    Identifier: "",
    ActionPerformedAt: "2024-12-03 20:28:40",
  },
  {
    key: '653',
    User: "SC - GREENVILLE",
    Event: "425.00",
    Module: "SOUTH CAROLINA",
    Identifier: "",
    ActionPerformedAt: "2024-12-03 20:28:40",
  },
  {
    key: '653',
    User: "SC - GREENVILLE",
    Event: "425.00",
    Module: "SOUTH CAROLINA",
    Identifier: "",
    ActionPerformedAt: "2024-12-03 20:28:40",
  },
  {
    key: '653',
    User: "SC - GREENVILLE",
    Event: "425.00",
    Module: "SOUTH CAROLINA",
    Identifier: "",
    ActionPerformedAt: "2024-12-03 20:28:40",
  },
  {
    key: '653',
    User: "SC - GREENVILLE",
    Event: "425.00",
    Module: "SOUTH CAROLINA",
    Identifier: "",
    ActionPerformedAt: "2024-12-03 20:28:40",
  },
];

function Log() {
 
  const columns = [
    {
      title: "Id",
      dataIndex: 'Id',
      key: 'Id',
      render: (text, record, index) => index + 1,
    },
    {
      title: "User",
      dataIndex: 'User',
      key: 'User',
    },
    {
      title: "Event",
      dataIndex: 'Event',
      key: 'Event',
    },
    {
      title: "Module",
      dataIndex: 'Module',
      key: 'Module',
    },
    {
      title: "Identifier",
      dataIndex: 'Identifier',
      key: 'Identifier',
    },
    {
      title: "Action Performed At",
      dataIndex: 'ActionPerformedAt',
      key: 'ActionPerformedAt',
    },
  ];

  return (
    <div style={{ padding: '10px', border: '1px solid #ccc', borderRadius: '8px', backgroundColor: "#FFFFFF" }}>
      <Row gutter={24} style={{ padding: "5px", borderRadius: '4px' }} className='flex justify-between'>
        <Col span={10}  >
          <Space direction="horizontal" size={15} >
            <h5 className='font-bold'>Logs</h5>
          </Space>
        </Col>
        <Col span={14} style={{ textAlign: "right" }} >
          <Input
            placeholder="Search"
            prefix={<SearchOutlined />}
            className="w-auto lg:w-2/5"
          />
        </Col>
      </Row>
      <Table columns={columns} scroll={{ x: "max-content" }}dataSource={data} style={{ marginTop: '10px' }} />

    
    </div>
  )
}
export default Log
