import React from 'react'
import { Table, Space, Button, Row, Col, Input } from 'antd';
import { EditOutlined, SearchOutlined } from '@ant-design/icons'

function TableContainer() {
  const columns = [
    {
      title: "#",
      dataIndex: '#',
      key: '_id',
      render: (text, record, index) => index + 1,
    },
    {
      title: "status",
      dataIndex: 'status',
      key: 'status',
    },
    {
      title: "Product",
      dataIndex: 'Product',
      key: 'Product',
    },
    {
      title: "vechical",
      dataIndex: 'vechical',
      key: 'vechical',
    },
    {
      title: "Edit",
      title: 'Edit',
      key: 'Edit',
      render: (text, { _id }) => (
        <Space size='small'>
          <Button type='primary' size="small">
            <EditOutlined />
          </Button>
        </Space >
      )
    },
  ];
  const data = [
    {
      key: '1',
      status: 'John Brown',
      Product: 'New York No. 1 Lake Park',
      vechical: 'New York No. 1 Lake Park',
    },
    {
      key: '2',
      status: 'Jim Green',
      Product: 'London No. 1 Lake Park',
      vechical: 'London No. 1 Lake Park',
    },
    {
      key: '3',
      status: 'Joe Black',
      Product: 'Sydney No. 1 Lake Park',
      vechical: 'Sydney No. 1 Lake Park'
    },
  ];
  return (
    <div style={{ padding: '20px', border: '1px solid #ccc', borderRadius: '8px' }}>
      <Row gutter={16}>
        <Col span={12}>
          <h1 style={{ fontWeight: "bold" }}>Container Number</h1>
        </Col>
      </Row>
      <Row gutter={24} style={{ marginTop: '20px', background: "#D9D9D9", padding: "10px", borderRadius: '4px' }}>
        <Col span={24} style={{ textAlign: 'right' }}>
          <Input
            placeholder="Search"
            prefix={<SearchOutlined />}
            style={{ width: 200 }}
          />
        </Col>
      </Row>
      <Table columns={columns} dataSource={data} style={{ marginTop: '20px' }} />
    </div>
  )
}
export default TableContainer
