import React, { useState } from 'react'
import { Table, Space, Button, Row, Col, Input, Modal } from 'antd';
import { EditOutlined, SearchOutlined, EyeOutlined, DeleteOutlined } from '@ant-design/icons'
import { Link ,useNavigate} from 'react-router-dom';


function Employees() {
  const navigate = useNavigate()
  const editEmployee = (id) => navigate(`/employees/form/${id}`);
  const [isModalshow, setIsModalShow] = useState()
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [selectedRecord, setSelectedRecord] = useState(null);
  const [data, setData] = useState([
    {
      Id: '13574',
      Name: "obaid",
      Address: "Plot no 17 Shahid Manzil opp Risala police station nishtar road karachi.",
      CNIC: "4220187613459",
      DateOfBirth: "1981-03-25",
      DateOfJoining: "2022-03-01",
      Qualification: "Graduate ",
      Salary: "20000",
      Designation: "Data",
      Username: "obaid123@gmail.com",
      status: "Inactive",
    },
    {
      Id: '13574',
      Name: "obaid",
      Address: "Plot no 17 Shahid Manzil opp Risala police station nishtar road karachi.",
      CNIC: "4220187613459",
      DateOfBirth: "1981-03-25",
      DateOfJoining: "2022-03-01",
      Qualification: "Graduate ",
      Salary: "20000",
      Designation: "Data",
      Username: "obaid123@gmail.com",
      status: "Inactive",
    },
    {
      Id: '13574',
      Name: "obaid",
      Address: "Plot no 17 Shahid Manzil opp Risala police station nishtar road karachi.",
      CNIC: "4220187613459",
      DateOfBirth: "1981-03-25",
      DateOfJoining: "2022-03-01",
      Qualification: "Graduate ",
      Salary: "20000",
      Designation: "Data",
      Username: "obaid123@gmail.com",
      status: "Inactive",
    },
    {
      Id: '13574',
      Name: "obaid",
      Address: "Plot no 17 Shahid Manzil opp Risala police station nishtar road karachi.",
      CNIC: "4220187613459",
      DateOfBirth: "1981-03-25",
      DateOfJoining: "2022-03-01",
      Qualification: "Graduate ",
      Salary: "20000",
      Designation: "Data",
      Username: "obaid123@gmail.com",
      status: "Inactive",
    },
    {
      Id: '13574',
      Name: "obaid",
      Address: "Plot no 17 Shahid Manzil opp Risala police station nishtar road karachi.",
      CNIC: "4220187613459",
      DateOfBirth: "1981-03-25",
      DateOfJoining: "2022-03-01",
      Qualification: "Graduate ",
      Salary: "20000",
      Designation: "Data",
      Username: "obaid123@gmail.com",
      status: "Inactive",
    },
    {
      Id: '13574',
      Name: "obaid",
      Address: "Plot no 17 Shahid Manzil opp Risala police station nishtar road karachi.",
      CNIC: "4220187613459",
      DateOfBirth: "1981-03-25",
      DateOfJoining: "2022-03-01",
      Qualification: "Graduate ",
      Salary: "20000",
      Designation: "Data",
      Username: "obaid123@gmail.com",
      status: "Inactive",
    },
  ])

  const showModal = (record) => {
    setSelectedRecord(record);
    setIsModalVisible(true);
  };
  const handleOk = () => {
    setData((prevData) =>
      prevData.map((item) =>
        item.key === selectedRecord.key
          ? {
            ...item,
            status: item.status === "Active" ? "Inactive" : "Active",
          }
          : item
      )
    );
    setIsModalVisible(false);
    setSelectedRecord(null);
  };
  const handleCancel = () => {
    setIsModalVisible(false);
    setSelectedRecord(null);
    setIsModalShow(false)
  };
  const showModalHandler = () => {
    setIsModalShow(true);
  };
  const columns = [
    {
      title: "Id",
      dataIndex: 'Id',
      key: 'Id',
      render: (text, record, index) => index + 1,
    },
    {
      title: "Name",
      dataIndex: 'Name',
      key: 'Name',
    },
    {
      title: "Address",
      dataIndex: 'Address',
      key: 'Address',
    },
    {
      title: "CNIC",
      dataIndex: 'CNIC',
      key: 'CNIC',
    },
    {
      title: "Date Of Birth",
      dataIndex: 'DateOfBirth',
      key: 'DateOfBirth',
    },
    {
      title: "Date Of Joining",
      dataIndex: 'DateOfJoining',
      key: 'DateOfJoining',
    },
    {
      title: "Qualification",
      dataIndex: 'Qualification',
      key: 'Qualification',
    },
    {
      title: "Salary",
      dataIndex: 'Salary',
      key: 'Salary',
    },

    {
      title: "Designation",
      dataIndex: 'Designation',
      key: 'Designation',
    },
    {
      title: "Username",
      dataIndex: 'Username',
      key: 'Username',
    },
    {
      title: "Status ",
      key: "statusAction",
      render: (_, record) => (
        <div style={{ display: "flex", alignItems: "center", gap: "12px" }}>
          <span
            style={{
              color: record.status === "Active" ? "green" : "red",
              fontWeight: "bold",
            }}
          >
            {record.status}
          </span>
          <Button
            style={{ backgroundColor: "#2D3748", color: "white" }}
            onClick={() => showModal(record)}
          >
            Change Status
          </Button>
        </div>
      ),
    },
    {
      title: 'Operations',
      key: 'Operations',
      render: (text, record) => (
        <Space size='small'>
        
            <Button type='primary' size="small" onClick={() => editEmployee(record.id)}>
              <EditOutlined />
            </Button>
   
          <Link to={"/employees/view/:id?"}>
          <Button type='primary' size="small">
            <EyeOutlined />
          </Button>
          </Link>
          <Button type='primary' size="small">
            <DeleteOutlined onClick={showModalHandler} />
          </Button>
        </Space >
      )
    },
  ]

  return (
    <div style={{ padding: '15px', border: '1px solid #ccc', borderRadius: '8px', backgroundColor: "#FFFFFF" }}>
      <Row gutter={24} style={{ padding: "10px", borderRadius: '4px' }} className='flex justify-between'>
        <Col span={10}  >
          <Space direction="horizontal" >
            <h5 className='font-bold'> Employee Table   </h5>
          </Space>
        </Col>
        <Col span={14} style={{ textAlign: "right" }} >
          <Input
            placeholder="Search"
            prefix={<SearchOutlined />}
            className="w-auto lg:w-2/5"
          />
        </Col>
      </Row>

      {/* action */}
      <Modal
        title={<div style={{ textAlign: "center" }}>Are You Sure?</div>}
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        centered
        bodyStyle={{
          padding: "20px",
        }}
        width={300}
        footer={
          <div className="flex justify-center gap-3">
            <Button
              type="primary"
              onClick={handleOk}
              style={{ width: "30%" }}
            >
              Yes, do it
            </Button>
            <Button
              type="default"
              onClick={handleCancel}
              style={{ width: "30%" }}
            >
              Cancel
            </Button>
          </div>
        }
      >
        <p>
          Are you sure you want to change the status to{" "}
          <strong>
            {selectedRecord?.status === "Active" ? "Inactive" : "Active"}
          </strong>
          ?
        </p>
      </Modal>

      {/* delete */}
      <Modal
        title={<div style={{ textAlign: 'center' }}>Confirm</div>}
        visible={isModalshow}
        onOk={handleOk}
        onCancel={handleCancel}
        centered
        bodyStyle={{
          padding: "20px",
        }}
        width={300}
        footer={
          <div className='flex justify-center gap-3'>
            <Button type="default" onClick={handleCancel} style={{ width: "30%" }}>
              Cancel
            </Button>
            <Button type="primary" onClick={handleOk} style={{ width: "30%" }}>
              Confirm
            </Button>
          </div>
        }
      >
        <p style={{ textAlign: 'center' }}>Are you sure you want to delete this?</p>
      </Modal>

      <Table columns={columns} dataSource={data} scroll={{ x: "max-content" }} style={{ marginTop: '5px' }} />
    </div>
  )
}
export default Employees
