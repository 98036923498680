import React from 'react'
import { Table, Space, Row, Col, Input } from 'antd';
import { SearchOutlined } from '@ant-design/icons'
function CustomerOverdue() {
  const columns = [
    {
      title: "#",
      dataIndex: '#',
      key: '_id',
      render: (text, record, index) => index + 1,
    },
    {
      title: "User Name",
      dataIndex: "UserName",
      key: 'UserName',
    },
    {
      title: "Total Overdue",
      dataIndex: 'TotalOverdue',
      key: 'TotalOverdue',
    },
    {
      title: "Total Pending Amount",
      dataIndex: 'TotalPendingAmount',
      key: 'TotalPendingAmount',
    },
  ];
  const data = [
    {
      key: '1',
      UserName: 'PRESTIGE SHIPPING INC',
      TotalOverdue: '28',
      TotalPendingAmount: "$8,985.00",

    },
    {
      key: '2',
      UserName: 'PRESTIGE SHIPPING INC',
      TotalOverdue: '28',
      TotalPendingAmount: "$8,985.00",
    },
    {
      key: '3',
      UserName: 'PRESTIGE SHIPPING INC',
      TotalOverdue: '28',
      TotalPendingAmount: "$8,985.00",
    },
  ];
  return (
    <div style={{ padding: '20px', border: '1px solid #ccc', borderRadius: '8px' }}>
      <Row gutter={16}>
        <Col span={12}>
          <h1 style={{ fontWeight: "bold" }}>Customers Overdue Summary
          </h1>
        </Col>
      </Row>
      <Row gutter={24} style={{ marginTop: '20px', background: "#D9D9D9", padding: "10px", borderRadius: '4px' }} className='flex justify-between'>
        <Col span={10}  >
          <Space direction="horizontal" size={10} >
            <img src="/images/ve.png" alt="" />
            <img src="/images/ve2.png" alt="" />
            <img src="/images/ve3.png" alt="" />
            <img src="/images/ve4.png" alt="" />
            <img src="/images/v35.png" alt="" />
          </Space>
        </Col>
        <Col span={14} style={{ textAlign: "right" }} >
          <Input
            placeholder="Search"
            prefix={<SearchOutlined />}
            style={{ width: 200 }}
          />
        </Col>
      </Row >
      <Row gutter={24}>
      <Col span={24} >
      <Table columns={columns} dataSource={data} style={{ marginTop: '20px' }} />
      </Col>
      </Row>
    </div>
  )
}

export default CustomerOverdue
