import React from 'react'
import { Table, Space, Button, Row, Col, Input } from 'antd';
import { SearchOutlined, SendOutlined, EyeOutlined, DeleteOutlined } from '@ant-design/icons'
function Invoice() {
    const columns = [
        {
            title: "#",
            dataIndex: '#',
            key: '_id',
            render: (text, record, index) => index + 1,
        },
        {
            title: "Name",
            dataIndex: 'Name',
            key: 'Name',
        },
        {
            title: "Chasis Number",
            dataIndex: 'ChasisNumber',
            key: 'ChasisNumber',
        },
        {
            title: "Container Number",
            dataIndex: 'ContainerNumber',
            key: 'ContainerNumber',
        },
        {
            title: "Discount",
            dataIndex: 'Discount',
            key: 'Discount',
        },
        {
            title: 'Payment Status',
            dataIndex: 'PaymentStatus',
            key: 'PaymentStatus',
            render: (status) => (
                <Button
                    type="primary"
                    size="small"
                    style={{
                        backgroundColor: status === 'paid' ? '#06D652' : status === 'unpaid' ? '#E4D766' : 'transparent',
                        borderColor: status === 'paid' ? '#06D652' : status === 'unpaid' ? '#E4D766' : 'transparent',
                        color: status === 'paid' ? 'white' : status === 'unpaid' ? 'black' : 'transparent',
                    }}
                >
                    {status === 'paid' ? 'Paid' : 'Unpaid'}
                </Button>
            ),
        },
        {
            title: 'Invoice Status',
            dataIndex: 'InvoiceStatus',
            key: 'InvoiceStatus',
            render: (status) => (
                <Button
                    type="primary"
                    size="small"
                    style={{
                        backgroundColor: status === 'approved' ? '#06D652' : status === 'not_approved' ? '#B83D3D' : 'transparent',
                        borderColor: status === 'approved' ? '#06D652' : status === 'not_approved' ? '#B83D3D' : 'transparent',
                        color: status === 'approved' ? 'white' : status === 'not_approved' ? 'white' : 'transparent',
                    }}
                >
                    {status === 'approved' ? 'Approved' : 'Not Approved'}
                </Button>
            ),
        },

        {
            title: 'Actions',
            key: 'Actions',
            render: (text, { _id }) => (
                <Space size='small'>
                    <Button type='primary' size="small">
                        <SendOutlined />
                    </Button>
                    <Button type='primary' size="small">
                        <EyeOutlined />
                    </Button>
                    <Button type='primary' size="small">
                        <DeleteOutlined />
                    </Button>
                </Space >
            )
        },
    ];
    const data = [
        {
            key: '1',
            Name: 'Lorem Ipsum',
            ChasisNumber: "Lorem Ipsum",
            ContainerNumber: 'TCNU5226866',
            Discount: 'HOUSTON 755',
            PaymentStatus: 'paid',  // Paid status
            InvoiceStatus: 'approved',
        },
        {
            key: '1',
            Name: 'Lorem Ipsum',
            ChasisNumber: "Lorem Ipsum",
            ContainerNumber: 'TCNU5226866',
            Discount: 'HOUSTON 755',
            PaymentStatus: 'unpaid',  // Unpaid status
            InvoiceStatus: 'not_approved',
        },
        {
            key: '1',
            Name: 'Lorem Ipsum',
            ChasisNumber: "Lorem Ipsum",
            ContainerNumber: 'TCNU5226866',
            Discount: 'HOUSTON 755',
            PaymentStatus: 'paid',  // Paid status
            InvoiceStatus: 'approved',
        },
    ];
    return (
        <div style={{ padding: '10px', border: '1px solid #ccc', borderRadius: '8px' }}>
            <Row gutter={24} style={{ padding: "5px", borderRadius: '4px' }} className='flex justify-between'>
                <Col span={10}  >
                    <Space direction="horizontal" size={15} >
                        <h5 className='font-bold'>Invoices</h5>
                    </Space>
                </Col>
                <Col span={14} style={{ textAlign: "right" }} >
                    <Input
                        placeholder="Search"
                        prefix={<SearchOutlined />}
                        style={{ width: 200 }}
                    />
                </Col>
            </Row>
            <Table columns={columns} scroll={{ x: "max-content" }} dataSource={data} style={{ marginTop: '5px' }} />
        </div>
    )
}
export default Invoice
