import React from 'react';
import {Layout, Menu, } from 'antd';
import { Link, } from 'react-router-dom';
import { FolderOpenOutlined } from '@ant-design/icons';
import { MdOutlineDashboard } from "react-icons/md";
import { FiUsers } from "react-icons/fi";
import { TbUsersGroup } from "react-icons/tb";
import { PiUsersThreeLight } from "react-icons/pi";
import { TbTruckDelivery } from "react-icons/tb";
import { RiFile2Line } from "react-icons/ri";
import { MdOutlineSettings } from "react-icons/md";
import { RxDotFilled } from "react-icons/rx";

const { Sider } = Layout;
// const { Text } = Typography
const usersChild1 = [
    {
        label: 'OP.Dashboard',
        key: 'OP.Dashboard',
        link: '',
        icon: <MdOutlineDashboard />,
    },
    {
        label: 'Management Dashboard',
        key: 'Management Dashboard',
        link: '',
        icon: <MdOutlineDashboard />,
    },
]
const usersChild = [
    {
        label: 'Vehicles',
        key: 'Vehicles',
        link: '/vehicles',
        icon: <TbTruckDelivery />
    },
    {
        label: 'Customers',
        key: 'Customers',
        link: '/customers',
        icon: <PiUsersThreeLight />,
    },
    {
        label: 'Vendors',
        key: 'Vendors',
        link: '/vendors',
        icon: <TbUsersGroup />,
    },
    {
        label: 'Loading Plan',
        key: 'Loading Plan',
        link: '/loadings',
        icon: <RiFile2Line />
    },
    {
        label: 'Towing Tariff',
        key: 'Towing Tariff',
        link: '/tariffs',
        icon: <RiFile2Line />
    },
    {
        label: 'Appoinment',
        key: 'Appoinment',
        link: '/clients',
        icon: <RiFile2Line />
    },
    {
        label: 'BL Form/Doc Receipt',
        key: 'BL Form/Doc Receipt',
        link: '/bl-forms',
        icon: <RiFile2Line />
    },
    {
        label: 'Quotations',
        key: 'Quotations',
        link: '/quotations',
        icon: <RiFile2Line />
    },
    {
        label: 'Appoinment',
        key: 'Appoinment',
        link: '/clients',
        icon: <RiFile2Line />
    },
    {
        label: 'Inventory Report',
        key: 'Inventory Report',
        link: '/clients',
        icon: <RiFile2Line />
    },
    {
        label: 'Schedule Booking',
        key: 'Schedule Booking',
        link: '/books',
        icon: <RiFile2Line />
    },
];
const usersChild2 = [
    {
        label: 'PNL',
        key: 'PNL',
        link: '',
        icon: <RiFile2Line />
    },
    {
        label: 'Ledger',
        key: 'Ledger',
        link: '',
        icon: <RiFile2Line />,
    },
    {
        label: 'Invoices',
        key: 'Invoices',
        link: '/invoices',
        icon: <RiFile2Line />,
    },
    {
        label: 'Reports',
        key: 'Reports',
        link: '/reports',
        icon: <RiFile2Line />
    },
]
const usersChild3 = [
    {
        label: 'Employees',
        key: 'Employees',
        link: '/employees',
        icon: <PiUsersThreeLight />,
    },
    {
        label: 'Settings',
        key: 'Settings',
        link: '/settings',
        icon: <MdOutlineSettings />
    },
]
const additionalFeatures = [
    {
        label: 'Browse Transaction',
        key: 'BrowseTransaction',
        link: '/accounts',
        icon:<RxDotFilled />
    },
    {
        label: 'Vendor Payments List',
        key: 'VendorPaymentsList',
        link: '/accounts/vendor-payment-lists',
        icon: <RxDotFilled />
    },
];
const additionalFeatures2 = [
    {
        label: 'Expense Voucher',
        key: 'Expense Voucher',
        link: '/accounts/expense-vouchers',
        icon:<RxDotFilled />
    },
    {
        label: 'Payment Voucher',
        key: 'Payment Voucher',
        link: '/accounts/payment-vouchers',
        icon: <RxDotFilled />
    },
    {
        label: 'Credit Note',
        key: 'Credit Note',
        link: '/accounts/credit-notes',
        icon: <RxDotFilled />
    },
    {
        label: 'Debit Note',
        key: 'Debit Note',
        link: '/accounts/debit-notes',
        icon: <RxDotFilled />
    },
    {
        label: 'Invoice Receipt',
        key: 'Invoice Receipt',
        link: '/accounts/receipt-collects',
        icon: <RxDotFilled />
    },
    {
        label: 'Costing Expense',
        key: 'Costing Expense',
        link: '/accounts/coastings',
        icon: <RxDotFilled />
    },
    {
        label: 'Customer Balance',
        key: 'Customer Balance',
        link: '/accounts/customer-ledgers',
        icon: <RxDotFilled />
    },
    {
        label: 'Vendors Containers Payments',
        key: 'Vendors Containers Payments',
        link: '/accounts/vendor-container-payments',
        icon: <RxDotFilled />
    },
    
];
const items = [
    {
        label: 'Dashboard',
        key: 'dashboard',
        icon: <MdOutlineDashboard />,
        link: '/',
        children: usersChild1
    },
    {
        label: 'Operations',
        key: 'Operations',
        icon: <FiUsers />,
        children: usersChild
    },
    {
        label: 'Accounts',
        key: 'Accounts',
        icon: <FolderOpenOutlined />,
        children: [
            ...usersChild2,
            { type: 'divider' }, 
            ...additionalFeatures,
            { type: 'divider' },
            ...additionalFeatures2,
        ],
    },
    {
        label: 'Aministrations',
        key: 'Aministrations',
        icon: <FolderOpenOutlined />,
        link: '/departments',
        children: usersChild3
    },
];

// const clientItems = [
//     {
//         label: 'Dashboard',
//         key: 'dashboard',
//         icon: <MdOutlineDashboard />,
//         link: '/'
//     },
//     {
//         label: 'Groups',
//         key: 'groups',
//         icon: <GrGroup />,
//         link: '/groups'
//     },
//     {
//         label: 'Chats',
//         key: 'chats',
//         icon: <BsChatLeftDots />,
//         link: '/chats'
//     },
// ]

// function activate() {
//     const pathname = window.location.pathname;
//     if (pathname !== '/') {
//         let key = pathname.replace("/", "");
//         const pathSegments = key.split('/');
//         // const firstSegment = pathSegments.find(segment => segment !== '');
//         return pathSegments[0];
//     }

//     return 'dashboard';
// }

const Sidebar = () => {
    // const role = localStorage.getItem('role')
    // const navigate = useNavigate()
    return (
        <>
            <Sider width={250} style={{ overflow: 'auto', height: '100vh', position: 'fixed', left: 0, top: 0, bottom: 0, background: '#FFFFFF', borderRight: '1px solid #E8E8E9' }} className='sidebar-scrollable'>
                <div style={{ margin: '50px 0px 20px 50px', textAlign: "center" }}>
                    <img
                        src="/images/ctrade-logo.png"
                        alt="ctrade-Logo"
                        className="text-center"
                        style={{ width: "83px", height: "45px" }}
                    />
                </div> 
                <Menu
                    mode="inline"
                    className="sidebar-menu"
                    style={{ padding: 0, margin: 0 }}
                >
                    {items.map((item) => (
                        item.children ? (
                            <Menu.SubMenu 
                                key={item.key}
                                icon={item.icon}
                                // title={<span>{item.label}</span>}
                             title=  { <Link to={item.link} style={{ display: 'block', padding: 0 }}>
                                {item.label}
                            </Link>}
                                style={{ paddingLeft: '50px !important' }}    > 
                                {item.children.map((subItem) => (
                                    subItem.type === 'divider' ? (
                                        <Menu.Divider key={subItem.key} style={{ margin: 7 }} />
                                    ) : (
                                        <Menu.Item
                                            key={subItem.key}
                                            icon={subItem.icon}
                                        >
                                            <Link to={subItem.link}>
                                                {subItem.label}
                                            </Link>
                                        </Menu.Item>
                                    )
                                ))}
                            </Menu.SubMenu>
                        ) : (
                            <Menu.Item key={item.key} icon={item.icon}>
                                <Link to={item.link} style={{ display: 'block', padding: 0 }}>
                                    {item.label}
                                </Link>
                            </Menu.Item>
                        )
                    ))}
                </Menu>
            </Sider>
        </>
    )
}

export default Sidebar