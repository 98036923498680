
import { Table, Space, Button, Row, Col, Input, Select } from 'antd';
import { SearchOutlined, EditOutlined } from '@ant-design/icons'
import { Divider } from 'antd';
import React from 'react'
import { Link } from 'react-router-dom';

function Coasting() {
  const options = [
    {
      value: '10',
      label: '10',
    },
    {
      value: '9',
      label: '9',
    },
  ]
  const columns = [

    {
      title: "Invoice Number",
      dataIndex: 'InvoiceNumber',
      key: 'InvoiceNumber',
    },
    {
      title: "Date",
      dataIndex: 'Date',
      key: 'Date',
    },
    {
      title: "Total Invoice Amount",
      dataIndex: 'TotalInvoiceAmount',
      key: 'TotalInvoiceAmount',
    },
    {
      title: "Total Transport Charges",
      dataIndex: 'TotalTransportCharges',
      key: 'TotalTransportCharges',
    },
    {
      title: "Total Labour Charges",
      dataIndex: 'TotalLabourCharges',
      key: 'TotalLabourCharges',
    },
    {
      title: "Total Material Charges",
      dataIndex: 'TotalMaterialCharges',
      key: 'TotalMaterialCharges',
    },
    {
      title: "Total/ Extra Buying Charges",
      dataIndex: 'TotalExtraBuyingCharges',
      key: 'TotalExtraBuyingCharges',
    },
    {
      title: "Total Selling Charges",
      dataIndex: 'TotalSellingCharges',
      key: 'TotalSellingCharges',
    },

    {
      title: 'Action',
      key: 'Action',
      render: (text, { _id }) => (
     
          <Link to={"/add-coasting-expenses"}>
          <Button type='primary' size="small">
            <EditOutlined />
          </Button>
          </Link>
   
      )
    },
  ];
  const data = [
    {
      InvoiceNumber: "5528",
      Date: "2024-10-24",
      TotalInvoiceAmount: "3150",
      TotalTransportCharges: "0",
      TotalLabourCharges: "0",
      TotalMaterialCharges: "0",
      TotalExtraBuyingCharges: "0",
      TotalSellingCharges: "0"
    },
    {
      InvoiceNumber: "5528",
      Date: "2024-10-24",
      TotalInvoiceAmount: "3150",
      TotalTransportCharges: "0",
      TotalLabourCharges: "0",
      TotalMaterialCharges: "0",
      TotalExtraBuyingCharges: "0",
      TotalSellingCharges: "0"
    },
    {
      InvoiceNumber: "5528",
      Date: "2024-10-24",
      TotalInvoiceAmount: "3150",
      TotalTransportCharges: "0",
      TotalLabourCharges: "0",
      TotalMaterialCharges: "0",
      TotalExtraBuyingCharges: "0",
      TotalSellingCharges: "0"
    },
    {
      InvoiceNumber: "5528",
      Date: "2024-10-24",
      TotalInvoiceAmount: "3150",
      TotalTransportCharges: "0",
      TotalLabourCharges: "0",
      TotalMaterialCharges: "0",
      TotalExtraBuyingCharges: "0",
      TotalSellingCharges: "0"
    },
    {
      InvoiceNumber: "5528",
      Date: "2024-10-24",
      TotalInvoiceAmount: "3150",
      TotalTransportCharges: "0",
      TotalLabourCharges: "0",
      TotalMaterialCharges: "0",
      TotalExtraBuyingCharges: "0",
      TotalSellingCharges: "0"
    },
  ];
  return (
    <div style={{ padding: '15px', border: '1px solid #ccc', borderRadius: '8px', backgroundColor: "#FFFFFF" }}>
     
        <Col span={24}>
          <h1 style={{ fontWeight: "bold" }}>Costing Expenses</h1>
        </Col>
     
      <Divider style={{ marginTop: 15 }} />
      <Row gutter={24} style={{ marginTop: '6px', background: "#F2F2F7", padding: "10px", borderRadius: '4px' }} className='flex justify-between'>
        <Col span={10}  >
          <Space direction="horizontal" size={12} >
            <p>Show</p>
            <Select defaultValue="10"
              options={options}
              size="small"
              // dropdownMatchSelectWidth={false} 
              style={{
                width: 60,
                height: 25,
                fontSize: 14,
              }} />
            <p>entries</p>
          </Space>
        </Col>

        <Col span={14} style={{ textAlign: "right" }} >
          <Input
            placeholder="Search"
            prefix={<SearchOutlined />}
        className="w-auto lg:w-2/5"
          />
        </Col>
      </Row>
      <Table columns={columns}scroll={{ x: "max-content" }} dataSource={data} style={{ marginTop: '10px' }} />
    </div>
  )
}
export default Coasting
