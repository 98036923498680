import React from 'react'
import { Table, Row, Col,  } from 'antd';

function LoadingVehicleDetail() {
 
  const columns = [
    {
      title: "S.no",
      dataIndex: 'S.no',
      key: 'S_no',
      render: (text, record, index) => index + 1,
    },
    {
      title: "Year",
      dataIndex: 'Year',
      key: 'Year',
    },
    {
      title: "Make",
      dataIndex: 'Make',
      key: 'Make',
    },
    {
      title: "Model",
      dataIndex: 'Model',
      key: 'Model',
    },
    {
      title: "Vin #",
      dataIndex: 'Vin',
      key: 'Vin',
    },
    {
      title: "Title",
      dataIndex: 'Title',
      key: 'Title',
    },
    {
      title: "Title State",
      dataIndex: 'TitleState',
      key: 'TitleState',
    },
    {
      title: "Value",
      dataIndex: 'Value',
      key: 'Value',
    },
    {
      title: "Weight",
      dataIndex: 'Weight',
      key: 'Weight',
    },
    {
      title: "Title Owner",
      dataIndex: 'Title Owner',
      key: 'Title_Owner',
    },
    {
      title: "Image",
      dataIndex: 'Image',
      key: 'Image',
      render: (text, record) => (
        <img
          src={record.Image} // URL of the image
          alt="Vehicle"
          style={{
            width: '70px',
            height: '50px',
            objectFit: 'contain',
            borderRadius: '4px',
          }}
        />
      ),
    },
  ];
  const columns2 = [
    {
      title: "Booking No",
      dataIndex: 'BookingNo',
      key: 'BookingNo',
    },
    {
      title: "Container No",
      dataIndex: 'ContainerNo',
      key: 'ContainerNo',
    },
    {
      title: "Container Size",
      dataIndex: 'ContainerSize',
      key: 'ContainerSize',
    },
    {
      title: "Seal No",
      dataIndex: 'SealNo',
      key: 'SealNo',
    },
    
  ];
  const data = [
    {
      S_no: '13574',
      Year: "MEHMOOD",
      Make: "60",
      Model: "7$",
      Vin: "59",
      Title: "13574",
      TitleState: "$43",
      Value: "141",
      Weight: "3439",
      Title_Owner: "",
      Image:"/images/image 9.png"
    },
  
    {
      S_no: '13574',
      Year: "MEHMOOD",
      Make: "60",
      Model: "7$",
      Vin: "59",
      Title: "13574",
      TitleState: "$43",
      Value: "141",
      Weight: "3439",
      Title_Owner: "",
      Image:"/images/image 9.png"
    },
  ];
  const data2 = [
    {
      BookingNo: "MEHMOOD",
      ContainerNo: "60",
      ContainerSize: "7$",
      SealNo: "59",
    },]
  return (
    <>
    <div style={{width:"50%",border: '1px solid #ccc',  backgroundColor: "#FFFFFF"}}>
     
    <Table columns={columns2} pagination={false} dataSource={data2} scroll={{ x: "max-content" }} style={{ marginTop: '10px' }} />

    </div>
    <div style={{ padding: '15px', border: '1px solid #ccc', borderRadius: '8px', backgroundColor: "#FFFFFF",marginTop:"20px" }}>
     
      <Row gutter={24}>
      <Col span={24}>
          <h1 style={{ fontWeight: "bold" }}>Destination</h1>
        </Col>
        <Col span={24}>
        
      <Table columns={columns} dataSource={data} scroll={{ x: "max-content" }} style={{ marginTop: '20px' }} />
        </Col>
      </Row>
    </div>
    </>
  )
}

export default LoadingVehicleDetail

