import React, { useState } from 'react'
import { Table, Space, Button, Row, Col, Input, Modal, Form, Select, DatePicker } from 'antd';
import { EditOutlined, SearchOutlined } from '@ant-design/icons'
function Accounts() {

  const [isModalShow, setIsModalShow] = useState(false);

  const showModal = () => setIsModalShow(true);

  const handleOk = () => {
    setIsModalShow(false)
  }
  const handleCancel = () => {
    setIsModalShow(false)
  }
  const columns = [
    {
      title: "Transaction Id",
      dataIndex: 'TransactionId',
      key: 'TransactionId',
      render: (text, record, index) => index + 1,
    },
    {
      title: "Invoice Number",
      dataIndex: 'InvoiceNumber',
      key: 'InvoiceNumber',
    },
    {
      title: "Mode Of Payment",
      dataIndex: 'ModeOfPayment',
      key: 'ModeOfPayment',
    },
    {
      title: "Payment Amount",
      dataIndex: 'PaymentAmount',
      key: 'PaymentAmount',
    },
    {
      title: "Balance",
      dataIndex: 'Balance',
      key: 'Balance',
    },
    {
      title: "Payment Status",
      dataIndex: 'PaymentStatus',
      key: 'PaymentStatus',
    },
    {
      title: "Payment Date",
      dataIndex: 'PaymentDate',
      key: 'PaymentDate',
    },
    {
      title: "Ref.Number",
      dataIndex: 'RefNumber',
      key: 'RefNumber',
    },
    {
      title: 'Actions',
      key: 'Actions',
      render: (text, { _id }) => (
        <Button type='primary' size="small" onClick={showModal}>
          <EditOutlined />
        </Button>
      )
    },
  ];
  const data = [
    {
      TransactionId: "9510",
      InvoiceNumber: "4566",
      ModeOfPayment: 'Bank Transfer',
      PaymentAmount: "800",
      Balance: "0",
      PaymentStatus: "Complete",
      PaymentDate: "2024-10-17",
      RefNumber: "",
    },
    {
      TransactionId: "9510",
      InvoiceNumber: "4566",
      ModeOfPayment: 'Bank Transfer',
      PaymentAmount: "800",
      Balance: "0",
      PaymentStatus: "Complete",
      PaymentDate: "2024-10-17",
      RefNumber: "",
    },
    {
      TransactionId: "9510",
      InvoiceNumber: "4566",
      ModeOfPayment: 'Bank Transfer',
      PaymentAmount: "800",
      Balance: "0",
      PaymentStatus: "Complete",
      PaymentDate: "2024-10-17",
      RefNumber: "",
    },
    {
      TransactionId: "9510",
      InvoiceNumber: "4566",
      ModeOfPayment: 'Bank Transfer',
      PaymentAmount: "800",
      Balance: "0",
      PaymentStatus: "Complete",
      PaymentDate: "2024-10-17",
      RefNumber: "",
    },
    {
      TransactionId: "9510",
      InvoiceNumber: "4566",
      ModeOfPayment: 'Bank Transfer',
      PaymentAmount: "800",
      Balance: "0",
      PaymentStatus: "Complete",
      PaymentDate: "2024-10-17",
      RefNumber: "",
    },
    {
      TransactionId: "9510",
      InvoiceNumber: "4566",
      ModeOfPayment: 'Bank Transfer',
      PaymentAmount: "800",
      Balance: "0",
      PaymentStatus: "Complete",
      PaymentDate: "2024-10-17",
      RefNumber: "",
    },
  ];
  return (
    <div style={{ padding: '15px', border: '1px solid #ccc', borderRadius: '8px', backgroundColor: "#FFFFFF" }}>
      <Row gutter={24} style={{ padding: "10px 10px 0 10px", borderRadius: '4px' }} className='flex justify-between'>
        <Col span={10}  >
          <Space direction="horizontal" size={15} >
            <h5 className='font-bold'> Transaction List</h5>
          </Space>
        </Col>
        <Col span={14} style={{ textAlign: "right" }} >
          <Input
            placeholder="Search"
            prefix={<SearchOutlined />}
            className="w-auto lg:w-2/5"
          />
        </Col>
      </Row>

      <Modal
        title={<div style={{ width: "100%" }}>Edit Invoice</div>}
        open={isModalShow}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={null}
        closable={true}
        width={700}
        bodyStyle={{ padding: "15px" }}
      >
        <Form layout="vertical" requiredMark={false}>
          <Row gutter={24}>
            <Col span={8}>
              <Form.Item
                label="Invoice Number"
                name="Invoice Number"
                rules={[{ required: true, message: "Please enter the number of allowed appointments" }]}
              >
                <Input type="number" placeholder="Enter number" />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                label="Ref. Number"
                name="Ref._Number"
                rules={[{ required: true, message: "Please enter the start time" }]}
              >
                <Input placeholder="Enter start time" />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label="Date" name="date" rules={[{ required: true, message: "Please select a date" }]}>
                <DatePicker style={{ width: "100%" }} />
              </Form.Item>
            </Col>

            <Col span={8}>
              <Form.Item
                label="Invoice Amount"
                name="Invoice_Amount"
                rules={[{ required: true, message: "Please enter the start time" }]}
              >
                <Input placeholder="Enter start time" />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                label="Transaction Amount"
                name="Transaction_Amount"
                rules={[{ required: true, message: "Please enter the end time" }]}
              >
                <Input placeholder="Enter end time" />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                label="Balance"
                name="Balance"
                rules={[{ required: true, message: "Please enter the end time" }]}
              >
                <Input placeholder="Enter end time" />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                label="Mode Of Payment"
                name="Mode_Of_Payment"
                rules={[{ required: true, message: "Please select an appointment type" }]}
              >
                <Select placeholder="Select type">
                  <Select.Option value="Online">Online</Select.Option>
                  <Select.Option value="Offline">Offline</Select.Option>
                </Select>
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                label="Bank"
                name="Bank"
                rules={[{ required: true, message: "Please select an appointment type" }]}
              >
                <Select placeholder="Select type">
                  <Select.Option value="Online">Online</Select.Option>
                  <Select.Option value="Offline">Offline</Select.Option>
                </Select>
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                label="Payment Status"
                name="Payment_Status"
                rules={[{ required: true, message: "Please select an appointment type" }]}
              >
                <Select placeholder="Select type">
                  <Select.Option value="Online">Online</Select.Option>
                  <Select.Option value="Offline">Offline</Select.Option>
                </Select>
              </Form.Item>
            </Col>
            {/* </Row> */}
            <Col span={24}>
              <Form.Item>
                <div style={{ textAlign: "center", marginTop: "12px" }}>
                  <Button
                    type="primary"
                    htmlType="submit"
                    style={{ width: "60%" }}
                    onClick={handleOk}
                  >
                    Update
                  </Button>
                </div>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>

      <Table columns={columns} scroll={{ x: "max-content" }} dataSource={data} style={{ marginTop: '10px' }} />
    </div>
  )
}
export default Accounts
