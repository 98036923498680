import React, { useState } from 'react'
import { Button, Col, DatePicker, Form, Input, Row, Select, Table } from 'antd';
import { CiSquarePlus } from "react-icons/ci";
import { Option } from 'antd/es/mentions';
import { DeleteOutlined } from '@ant-design/icons'
import { useParams } from 'react-router-dom';

export default function VendorQuotationForm() {
    const { id } = useParams();  // Get 'id' from the URL
    console.log("vendorquotation", id)

    const [showAllRows, setShowAllRows] = useState(false);
    const [form] = Form.useForm();

    const columns = [
        {
            title: "Id",
            dataIndex: 'Id',
            key: '_id',
            render: (text, record, index) => index + 1,
        },

        {
            title: "Destination",
            dataIndex: 'Destination',
            key: 'Destination',
            render: (text, record) => (
                <Select
                    defaultValue={text}
                    style={{ width: '100%' }}
                >
                    <Option value="User1">User1</Option>
                    <Option value="User2">User2</Option>
                    <Option value="User3">User3</Option>
                </Select>
            ),
        },

        {
            title: "Container Size",
            dataIndex: 'Container Size',
            key: 'Container Size',
            render: (text, record) => (
                <Select
                    defaultValue={text}
                    style={{ width: '100%' }} >
                    <Option value="SUV">SUV</Option>
                    <Option value="Sedan">Sedan</Option>
                    <Option value="Truck">Truck</Option>
                </Select>
            ),
        },
        {
            title: "DG/NON-DG",
            dataIndex: 'DG/NON-DG',
            key: 'DG/NON-DG',
            render: (text, record) => (
                <Select
                    defaultValue={text}
                    style={{ width: '100%' }}
                >
                    <Option value="Petrol">Petrol</Option>
                    <Option value="Diesel">Diesel</Option>
                    <Option value="Electric">Electric</Option>
                </Select>
            ),
        },
        {
            title: "Fright Charges",
            dataIndex: "Fright Charges",
            key: "Fright Charges",
            render: (text) => (
                <Input
                    defaultValue={text}
                    style={{ width: "100%" }}
                />
            ),
        },
        {
            title: (<div>
                <DeleteOutlined style={{ fontSize: "18px" }} />
            </div>),
            key: 'Delete',
            render: (text, { _id }) => (
                <Button type='primary' size="small">
                    <DeleteOutlined />
                </Button>
            )
        },

    ]
    const data = [
        {
            key: '13574',
            Destination: '',
            ContainerSize: '',
            FrightCharges: '',

        },
        {
            key: '13574',
            Destination: '',
            ContainerSize: '',
            FrightCharges: '',

        },

        {
            key: '13574',
            Destination: '',
            ContainerSize: '',
            FrightCharges: '',

        },
        {
            key: '13574',
            Destination: '',
            ContainerSize: '',
            FrightCharges: '',

        },

    ]
    const handlePlusClick = () => {
        setShowAllRows(true);
    };

    const displayedData = showAllRows ? data : data.slice(0, 2);
    const isEditMode = id !== undefined;
    return (
        <div style={{ padding: '15px', border: '1px solid #ccc', borderRadius: '8px', backgroundColor: "#FFFFFF" }}>
            <Form
                requiredMark={false}
                form={form}
                layout="vertical"
                initialValues={{ layout: "vertical" }}
            >
                <Row gutter={24} >
                    <Col span={24}>
                        <h1 style={{ fontWeight: 'bold', }} className='pt-2  pb-5'>{isEditMode ? 'Edit Vendors Quotation' : 'Add Vendors Quotation '}</h1>
                    </Col>
                    <Col span={8}>
                        <Form.Item
                            label="Vendor "
                            name="Vendor"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please input!',
                                },
                            ]}
                        >
                            <Select placeholder="OTHER" />
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item label="Quotation Date ">
                            <DatePicker style={{ width: '100%' }} placeholder="mm/dd/yyyy" />
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item label="Valid Date ">
                            <DatePicker style={{ width: '100%' }} placeholder="mm/dd/yyyy" />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item label="Chassis Days">
                            <Input placeholder="" />
                        </Form.Item>
                    </Col >
                    <Col span={12}>
                        <Form.Item label="Chassis Charges">
                            <Input placeholder="" />
                        </Form.Item>
                    </Col >
                </Row>

                <div style={{ padding: '10px', border: '1px solid #ccc', borderRadius: '8px', backgroundColor: "#FFFFFF", margin: 50 }}>
                    <Table columns={columns} scroll={{ x: "max-content" }} dataSource={displayedData} pagination={false} rowKey="Id" style={{ marginTop: '10px' }} />

                    <div style={{ marginTop: 20 }} className='flex flex-col justify-center items-center'>
                        <CiSquarePlus style={{ cursor: 'pointer', fontSize: '22px' }} onClick={handlePlusClick} />
                        <p>Add Fright Container Charges</p>
                    </div>
                </div>
                <Form.Item style={{ textAlign: 'center', marginTop: 30 }}>
                    <Button type="primary" style={{ width: '40%' }}> {isEditMode ? 'Update Quotation' : 'Add Quotation'}</Button>
                </Form.Item>
            </Form>
        </div>
    )
}
