
import { Table, Space, Button, Row, Col, Input, Select } from 'antd';
import { SearchOutlined, SendOutlined, FilePdfOutlined, FileAddOutlined, PrinterOutlined, FileOutlined } from '@ant-design/icons'
import { CiSquarePlus } from "react-icons/ci";
import { Divider } from 'antd';
import React from 'react'
import { Link } from 'react-router-dom';

function ReceiptCollect() {
    const options = [
        {
            value: '10',
            label: '10',
        },
        {
            value: '9',
            label: '9',
        },
    ]
    const columns = [
        {
            title: "Recipet Id",
            dataIndex: 'RecipetId',
            key: '_id',
            render: (text, record, index) => index + 1,
        },
        {
            title: "Receipt Ref Number",
            dataIndex: 'ReceiptRefNumber',
            key: 'ReceiptRefNumber',
        },
        {
            title: "Reciept Date",
            dataIndex: 'RecieptDate',
            key: 'RecieptDate',
        },
        {
            title: "Payment Date",
            dataIndex: 'PaymentDate',
            key: 'PaymentDate',
        },
        {
            title: "Mode of Payment",
            dataIndex: 'ModeofPayment',
            key: 'ModeofPayment',
        },

        {
            title: "Customer",
            dataIndex: 'Customer',
            key: 'Customer',
        },
        {
            title: 'Action',
            key: 'Action',
            render: (text, { _id }) => (
                <Space size='small'>
                    <Button type='primary' size="small">
                        <SendOutlined />
                    </Button>
                    <Button type='primary' size="small">
                        <FilePdfOutlined />
                    </Button>
                </Space >
            )
        },
    ];
    const data = [
        {
            RecipetId: '4294',
            ReceiptRefNumber: "-",
            RecieptDate: "2024-10-23",
            PaymentDate: "2024-10-21",
            ModeofPayment: "Bank Transfer",
            Customer: "US TRANS LLC"
        },
        {
            RecipetId: '4294',
            ReceiptRefNumber: "-",
            RecieptDate: "2024-10-23",
            PaymentDate: "2024-10-21",
            ModeofPayment: "Bank Transfer",
            Customer: "US TRANS LLC"
        },
        {
            RecipetId: '4294',
            ReceiptRefNumber: "-",
            RecieptDate: "2024-10-23",
            PaymentDate: "2024-10-21",
            ModeofPayment: "Bank Transfer",
            Customer: "US TRANS LLC"
        },
        {
            RecipetId: '4294',
            ReceiptRefNumber: "-",
            RecieptDate: "2024-10-23",
            PaymentDate: "2024-10-21",
            ModeofPayment: "Bank Transfer",
            Customer: "US TRANS LLC"
        },
        {
            RecipetId: '4294',
            ReceiptRefNumber: "-",
            RecieptDate: "2024-10-23",
            PaymentDate: "2024-10-21",
            ModeofPayment: "Bank Transfer",
            Customer: "US TRANS LLC"
        },
    ];
    return (
        <div style={{ padding: '15px', border: '1px solid #ccc', borderRadius: '8px' }}>
            <Row gutter={16}>
                <Col span={12}>
                    <h1 style={{ fontWeight: "bold" }}>Receipt/Collection</h1>
                </Col>
            </Row>
            <Divider style={{ marginTop: 15 }} />
            <Row gutter={24} style={{ marginTop: '5px', padding: "5px" }} className='flex justify-between'>
                <Col span={10}  >
                    <Space direction="horizontal" size={12} >
                        <p>Show</p>
                        <Select defaultValue="10"
                            options={options}
                            size="small"
                            // dropdownMatchSelectWidth={false} // Prevent dropdown from inheriting the small width
                            style={{
                                width: 60, // Ensure the select is square-like
                                height: 25, // Equal height for a square appearance
                                fontSize: 14,
                            }} />
                        <p>entries</p>
                    </Space>
                </Col>
                <Col flex="none" style={{ display: 'flex', alignItems: 'center', gap: '15px', justifyContent: 'flex-end' }}>
               <Link to={"/add-receipt"}>
                        <CiSquarePlus size={30} style={{ cursor: 'pointer' }} />
               </Link>
                </Col>
            </Row>
            <Row gutter={24} style={{ marginTop: '10px', background: "#F2F2F7", padding: "10px", borderRadius: '4px' }} className='flex justify-between'>
                <Col span={10}  >
                    <Space size={10}>
                        <PrinterOutlined style={{ fontSize: 18 }} />
                        <FileOutlined style={{ fontSize: 18 }} />
                        <FileAddOutlined style={{ fontSize: 18 }} />
                        <FilePdfOutlined style={{ fontSize: 18 }} />
                        <img
                            src="/images/copy-icon.svg"
                            alt="SVG Icon"
                            style={{
                                width: '18px',
                                height: '18px',
                            }}
                        />
                    </Space>
                </Col>
                <Col span={14} style={{ textAlign: "right" }} >
                    <Input
                        placeholder="Search"
                        prefix={<SearchOutlined />}
                        className="w-auto lg:w-2/5"
                    />
                </Col>
            </Row>
            <Table columns={columns} scroll={{ x: "max-content" }} dataSource={data} style={{ marginTop: '10px' }} />
        </div>
    )
}
export default ReceiptCollect

