
import { Table, Row, Col, Input, Button, Form, Space } from 'antd';
import { DeleteOutlined } from '@ant-design/icons'
import { CiSquarePlus } from "react-icons/ci";
import React, { useState } from 'react'

const columns = [
    {
        title: "Id",
        dataIndex: 'Id',
        key: '_id',
        render: (text, record, index) => index + 1,
    },
    {
        title: "Name",
        dataIndex: 'Name',
        key: 'Name',
    },
    {
        title: "Email",
        dataIndex: 'Email',
        key: 'Email',
    },
    {
        title: 'Action',
        key: 'Action',
        render: (text, { _id }) => (
            <Space size='small'>
                <Button type='primary' size="small">
                    <DeleteOutlined />
                </Button>
            </Space >
        )
    },
];
const data = [
    {
        key: '13574',
        Name: "MEHMOOD",
        Email: "60",
    },

];
function Email() {
    const [form] = Form.useForm();
    const [formLayout, setFormLayout] = useState('vertical');
    const onFormLayoutChange = ({ layout }) => {
        setFormLayout(layout);
    };

    return (
        <div style={{ padding: '15px', border: '1px solid #ccc', borderRadius: '8px', backgroundColor: "#FFFFFF" }}>
            <Form
                requiredMark={false}
                layout={formLayout}
                form={form}
                initialValues={{ layout: formLayout }}
                onValuesChange={onFormLayoutChange}
            >
                <Row gutter={24}>

                    <Col span={6}>
                        <Form.Item label="Name">
                            <Input placeholder="" />
                        </Form.Item>
                    </Col>

                    <Col span={6}>
                        <Form.Item label="Email">
                            <Input placeholder="" />
                        </Form.Item>
                    </Col>

                    <Col span={2}>
                        <Form.Item style={{ textAlign: 'center', marginTop: 28 }}>
                            <Button type="primary" style={{ textAlign: "right" }}>
                                <CiSquarePlus size={25} style={{ cursor: 'pointer' }} />
                            </Button>
                        </Form.Item>
                    </Col>

                </Row>
            </Form>
            <Table columns={columns} dataSource={data} style={{ marginTop: '20px' }} />
        </div>
    )
}

export default Email

