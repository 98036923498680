import ajaxService from "./ajax-service";

class VendorQuotation{

  async fetch(page) {
    const response = await ajaxService.get(`/vendors-quotations?page${page}`);
    if (response.success ??false) {
      return response 
    } 
  }
  async viewBrowseQuotation(id) {
    const response = await ajaxService.get(`/vendors-quotations/${id}`);
    if (response.success ??false) {
      return response 
    } 
 
}
}

const vendorQuotation = new VendorQuotation();
export default vendorQuotation;
