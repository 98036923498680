import ajaxService from "./ajax-service";

class VendorService {

  async fetch(page) {
    const response = await ajaxService.get(`/vendors?page${page}`);
    if (response.success ??false) {
      return response 
    } 
  }
  async viewVendor(id) {
    const response = await ajaxService.get(`/vendors/${id}`);
    if (response.success ??false) {
      return response 
    } 
  }
  async deleteVendor(id) {
    const response = await ajaxService.get(`/vendors/${id}`);
    if (response.success ??false) {
      return response 
    } 
  }
}

const vendorService = new VendorService();
export default vendorService;
