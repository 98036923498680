import React, { useEffect, useState } from 'react'
import { Button, Row, Col, Table, Select, Input, Divider, Form, Checkbox, Upload } from 'antd';
import { DeleteOutlined } from '@ant-design/icons'
import { CiSquarePlus } from 'react-icons/ci';
import { Option } from 'antd/es/mentions';

function EditLoadingPlan() {
    const [showAllRows, setShowAllRows] = useState(false);
    const [images, setImages] = useState([]);

    const columns = [
        {
            title: "Vehicle",
            dataIndex: 'Vehicle',
            key: 'Vehicle',
        },
        {
            title: "Chassis Number",
            dataIndex: 'Chassis_Number',
            key: 'Chassis_Number',
        },
        {
            title: "Value",
            dataIndex: 'Value',
            key: 'Value',
        },
        {
            title: "Weight",
            dataIndex: 'Weight',
            key: 'Weight',
        },
        {
            title: "Engine Type",
            dataIndex: 'Engine_Type',
            key: 'Engine_Type',
        },
        {
            title: (<div>
                <DeleteOutlined style={{ fontSize: "18px" }} />
            </div>),
            key: 'Delete',
            render: (text, { _id }) => (
                <Button type='primary' size="small">
                    <DeleteOutlined />
                </Button>
            )
        },

    ];
    const data = [
        {
            key: '13574',
            Vehicle: "FORD",
            Chassis_Number: "1FDUF5HT0RDA27180",
            Engine_Type: "2027",
            Value: "8650",
            Weight: "3439",
        },
        {
            key: '13574',
            Vehicle: "FORD",
            Chassis_Number: "1FDUF5HT0RDA27180",
            Engine_Type: "2027",
            Value: "8650",
            Weight: "3439",
        },
        {
            key: '13574',
            Vehicle: "FORD",
            Chassis_Number: "1FDUF5HT0RDA27180",
            Engine_Type: "2027",
            Value: "8650",
            Weight: "3439",
        }, {
            key: '13574',
            Vehicle: "FORD",
            Chassis_Number: "1FDUF5HT0RDA27180",
            Engine_Type: "2027",
            Value: "8650",
            Weight: "3439",
        },

    ]
    const columns2 = [
        {
            title: " #",
            dataIndex: " #",
            key: " #",
            render: (text, record, index) => index + 1,
        },
        {
            title: "Vendor",
            dataIndex: 'Vendor',
            key: 'Vendor',
            render: (text, record) => (
                <Select
                    defaultValue={text}
                    style={{ width: '100%' }} >
                    <Option value="SUV">SUV</Option>
                    <Option value="Sedan">Sedan</Option>
                    <Option value="Truck">Truck</Option>
                </Select>
            ),
        },
        {
            title: "Description",
            dataIndex: "Description",
            key: "Description   ",
            render: (text) => (
                <Input
                    defaultValue={text}
                    style={{ width: "100%" }}
                />
            ),
        },

        {
            title: (<div>
                <DeleteOutlined style={{ fontSize: "18px" }} />
            </div>),
            key: 'Delete',
            render: (text, { _id }) => (
                <Button type='primary' size="small">
                    <DeleteOutlined />
                </Button>
            )
        },

    ];
    const data2 = [
        {
            key: '13574',
            Vehicle: "FORD",
            Chassis_Number: "1FDUF5HT0RDA27180",

        },
        {
            key: '13574',
            Vehicle: "FORD",
            Chassis_Number: "1FDUF5HT0RDA27180",

        },
        {
            key: '13574',
            Vehicle: "FORD",
            Chassis_Number: "1FDUF5HT0RDA27180",

        }, {
            key: '13574',
            Vehicle: "FORD",
            Chassis_Number: "1FDUF5HT0RDA27180",

        },

    ]

    const handlePlusClick = () => {
        setShowAllRows(true);
    };

    const displayedData = showAllRows ? data2 : data2.slice(0, 2);

    useEffect(() => {

        const fetchedImages = [
            { id: 1, url: '/images/image.png' },
            { id: 2, url: '/images/image2.png' },
            { id: 3, url: '/images/image.png' },
            { id: 4, url: '/images/image2.png' },
            { id: 5, url: '/images/image.png' },
            { id: 6, url: '/images/image2.png' },
            { id: 7, url: '/images/image.png' },
            { id: 8, url: '/images/image2.png' },
            { id: 9, url: '/images/image.png' },
            { id: 10, url: '/images/image2.png' },
            { id: 11, url: '/images/image.png' },
            { id: 12, url: '/images/image2.png' },
            { id: 13, url: '/images/image.png' },
            { id: 14, url: '/images/image2.png' },
            { id: 15, url: '/images/image.png' },
            { id: 16, url: '/images/image2.png' },
            { id: 17, url: '/images/image.png' },
            { id: 18, url: '/images/image2.png' },

        ];
        setImages(fetchedImages);
    }, []);

    const removeImage = (imageId) => {
        setImages(images.filter((image) => image.id !== imageId));
    }
    return (
        <>
            <Row gutter={24} wrap={false}>
                <Col flex="280px">
                    <div style={{ paddingTop: "8px", paddingRight: "8px", paddingLeft: 8, border: "1px solid #ccc", borderRadius: 5, backgroundColor: "#FFFFFF" }}>
                        <Row gutter={16} justify="center">
                            {images.map((image) => (
                                <Col xs={24} sm={12} lg={12}>
                                    <div style={{ textAlign: 'center', marginBottom: '12px' }}>
                                        <img
                                            src={image.url}
                                            alt="No images found"
                                            style={{
                                                width: '100%',
                                                height: 'auto',
                                                maxWidth: '320px',
                                                objectFit: 'contain',
                                            }}
                                        />
                                        <p
                                            style={{
                                                cursor: 'pointer',
                                                color: 'red',
                                                marginTop: '5px',
                                            }}
                                            onClick={() => removeImage(image.id)}
                                        >
                                            Remove Image
                                        </p>
                                    </div>
                                </Col>
                            ))}
                        </Row>
                    </div>
                </Col>

                <Col flex="1">
                    <div style={{ padding: '15px', border: '1px solid #ccc', borderRadius: '8px', backgroundColor: "#FFFFFF" }}>
                        <Row gutter={24}>
                            <Form
                                requiredMark={false}
                                layout="vertical"
                                initialValues={{ layout: "vertical" }} >
                                <Row gutter={24} style={{ padding: 20 }}>
                                    <Col span={8}>
                                        <Form.Item
                                            label="Load Number"
                                            name="Load Number"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'Please input!',
                                                },
                                            ]}
                                        >
                                            <Select placeholder="HOUSTON-3323-90164" />
                                        </Form.Item>
                                    </Col>
                                    <Col span={8}>
                                        <Form.Item label="Booking Number">
                                            <Input placeholder="EBKG10639832  " />
                                        </Form.Item>
                                    </Col>
                                    <Col span={8}>
                                        <Form.Item label="Container Number">
                                            <Input placeholder="" />
                                        </Form.Item>
                                    </Col>
                                    <Col span={8}>
                                        <Form.Item label="Add New Car">
                                            <Input placeholder="" />
                                        </Form.Item>
                                    </Col>
                                    <Col span={8}>
                                        <Form.Item label="Shipping Line">
                                            <Input placeholder="MCS" />
                                        </Form.Item>
                                    </Col>
                                    <Col span={8}>
                                        <Form.Item label="Location ">
                                            <Input placeholder="HOUSTON-3323" />
                                        </Form.Item>
                                    </Col>
                                    <Col span={24}>
                                        <Form.Item style={{ textAlign: 'right', marginTop: 30 }}>
                                            <Button type="primary" style={{ width: '30%' }}>ADD</Button>
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </Form>

                            <Form
                                requiredMark={false}
                                layout="vertical"
                                initialValues={{ layout: "vertical" }} >
                                <Row gutter={24} style={{ padding: 20 }}>
                                    <Col span={8}>
                                        <Form.Item
                                            label="Destination"
                                            name="Destination"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'Please input!',
                                                },
                                            ]}
                                        >
                                            <Select placeholder="BENGHAZI" />
                                        </Form.Item>
                                    </Col>
                                    <Col span={8}>
                                        <Form.Item label="Container Size">
                                            <Input placeholder="40" />
                                        </Form.Item>
                                    </Col>
                                    <Col span={8}>
                                        <Form.Item label="Pull Out Date">
                                            <Input placeholder="dd----yyyy" />
                                        </Form.Item>
                                    </Col>
                                    <Col span={8}>
                                        <Form.Item label="Gate In Date">
                                            <Input placeholder="dd----yyyy" />
                                        </Form.Item>
                                    </Col>
                                    <Col span={8}>
                                        <Form.Item label="Customised Date">
                                            <Input placeholder="dd----yyyy" />
                                        </Form.Item>
                                    </Col>
                                    <Col span={8}>
                                        <Form.Item label="ETA Date">
                                            <Input placeholder="dd----yyyy" />
                                        </Form.Item>
                                    </Col>
                                    <Col span={24}>
                                        <Form.Item style={{ textAlign: 'right', marginTop: 30 }}>
                                            <Checkbox>
                                                Customer Own Loading
                                            </Checkbox>
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </Form>
                        </Row>
                        <div style={{ padding: '10px', border: '1px solid #ccc', borderRadius: '8px', backgroundColor: "#FFFFFF", margin: 5, marginTop: 10, }}>

                            <Table columns={columns} pagination={false} scroll={{ x: "max-content" }} dataSource={data} style={{ marginTop: '5px' }} />
                        </div>

                        <Row gutter={24} style={{ marginTop: 40, display: 'flex', justifyContent: 'flex-end' }}>
                            <Col span={6}>
                                <Upload
                                    id="uploadInput"
                                    action="/upload"
                                    listType="picture"

                                >
                                    <Button
                                        type="dashed"
                                        style={{
                                            width: '100%',
                                            border: '1px solid #ccc',
                                            padding: 15,

                                        }}
                                    >
                                        <img src="/images/Group 1178.png" alt="" width="25px" />
                                        Upload Image
                                    </Button>
                                </Upload>
                            </Col>

                            <Col span={6}>
                                <Button type="primary" style={{ width: '100%' }}>
                                    Update - Save
                                </Button>
                            </Col>
                            <Col span={6}>
                                <Button type="primary" style={{ width: '100%' }}>
                                    Update - Load
                                </Button>
                            </Col>
                        </Row>

                        <div style={{ padding: '10px', border: '1px solid #ccc', borderRadius: '8px', backgroundColor: "#FFFFFF", margin: 5, marginTop: 40 }}>
                            <Row gutter={16}>
                                <Col span={12}>
                                    <h1 style={{ fontWeight: "bold" }}>Freight Forwarder / Transporter</h1>
                                </Col>

                            </Row>
                            <Table columns={columns2} pagination={false} scroll={{ x: "max-content" }} dataSource={displayedData} style={{ marginTop: '5px' }} />

                            <div style={{ marginTop: 20 }} className='flex flex-col justify-center items-center'>
                                <CiSquarePlus style={{ cursor: 'pointer', fontSize: '25px' }} onClick={handlePlusClick} />
                            </div>
                        </div>
                        <Row gutter={24} style={{ marginTop: 20, display: 'flex', justifyContent: 'flex-end' }}>

                            <Col span={6}>
                                <Button type="primary" style={{ width: '100%' }}>
                                    Update - Save
                                </Button>
                            </Col>

                        </Row>
                    </div>

                </Col>
            </Row>


        </>
    )
}

export default EditLoadingPlan

