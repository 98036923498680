
import { Table, Space, Button, Row, Col, Input, Select, Modal } from 'antd';
import { SearchOutlined, EyeOutlined } from '@ant-design/icons'
import { Divider } from 'antd';
import React, { useState } from 'react'

function CustomerLedger() {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => setIsModalOpen(true)

  const handleOk = () => {
    setIsModalOpen(false)
  }
  const handleCancel = () => {
    setIsModalOpen(false)
  }

  const options = [
    {
      value: '10',
      label: '10',
    },
    {
      value: '9',
      label: '9',
    },
  ]
  const columns = [
    {
      title: "User",
      dataIndex: 'User',
      key: 'User',
    },
    {
      title: "Total Invoice",
      dataIndex: 'TotalInvoice',
      key: 'TotalInvoice',
    },
    {
      title: "Paid",
      dataIndex: 'Paid',
      key: 'Paid',
    },
    {
      title: "Balance",
      dataIndex: 'Balance',
      key: 'Balance',
    },
    {
      title: "Status",
      dataIndex: 'Status',
      key: 'Status',
    },

    {
      title: 'Action',
      key: 'Action',
      render: (text, { _id }) => (
        <Space size='small'>
          <Button type='primary' size="small" onClick={openModal}>
            <EyeOutlined />
          </Button>
        </Space >
      )
    },
  ];
  const data = [
    {
      User: "YOUNES EXPORT",
      TotalInvoice: "$ 121051.4",
      TotalInvoiceAmount: "$ 103531.4",
      Paid: "$ 103531.4",
      Balance: "$ 17520",
      Status: "Pending",
    },
    {
      User: "YOUNES EXPORT",
      TotalInvoice: "$ 121051.4",
      TotalInvoiceAmount: "$ 103531.4",
      Paid: "$ 103531.4",
      Balance: "$ 17520",
      Status: "Pending",
    },
    {
      User: "YOUNES EXPORT",
      TotalInvoice: "$ 121051.4",
      TotalInvoiceAmount: "$ 103531.4",
      Paid: "$ 103531.4",
      Balance: "$ 17520",
      Status: "Pending",
    },
    {
      User: "YOUNES EXPORT",
      TotalInvoice: "$ 121051.4",
      TotalInvoiceAmount: "$ 103531.4",
      Paid: "$ 103531.4",
      Balance: "$ 17520",
      Status: "Pending",
    },
    {
      User: "YOUNES EXPORT",
      TotalInvoice: "$ 121051.4",
      TotalInvoiceAmount: "$ 103531.4",
      Paid: "$ 103531.4",
      Balance: "$ 17520",
      Status: "Pending",
    },
  ];
  const dummyInvoices = Array.from({ length: 71 }, (_, i) =>10000 + i);
  return (
    <div style={{ padding: '15px', border: '1px solid #ccc', borderRadius: '8px', backgroundColor: "#FFFFFF" }}>
      <Row gutter={16}>
        <Col span={12}>
          <h1 style={{ fontWeight: "bold" }}>Customers Accounts Ledger</h1>
        </Col>
      </Row>
      <Divider style={{ marginTop: 15 }} />
      <Row gutter={24} style={{ marginTop: '6px', background: "#F2F2F7", padding: "10px", borderRadius: '4px' }} className='flex justify-between'>
        <Col span={10}  >
          <Space direction="horizontal" size={12  } >
            <p>Show</p>
            <Select defaultValue="10"
              options={options}
              size="small"
              // dropdownMatchSelectWidth={false} // Prevent dropdown from inheriting the small width
              style={{
                width: 60, // Ensure the select is square-like
                height: 25, // Equal height for a square appearance
                fontSize: 14,
              }} />
            <p>entries</p>
          </Space>
        </Col>

        <Col span={14} style={{ textAlign: "right" }} >
          <Input
            placeholder="Search"
            prefix={<SearchOutlined />}
            className="w-auto lg:w-2/5"
          />
        </Col>
      </Row>

      
      <Modal
  title={<div style={{ width: "100%" }}>Invoice Numbers</div>}
  open={isModalOpen}
  onOk={handleOk}
  onCancel={handleCancel}
  footer={null}
  closable={true}
  width={850}
  bodyStyle={{
    padding: "20px",
  }}
>
  <div
    style={{
      border: "1px solid #ccc",
      padding: "20px",
      borderRadius: "12px",
    }}
  >
    {/* Scrollable Container for Invoices */}
    <div
      style={{
        display: "flex",
        flexWrap: "wrap",
        gap: "5px",
        justifyContent: "space-between",
        maxHeight: "150px", // Set the scrollable height
        overflowY: "auto", // Enable vertical scrolling
      }}
    >
      {dummyInvoices.map((invoice, index) => (
        <div
          key={index}
          style={{
            backgroundColor: "#B83D3D",
            color: "white",
            padding: "5px",
            border: "1px solid #ccc",
            borderRadius: "5px",
            textAlign: "center",
            width: "13%", // 7 items per row (adjust for margins and gaps)
            boxSizing: "border-box",
          }}
        >
          {invoice}
        </div>
      ))}
    </div>
  </div>
</Modal>

  

      <Table columns={columns} scroll={{ x: "max-content" }} dataSource={data} style={{ marginTop: '10px' }} />
    </div>
  )
}

export default CustomerLedger
