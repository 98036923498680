import React from 'react'
import { Table, Space, Button, Row, Col, Input } from 'antd';
import { EditOutlined, SearchOutlined } from '@ant-design/icons'
function MissingContainer() {
  const columns = [
    {
      title: "#",
      dataIndex: '#',
      key: '_id',
      render: (text, record, index) => index + 1,
    },
    {
      title: "Container",
      dataIndex: 'Container',
      key: 'Container   ',
    },
    {
      title: "Location",
      dataIndex: 'Location',
      key: 'Location',
    },


    {
      title: "Edit",
      title: 'Edit',
      key: 'Edit',
      render: (text, { _id }) => (
        <Space size='small'>
          <Button type='primary' size="small">
            <EditOutlined />
          </Button>
        </Space >
      )
    },
  ];
  const data = [
    {
      key: '1',
      Container: 'HOUSTON-2286-82092',
      Location: 'HOUSTON-3118'

    },
    {
      key: '2',
      Container: 'HOUSTON-2286-82092',
      Location: 'HOUSTON-3118',
    },
    {
      key: '3',
      Container: 'HOUSTON-2286-82092',
      Location: 'HOUSTON-3118'
    },
  ];
  return (
    <div style={{ padding: '20px', border: '1px solid #ccc', borderRadius: '8px' }}>
      <Row gutter={16}>
        <Col span={12}>
          <h1 style={{ fontWeight: "bold" }}>Saved Loading Plans (Missing Container/Booking #) tooltip</h1>
        </Col>
      </Row>
      <Row gutter={26} style={{ marginTop: '20px', background: "#D9D9D9", padding: "10px", borderRadius: '4px' }}>
        <Col span={24} style={{ textAlign: 'right' }}>
          <Input
            placeholder="Search"
            prefix={<SearchOutlined />}
            style={{ width: 200 }}
          />
        </Col>
      </Row>
      <Table columns={columns} dataSource={data} style={{ marginTop: '20px' }} />
    </div>
  )
}
export default MissingContainer
