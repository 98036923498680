
import React from 'react'
import { Table, Row, Col, Space } from 'antd';
import { SendOutlined, PrinterOutlined } from '@ant-design/icons'

function QuotationView() {
  const columns = [
    {
      title: "S.No",
      dataIndex: 'SNo',
      key: 'SNo',
      render: (text, record, index) => index + 1,
    },
    {
      title: "Destination",
      dataIndex: 'Destination',
      key: 'Destination',
    },
    {
      title: "Container",
      dataIndex: 'Container',
      key: 'Container',
    },
    {
      title: "No Of Vehicles",
      dataIndex: 'NoOfVehicles',
      key: 'NoOfVehicles',
    },
    {
      title: "Vehicle Type",
      dataIndex: 'VehicleType',
      key: 'VehicleType',
    }, {
      title: "Loading Charges",
      dataIndex: 'LoadingCharges',
      key: 'LoadingCharges',
    },



  ];
  const data = [
    {
      SNo: "1",
      Destination: "DURRES",
      Container: "40",
      NoOfVehicles: "40",
      VehicleType: "Car",
      LoadingCharges: "1000",
    },
    {
      SNo: "1",
      Destination: "DURRES",
      Container: "40",
      NoOfVehicles: "40",
      VehicleType: "Car",
      LoadingCharges: "1000",
    },
    {
      SNo: "1",
      Destination: "DURRES",
      Container: "40",
      NoOfVehicles: "40",
      VehicleType: "Car",
      LoadingCharges: "1000",
    },
    {
      SNo: "1",
      Destination: "DURRES",
      Container: "40",
      NoOfVehicles: "40",
      VehicleType: "Car",
      LoadingCharges: "1000",
    },
    {
      SNo: "1",
      Destination: "DURRES",
      Container: "40",
      NoOfVehicles: "40",
      VehicleType: "Car",
      LoadingCharges: "1000",
    },
    {
      SNo: "1",
      Destination: "DURRES",
      Container: "40",
      NoOfVehicles: "40",
      VehicleType: "Car",
      LoadingCharges: "1000",
    },
    {
      SNo: "1",
      Destination: "DURRES",
      Container: "40",
      NoOfVehicles: "40",
      VehicleType: "Car",
      LoadingCharges: "1000",
    },
  ];
  return (
    <div style={{ padding: '15px', border: '1px solid #ccc', borderRadius: '8px', backgroundColor: "#FFFFFF" }}>
      {/* Header Content */}
      <Row gutter={16}>
        <Col span={20}>
          <h1 style={{ fontWeight: 'bold' }}>Hello BUY TO AMERICA,</h1>
          <ul style={{ lineHeight: '1.8', margin: 0, }}>
            <li>LOADING/FREIGHT RATES ARE AS ATTACHED.</li>
            <li>ABOVE RATES INCLUSIVE OF 0 DAYS CHASIS CHARGES; THEREAFTER $0/DAY PER CHASIS.</li>
            <li>PAYMENT TERMS: 7 DAYS FROM THE DATE OF INVOICE.</li>
            <li>LOADING PICTURES WILL BE PROVIDED TO SATISFY THE CUSTOMERS IN ADVANCE.</li>
            <li>CHEQUE BOOK PROVIDED FOR ISSUANCE OF CHEQUES TO CUSTOMERS WITH THEIR OWN DISPATCH.</li>
            <li>DEDICATED CUSTOMER SERVICE FOR VOLUMETRIC CUSTOMERS.</li>
            <li>STORAGE POLICY: 60 DAYS FREE; THEREAFTER $7/DAY PER UNIT.</li>
            <li>DRIVERS MUST CONTACT PRIOR TO DELIVERY OR EMAIL THE DISPATCH SHEETS FOR PROPER DELIVERY AND NO DELAYS.</li>
            <li>ADVANCE BOOKINGS BASED ON VOLUME COMMITMENT.</li>
            <li>7 ACRES OF PARKING SPACE.</li>
            <li>
              WE REQUEST THAT THE LOADING PLAN BE SENT 48 TO 72 BUSINESS HOURS IN ADVANCE OF FIRST RECEIVING.
            </li>
            <li>
              IF CUSTOMERS NEED TO PICK UP VEHICLES, IT WILL BE SUBJECT TO $100 PER VEHICLE, ALONG WITH STORAGE CHARGES AFTER 30 DAYS OF RECEIVING AT THE FACILITY.
            </li>
            <li>IN-HOUSE DRAYAGE NETWORK WITH OWN CHASIS FOR SMOOTH OPERATIONS.</li>
          </ul>
        </Col>
      </Row>

      <div style={{ padding: '20px', border: '1px solid #ccc', borderRadius: '8px', marginTop: '20px' }}>
        <Table columns={columns} scroll={{ x: "max-content" }} dataSource={data} />
      </div>

      <div style={{ textAlign: 'right', marginTop: '20px' }}>
        <Space size="large">
          <SendOutlined style={{ fontSize: '18px', cursor: 'pointer' }} />
          <PrinterOutlined style={{ fontSize: '18px', cursor: 'pointer' }} />
        </Space>
      </div>
    </div>
  )
}
export default QuotationView

