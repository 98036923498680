import React, { useEffect, useState } from 'react'
import { Table, Space, Button,  Row, Col, Input, Modal } from 'antd';
import { EditOutlined, SearchOutlined, EyeOutlined, DeleteOutlined } from '@ant-design/icons'
import { useNavigate } from 'react-router-dom';

import vendorQuotation from '../../services/vendor-quotation-service';

function BrowseVendor() {
  const navigate = useNavigate()
  const editVendorQuotation = (id) => navigate(`/vendors/quotations/form/${id}`);
  const [isModalshow, setIsModalShow] = useState()
  const [browses, setBrowse] = useState([])
  const [filteredBrowse, setFilteredBrowse] = useState(browses);
  const [loading, setLoading] = useState(false);
  // const [selectedDeleteRecord,setSelectedDeleteRecord]=useState(null)
  const [pagination, setPagination] = useState({ current: 1, total: 0, pageSize: 10 });
  const [searchTerm, setSearchTerm] = useState('');

  const showModalHandler = () => {
    setIsModalShow(true);
  };
  const handleOk = () => {
    setIsModalShow(false)
  };
  const handleCancel = () => {
    setIsModalShow(false)
  };

  const initialize = async (page = 1, pageSize = pagination.pageSize) => {
    setLoading(true);
    const { success, data } = await vendorQuotation.fetch(page);
    setLoading(false);
    if (success) {
      setBrowse(data.data);
      setFilteredBrowse(data.data);
      setPagination((prev) => ({
        ...prev,
        total: data.total,
        current: page,
        pageSize,
      }));
    }
  };
  const handleSearch = (e) => {
    const value = e.target.value;
    setSearchTerm(value);

    const filteredData = browses.filter((browse) =>
      browse.name.toLowerCase().includes(value.toLowerCase()) // Change "name" to whatever field you want to search by
    );
    setFilteredBrowse(filteredData);
  };

  const columns = [
    {
      title: "#",
      dataIndex: '#',
      key: '#   ',
      render: (text, record, index) => (pagination.current - 1) * pagination.pageSize + index + 1,

    },

    {
      title: "Name",
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: "Date",
      dataIndex: 'date',
      key: 'date',
    },
    {
      title: "Validity",
      dataIndex: 'validity_date',
      key: 'validity_date',
    },
    {
      title: 'Actions',
      key: 'Actions',
      render: (text, record) => (
        <Space size='small'>
      
            <Button type='primary' size="small" onClick={() => editVendorQuotation(record.id)}>
              <EditOutlined />
            </Button>
    
            <Button type='primary' size="small"  onClick={() => navigate('/vendors/quotations/views/' + record.id)}>
              <EyeOutlined />
            </Button>
      
          <Button type='primary' size="small" onClick={showModalHandler}>
            <DeleteOutlined  />
          </Button>

        </Space >
      )
    },
  ];
  
  useEffect(() => {
    initialize(pagination.current, pagination.pageSize);

  }, [pagination.current, pagination.pageSize]);

  return (
    <div style={{ padding: '15px', border: '1px solid #ccc', borderRadius: '8px', backgroundColor: "#FFFFFF" }}>
      <Row gutter={24} style={{ padding: "10px", borderRadius: '4px' }} className='flex justify-between'>
        <Col span={12}  >
          <Space direction="horizontal" size={15} >
            <h5 className='font-bold'> Browse Vendors Quotation</h5>
          </Space>
        </Col>
        <Col span={12} style={{ textAlign: "right" }} >
          <Input
            placeholder="Search"
            prefix={<SearchOutlined />}
          className="w-auto lg:w-2/5"
          value={searchTerm}
          onChange={handleSearch}
          />
        </Col>
      </Row>

      {/* delete */}
      <Modal
        title={<div style={{ textAlign: 'center' }}>Confirm</div>}
        visible={isModalshow}
        onOk={handleOk}
        onCancel={handleCancel}
        centered
        bodyStyle={{
          padding: "20px", // Adjust padding for smaller size
        }}
        width={300} // Adjust modal width
        footer={
          <div className='flex justify-center gap-3'>
            <Button type="default" onClick={handleCancel} style={{ width: "30%" }}>
              Cancel
            </Button>
            <Button type="primary" onClick={handleOk} style={{ width: "30%" }}>
              Confirm
            </Button>
          </div>
        }
      >
        <p style={{ textAlign: 'center' }}>Are you sure you want to delete this?</p>
      </Modal>

      <Table 
      columns={columns}
      dataSource={filteredBrowse}
            loading={loading}
            pagination={{
              current: pagination.current,
              total: pagination.total,
              pageSize: pagination.pageSize,
              onChange: (page) => initialize(page, pagination.pageSize),
            }} style={{ marginTop: '10px' }} />
    </div>
  )
}
export default BrowseVendor
