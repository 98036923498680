import React from 'react';
import { Modal, Form, Input, Button, Row, Col } from 'antd';

const ModalForm = ({ visible, onClose, editData, record }) => {
  const [form] = Form.useForm();

  // Set initial values for edit mode
  React.useEffect(() => {
    if (editData && record) {
      form.setFieldsValue(record);
    } else {
      form.resetFields();
    }
  }, [editData, record, form]);

  const handleSubmit = (values) => {
    if (editData) {
      console.log('Editing record:', values);
    } else {
      console.log('Adding new record:', values);
    }
    onClose();
  };

  return (
    <Modal
      title={editData ? 'Edit Tariff' : 'Add Tariff'}
      visible={visible}
      onCancel={onClose}
      footer={null}
      centered
    >
      <Form
        form={form}
        layout="vertical"
        onFinish={handleSubmit}
        requiredMark={false}
      >
        <Row gutter={24}>
          <Col span={8}>
            <Form.Item
              label="Auction"
              name="Auction"
              rules={[{ required: true, message: 'Please input Auction!' }]}
            >
              <Input placeholder="Enter auction name" />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label="USD"
              name="USD"
              rules={[{ required: true, message: 'Please input USD!' }]}
            >
              <Input placeholder="Enter USD" />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label="State"
              name="State"
              rules={[{ required: true, message: 'Please input State!' }]}
            >
              <Input placeholder="Enter state" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="Code"
              name="Code"
              rules={[{ required: true, message: 'Please input Code!' }]}
            >
              <Input placeholder="Enter state code" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="Ports"
              name="Ports"
              rules={[{ required: true, message: 'Please input Ports!' }]}
            >
              <Input placeholder="Enter ports" />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item style={{ textAlign: 'center' }}>
              <Button type="primary" htmlType="submit" style={{ width: '50%' }}>
                {editData ? 'Update Tariff' : 'Add Tariff'}
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

export default ModalForm;