import { BrowserRouter as Router, Route, Routes, useLocation, Navigate } from "react-router-dom";
import Login from "../pages/auth/Login";
import Signup from "../pages/auth/Signup";
import Forget from "../pages/auth/Forget";
import Dashboard from "../pages/Dashboard";
import Vechical from "../pages/vechicals";
import AddVehical from "../pages/vechicals/AddVehical";
import ViewVehical from "../pages/vechicals/ViewVehical";
import Customer from "../pages/customer";
import ViewCustomer from "../pages/customer/View";
import BlForm from "../pages/blform/Form";
import Quotation from "../pages/quotation";
import QuotationView from "../pages/quotation/View";
import Vendors from "../pages/vendors";
import VendorQuotation from "../pages/vendorquotation/View";
import BrowseVendor from "../pages/vendorquotation";
import VendorQuotationForm from "../pages/vendorquotation/Form";
import VendorForm from "../pages/vendors/Form";
import ViewVendor from "../pages/vendors/View";
import BookSchedule from "../pages/schedule";
import ReportInvoice from "../pages/report/ReportInvoice";
import Employees from "../pages/employees";
import EmployeeForm from "../pages/employees/Form";
import ViewEmployee from "../pages/employees/View";
import TowingTarf from "../pages/towingtarf";
import AddTariff from "../pages/towingtarf/ModalForm";
import Accounts from "../pages/accounts/Accounts";
import CreditNotes from "../pages/accounts/CreditNotes";
import DebitNotes from "../pages/accounts/DebitNotes";
import ReceiptCollect from "../pages/accounts/ReceiptCollect";
import AddRecepit from "../pages/accounts/AddRecepit";
import Coasting from "../pages/accounts/Coasting";
import AddCoastngExpenses from "../pages/accounts/AddCoastngExpenses";
import CustomerLedger from "../pages/accounts/CustomerLedger";
import PaymentVoucher from "../pages/accounts/PaymentVoucher";
import ExpenseVoucher from "../pages/accounts/ExpenseVoucher";
import VendorPaymentList from "../pages/accounts/VendorPaymentList";
import VendorContainerPayment from "../pages/accounts/VendorContainerPayment";
import Setting from "../pages/setting/Setting";
import ContainerSize from "../pages/setting/ContainerSize";
import Log from "../pages/setting/Log";
import Invoice from "../pages/invoices";
import LoadingPlan from "../pages/loadingplan";
import LoadingImo from "../pages/loadingplan/LoadingImo";
import LoadingClient from "../pages/loadingclient";
import ViewLoadingContainer from "../pages/loadingplan/View";
import SaveViewContainer from "../pages/loadingsaveplan/View";
import EditSavePlan from "../pages/loadingsaveplan/Edit";
import EditLoadingPlan from "../pages/loadingplan/Edit";
import LoadingVehicleDetail from "../pages/loadingplan/LoadingVehicleDetail";
import LoadingAdd from "../pages/loadingplan/LoadingAdd";
import GenerateLoadPlan from "../pages/loadingplan/GenerateLoadPlan";
import ViewLoadingPlan from "../pages/loadingclient/View";
import SaveContainer from "../pages/loadingsaveplan";
import DockReceipt from "../pages/loadingplan/DockReceipt";
import Email from "../pages/headericon/Email";
import EditProfile from "../pages/headericon/EditProfile";
import AuthLayout from "../layout/auth";
import MainLayout from "../layout";
import CustomerForm from "../pages/customer/Form";
import QuotationForm from "../pages/quotation/Form";

function Navigation() {

  const AuthRedirect = ({ destination }) => {

    const location = useLocation();
    return <Navigate to={destination} replace state={{ from: location }} />;
  }

  const wrapper = (Layout, title = null, Component) => {

    let accessToken = localStorage.getItem('token');

    const redirect = <AuthRedirect destination={'/login'} />

    if (accessToken == null || !accessToken) return redirect;
    return (
      <>
        <Layout title={title}>
          {Component}
        </Layout>
      </>
    )
  }

  // const [userRole, setUserRole] = useState(localStorage.getItem('role') ?? null)
  // const [loginKey, setLoginKey] = useState(0);

  // const isAdmin = userRole === ADMIN;

  // useEffect(() => {
  //   setUserRole(localStorage.getItem('role'))
  // }, [loginKey]);

  // const handleLogin = () => {
  //   setLoginKey(key => key + 1);
  // };

  // const handleComponent = (AdminComponent = null, ClientComponent = null) => {

  //   if (isAdmin && AdminComponent) {
  //     return <AdminComponent />;
  //   }
  //   // if (userRole === CLIENT && ClientComponent) {
  //   if (!isAdmin && ClientComponent) {
  //     return <ClientComponent />;
  //   }
  //   return "NotFound";
  // }

  return (
    <Router>
      <Routes>
        {/* auth */}
        <Route path="/login" element={<AuthLayout><Login /></AuthLayout>} />
        <Route path="/signup" element={<AuthLayout><Signup /></AuthLayout>} />
        <Route path="/forget" element={<AuthLayout><Forget /></AuthLayout>} />

        {/* dashboard */}
        <Route path="/" element={wrapper(MainLayout, 'Dashboard', <Dashboard />)} />

        {/* vehicle */}
        <Route path="/vehicles" element={wrapper(MainLayout, 'Vehicles', <Vechical />)} />
        <Route path="/vehicles/adds" element={wrapper(MainLayout, 'Vehicles', <AddVehical />)} />
        <Route path="/vehicles/view/:id?" element={wrapper(MainLayout, 'Vehicles', <ViewVehical />)} />

        {/* customer */}
        <Route path="/customers" element={wrapper(MainLayout, 'Customers', <Customer />)} />
        <Route path="/customers/form/:id?" element={wrapper(MainLayout, 'Customers', <CustomerForm />)} />
        <Route path="/customers/view/:id?" element={wrapper(MainLayout, 'Add Customer', <ViewCustomer />)} />

        {/* bl-form */}
        <Route path="/bl-forms" element={wrapper(MainLayout, 'BL Forms', <BlForm />)} />

        {/* quotation */}
        <Route path="/quotations" element={wrapper(MainLayout, 'Quotation', <Quotation />)} />
        <Route path="/quotations/views/:id?" element={wrapper(MainLayout, 'Quotation', <QuotationView />)} />
        <Route path="/quotations/form/:id?" element={wrapper(MainLayout, 'Quotation', <QuotationForm />)} />

        {/* vendor */}
        <Route path="/vendors" element={wrapper(MainLayout, 'Vendors', <Vendors />)} />
        <Route path="/vendors/form/:id?" element={wrapper(MainLayout, 'Vendors', <VendorForm />)} />
        <Route path="/vendors/view/:id?s" element={wrapper(MainLayout, 'Vendors', <ViewVendor />)} />

        {/* vendorQuotation */}
        <Route path="/vendors/quotations" element={wrapper(MainLayout, 'Browse Vendors Quotation', <BrowseVendor />)} />
        <Route path="/vendors/quotations/views/:id?" element={wrapper(MainLayout, 'Vendor Quotation', <VendorQuotation />)} />
        <Route path="/vendors/quotations/form/:id?" element={wrapper(MainLayout, 'Vendor Quotation', <VendorQuotationForm />)} />

        <Route path="/reports" element={wrapper(MainLayout, 'Reports', <ReportInvoice />)} />

        <Route path="/books" element={wrapper(MainLayout, 'Booking Schedule', <BookSchedule />)} />

        {/* employee */}
        <Route path="/employees" element={wrapper(MainLayout, 'Employees', <Employees />)} />
        <Route path="/employees/form/:id?" element={wrapper(MainLayout, 'Employees', <EmployeeForm />)} />
        <Route path="/employees/view/:id?" element={wrapper(MainLayout, 'Employees', <ViewEmployee />)} />

        {/* towing */}
        <Route path="/tariffs" element={wrapper(MainLayout, 'Towing Tariff', <TowingTarf />)} />
        <Route path="/tariffs/add" element={wrapper(MainLayout, 'Towing Tariff', <AddTariff />)} />

        {/* Acounts */}
        <Route path="/accounts" element={wrapper(MainLayout, 'Accounts', <Accounts />)} />
        <Route path="/accounts/credit-notes" element={wrapper(MainLayout, 'Accounts', <CreditNotes />)} />
        <Route path="/accounts/debit-notes" element={wrapper(MainLayout, 'Accounts', <DebitNotes />)} />
        <Route path="/accounts/receipt-collects" element={wrapper(MainLayout, 'Accounts', <ReceiptCollect />)} />
        <Route path="/accounts/receipt-collects/add" element={wrapper(MainLayout, 'Accounts', <AddRecepit />)} />
        <Route path="/accounts/coastings" element={wrapper(MainLayout, 'Accounts', <Coasting />)} />
        <Route path="/accounts/coasting/add" element={wrapper(MainLayout, 'Accounts', <AddCoastngExpenses />)} />
        <Route path="/accounts/customer-ledgers" element={wrapper(MainLayout, 'Accounts', <CustomerLedger />)} />
        <Route path="/accounts/payment-vouchers" element={wrapper(MainLayout, 'Accounts', <PaymentVoucher />)} />
        <Route path="/accounts/expense-vouchers" element={wrapper(MainLayout, 'Accounts', <ExpenseVoucher />)} />
        <Route path="/accounts/vendor-payment-lists" element={wrapper(MainLayout, 'Accounts', <VendorPaymentList />)} />
        <Route path="/accounts/vendor-container-payments" element={wrapper(MainLayout, 'Accounts', <VendorContainerPayment />)} />

        {/* setting */}
        <Route path="/settings" element={wrapper(MainLayout, 'Settings', <Setting />)} />
        <Route path="/containers" element={wrapper(MainLayout, 'Settings', <ContainerSize />)} />
        <Route path="/logs" element={wrapper(MainLayout, 'Settings', <Log />)} />

        <Route path="/invoices" element={wrapper(MainLayout, 'Invoices', <Invoice />)} />

        {/* loadingplan */}
        <Route path="/loadings" element={wrapper(MainLayout, 'Loading Plan', <LoadingPlan />)} />
        <Route path="/loadings/views/:id?" element={wrapper(MainLayout, 'Loading Container', <ViewLoadingContainer />)} />
        <Route path="/loadings/edits/:id?" element={wrapper(MainLayout, 'Edit Loaded Plan', <EditLoadingPlan />)} />

        {/* loadingimo */}
        <Route path="/loadings/imos" element={wrapper(MainLayout, 'Loading Plan', <LoadingImo />)} />

        {/* LoadingAdd */}
        <Route path="/loadings/adds" element={wrapper(MainLayout, 'Loading Plan', <LoadingAdd />)} />

        {/* loadingclient */}
        <Route path="/loadings/clients" element={wrapper(MainLayout, 'Loading Plan', <LoadingClient />)} />
        <Route path="/loadings/clients/views/:id?" element={wrapper(MainLayout, 'View Load Plan Booking', <ViewLoadingPlan />)} />

        {/* loadingsaveconatiner */}
        <Route path="/loadings/save-containers" element={wrapper(MainLayout, 'Loading Plan', <SaveContainer />)} />
        <Route path="/loadings/save-containers/views/:id?" element={wrapper(MainLayout, 'Saved Container', <SaveViewContainer />)} />
        <Route path="/loadings/save-containers/edits/:id?" element={wrapper(MainLayout, 'Edit Saved Plan', <EditSavePlan />)} />

        <Route path="/loadings/generates-plans" element={wrapper(MainLayout, 'Generate Load Plan PDF', <GenerateLoadPlan />)} />
        <Route path="/loadings/vehical-details" element={wrapper(MainLayout, 'Loaded Vehicles Details', <LoadingVehicleDetail />)} />

        <Route path="/docks" element={wrapper(MainLayout, 'DockRecipt', <DockReceipt />)} />

        {/* header */}
        <Route path="/emails" element={wrapper(MainLayout, 'Email', <Email />)} />
        <Route path="/edit-profiles" element={wrapper(MainLayout, 'Edit Profile', <EditProfile />)} />

      </Routes>
    </Router>
  );
}
export default Navigation