import React, { useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import { Form, Input, Button, Typography, notification } from "antd";
import { MailOutlined, CloseCircleOutlined } from "@ant-design/icons";
import { useLocation } from "react-router-dom";
import ajaxService from "../services/ajax-service";
// import  from "../pages/auth/"; // Import the ForgetPassword component
const { Title } = Typography;
const ForgetPassword = ({onlogin}) => {
  let location = useLocation();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
 
  let { from } = location.state || { from: { pathname: "/" } };

  const onFinish = async (values) => {
    setLoading(true);
    const response = await ajaxService.getAccessToken(
      values.email,
      values.password,
      values.remember
    );
    const { success, token, data } = response;

    if (success ?? false) {
      localStorage.setItem("token", data.token);
      localStorage.setItem("name", data.name);
      localStorage.setItem("role", data.role);

      onlogin();
      navigate(from, { replace: true });
    } else {
      notification.open({
        message: response.message,
        icon: <CloseCircleOutlined style={{ color: "red" }} />,
      });
    }
    setLoading(false);
  };
  const onFinishFailed = (errorInfo) => {
    console.error("Failed:", errorInfo);
  };

  return (
    <div className="login-container">
      <div style={{ marginBottom: 15 }}>
        <div style={{ display: "flex", justifyContent: "center", marginBottom: 20}}>
          <img
            src="ctrade-logo.png"
            alt="ctrade-Logo"
            className="h-10 w-auto"
            style={{ width: "179px", height: "96px" }}
          />
        </div>

        <Title id="title" style={{ fontSize: "20px", textAlign:"center" , marginBottom: 20}}>
        Forgot Password
        </Title>
      </div>  
      <Form
        layout="vertical"
        style={{ width: "350px" }}
        name="basic"
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        initialValues={{ email: location.state?.email ?? null }}
      >
        <Form.Item
          className="login-form-item"
          name="email"
          rules={[
            { type: "email", message: "The input is not valid email!" },
            { required: true, message: "Please input your email!" },
          ]}
        >
          <Input
            prefix={<MailOutlined />}
            type="email"
            style={{ borderRadius: "16px", height: "40px" }}
            placeholder="Enter your email"
            allowClear
          />
        </Form.Item>
        <div
          style={{ display: "flex", justifyContent: "space-between", marginBottom:2 }}
        >
          <Button
            style={{
              width: "30%",
              borderRadius: "16px",
              height: "40px",
            }}
            type="primary"
            htmlType="submit"
            loading={loading}
          >
            submit
          </Button>
          <Form.Item>
          <Link to="/SignUp" className="text-blue underline text-black ">
            create an account?
          </Link>
          </Form.Item>
        </div>
      </Form>
    </div>
  );
};

export default ForgetPassword;
