import React from "react";
import { Table, Space, Row, Col, Card, Divider, Form, Input } from "antd";
import { EditOutlined, DeleteOutlined } from "@ant-design/icons";

const { Meta } = Card;

function ViewLoadingPlan() {
  const fieldData = [
    { label: "Container ID", value: "" },
    { label: "Destination", value: "" },
    { label: "Shipping Line", value: "" },
    { label: "ERD Date", value: "" },

    { label: "ETD Date", value: "" },
    { label: "Title Cut Off", value: "" },
    { label: "Cargo Cut Off ", value: "" },
    { label: "NO FILE SHARED", value: "Enter country name" },
    { label: "ETA Date", value: "Enter country name" },
    { label: "Location", value: "Enter country name" },
    { label: "Booking Type", value: "" },
    { label: "Container Size", value: "Enter country name" },
    { label: "Booking Number", value: "Enter country name" },
    { label: "Auto Cut Off", value: "Enter country name" },
    { label: "Doc Cut Off", value: "Enter country name" },
  ];
  const columns = [
    {
      title: "S.no",
      dataIndex: "S.no",
      key: "S.no",
      render: (text, record, index) => index + 1,
    },
    {
      title: "Year",
      dataIndex: "Year",
      key: "Year",
    },
    {
      title: "Make",
      dataIndex: "Make",
      key: "Make",
    },
    {
      title: "Model",
      dataIndex: "Model",
      key: "Model",
    },
    {
      title: "Vin #",
      dataIndex: "Vin ",
      key: "Vin ",
    },
    {
      title: "Title",
      dataIndex: "Title",
      key: "Title",
    },
    {
      title: "Title State",
      dataIndex: "TitleState",
      key: "TitleState",
    },
    {
      title: "Value",
      dataIndex: "Value",
      key: "Value",
    },
    {
      title: "Weight",
      dataIndex: "Weight",
      key: "Weight",
    },

    {
      title: "Image",
      dataIndex: "Image",
      key: "Image",
      render: (text, record) => (
        <img
          src={record.Image} // URL of the image
          alt="Vehicle"
          style={{
            width: "70px",
            height: "50px",
            objectFit: "contain",
            borderRadius: "4px",
          }}
        />
      ),
    },
  ];
  const data = [
    {
      S_no: "13574",
      Year: "MEHMOOD",
      Make: "60",
      Model: "7$",
      Vin: "59",
      Title: "13574",
      TitleState: "$43",
      Value: "141",
      Weight: "3439",
      Image: "/images/image 9.png",
    },
    {
      S_no: "13574",
      Year: "MEHMOOD",
      Make: "60",
      Model: "7$",
      Vin: "59",
      Title: "13574",
      TitleState: "$43",
      Value: "141",
      Weight: "3439",
      Image: "/images/image 9.png",
    },
  ];
  return (
    <>
      <Row gutter={24} wrap={false}>
        {/* First Column */}
        <Col flex="370px">
          <Card style={{ padding: "15px" }}>
            {/* First Section: Name and Email */}
            <Meta title="Hanniya Siddiqui" style={{ textAlign: "center" }} />
            <div style={{ marginTop: "10px", textAlign: "center" }}>
              <p> haniya.siddiqui124@gmail.com</p>
            </div>
            <Divider />
            <div className="profile-info">
              <p>
                <strong>Phone:</strong> 12345678900
              </p>
              <p>
                <strong>Address:</strong> Sharjah
              </p>
              <p>
                <strong>Town:</strong> Sharjah
              </p>
              <p>
                <strong>City:</strong> Sharjah
              </p>
              <p>
                <strong>Country:</strong> United Arab Emirates
              </p>
              <p>
                <strong>Secondary Email:</strong> -----------
              </p>
            </div>
            <Divider />
            <div style={{ textAlign: "center" }}>
              <Space>
                <EditOutlined style={{ fontSize: "20px", cursor: "pointer" }} />
                <DeleteOutlined
                  style={{ fontSize: "20px", cursor: "pointer" }}
                />
              </Space>
            </div>
          </Card>
        </Col>
        {/* Second Column */}
        <Col flex="1">
          <div
            style={{
              padding: "15px",
              border: "1px solid #ccc",
              borderRadius: "8px",
              backgroundColor: "#FFFFFF",
            }}
          >
            <Row gutter={12}>
              <Col span={12}>
                <h1 style={{ fontWeight: "bold" }}>View Details</h1>
              </Col>
            </Row>

            <Table
              columns={columns}
              scroll={{ x: "max-content" }}
              dataSource={data}
              style={{ marginTop: "10px" }}
            />
          </div>

          <div
            style={{
              padding: "15px",
              border: "1px solid #ccc",
              borderRadius: "8px",
              backgroundColor: "#FFFFFF",
              marginTop: "20px",
            }}
          >
            <Row gutter={12}>
              <Col span={12}>
                <h1 style={{ fontWeight: "bold" }}>Load Plan Details</h1>
              </Col>
            </Row>
            <Row gutter={24} className="mt-3">
              {fieldData.map((field, index) => (
                <Col span={12}>
                  <div
                    style={{
                      display: "flex",
                      width: 310,
                      border: "1px solid #d9d9d9",
                      borderRadius: 4,
                      marginBottom: 2,
                    }}
                  >
                    <div
                      style={{
                        flex: 1,
                        padding: "4px 11px",
                        backgroundColor: "#f5f5f5",
                        borderRight: "1px solid #d9d9d9",
                      }}
                    >
                      {field.label}
                    </div>
                    <p
                      style={{
                        flex: 1,
                        margin: 0,
                        padding: "4px 11px",
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      {field.value}
                    </p>
                  </div>
                </Col>
              ))}
            </Row>
          </div>

          <Form
            requiredMark={false}
            layout="vertical"
            initialValues={{ layout: "vertical" }}
            className="mt-5"
          >
            <Row gutter={24} align="bottom">
              <Col span={12}>
                <Form.Item
                  label={
                    <span style={{ fontWeight: "bold" }}>Special Note:</span>
                  }
                >
                  <Input.TextArea
                    placeholder="Enter special notes here..."
                    rows={5}
                  />
                </Form.Item>
              </Col>

              <Col span={12} style={{ textAlign: "right" }}>
                <Form.Item
                  label={
                    <span style={{ fontWeight: "bold" }}>Updation Note:</span>
                  }
                >
                  <Input.TextArea
                    placeholder="Enter special notes here..."
                    rows={5}
                  />
                </Form.Item>
              </Col>
            </Row>
          </Form>

          <Row gutter={24} className="mb-5">
            <Col span={24}>
              <div
                style={{
                  display: "flex",
                  width: 695,
                  border: "1px solid #d9d9d9",
                  borderRadius: 10,
                  marginBottom: 2,
                }}
              >
                <div
                  style={{
                    flex: 0.5,
                    padding: "4px 11px",
                    color: "#b91111",
                    border: "1px solid #b91111",
                    borderRadius: 5,

                    // backgroundColor: "#b91111",
                    borderRight: "1px solid #b91111",
                  }}
                >
                  Booking has been rejected
                </div>
                <p
                  style={{
                    flex: 1.5,
                    margin: 0,
                    padding: "4px 11px",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  Reason: Container unavailability / wooden floor not available
                  at the port.
                </p>
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
      <style>
        {`
  .profile-info {
    display: flex;
    flex-direction: column; /* Stack items vertically */
    gap: 15px; /* Space between each <p> element */
  }

  .profile-info p {
    display: flex; /* Makes each <p> a flex container */
     justify-content:space-between;
  }

  .profile-info strong {
    margin-right: 25px; /* Space between <strong> and <p> content */
    width: 100px; /* You can adjust this value to ensure equal space between all labels */
    text-align: left; /* Optional: Align the <strong> to the left */
  }
`}
      </style>
    </>
  );
}

export default ViewLoadingPlan;
