import { notification } from "antd";
import ajaxService from "./ajax-service";
import { CloseCircleOutlined } from "@ant-design/icons";

class VehicalService {
    async get(url) {
        const response = await ajaxService.get(url);
        return response;
    }
    async post(url, data, id) {
        const response = await ajaxService.get(url);
        if (response.success) {
            return response
        } else {
            notification.open({
                message: response.message,
                icon: <CloseCircleOutlined style={{ color: "red" }} />,
            });
        }
    }
}
const vehicalService = new VehicalService()

export default vehicalService