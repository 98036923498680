import React, { useState } from 'react'
import { Table, Space, Button, Row, Col, Input, Modal } from 'antd';
import { EditOutlined, SearchOutlined, EyeOutlined, DeleteOutlined } from '@ant-design/icons'
import TariffModalForm from './ModalForm';
// import EditTariff from './EditTariff';

const data = [
  {
    key: '653',
    Auction: "SC - GREENVILLE",
    USD: "425.00",
    State: "SOUTH CAROLINA",
    Code: "SC",
    Ports: "SAVANNAH",
  },
  {
    key: '653',
    Auction: "SC - GREENVILLE",
    USD: "425.00",
    State: "SOUTH CAROLINA",
    Code: "SC",
    Ports: "SAVANNAH",
  },
  {
    key: '653',
    Auction: "SC - GREENVILLE",
    USD: "425.00",
    State: "SOUTH CAROLINA",
    Code: "SC",
    Ports: "SAVANNAH",
  },
  {
    key: '653',
    Auction: "SC - GREENVILLE",
    USD: "425.00",
    State: "SOUTH CAROLINA",
    Code: "SC",
    Ports: "SAVANNAH",
  },
  {
    key: '653',
    Auction: "SC - GREENVILLE",
    USD: "425.00",
    State: "SOUTH CAROLINA",
    Code: "SC",
    Ports: "SAVANNAH",
  },
  {
    key: '653',
    Auction: "SC - GREENVILLE",
    USD: "425.00",
    State: "SOUTH CAROLINA",
    Code: "SC",
    Ports: "SAVANNAH",
  },
];

function TowingTarf() {
  const [isModalshow, setIsModalShow] = useState()
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const [editData, setEditData] = useState(null);

  const handleEdit = (record) => {
    setEditData(record); 
    setModalVisible(true);
  };
  const handleModalClose = () => {
    setModalVisible(false);
  }

  const showModalHandler = () => {
    setIsModalShow(true);
  };
  const handleOk = () => {
    setIsModalShow(false)
  };
  const handleCancel = () => {
    setIsModalShow(false)
  };

  const handleOpenModal = () => {
    setIsModalVisible(true);
  };
  const handleCloseModal = () => {
    setIsModalVisible(false);
  };
 

  const columns = [
    {
      title: "#",
      dataIndex: '#',
      key: '#   ',
      render: (text, record, index) => index + 1,
    },
    {
      title: "Auction",
      dataIndex: 'Auction',
      key: 'Auction',
    },
    {
      title: "USD",
      dataIndex: 'USD',
      key: 'USD',
    },
    {
      title: "State",
      dataIndex: 'State',
      key: 'State',
    },
    {
      title: "Code",
      dataIndex: 'Code',
      key: 'Code',
    },
    {
      title: "Ports",
      dataIndex: 'Ports',
      key: 'Ports',
    },
    {
      title: 'Actions',
      key: 'Actions',
      render: (text, record) => (
        <Space size='small'>
          <Button type='primary' size="small"  onClick={() => handleEdit(record)}>
            <EditOutlined   /> 
        
          </Button>
          <Button type='primary' size="small" onClick={handleOpenModal}>
            <EyeOutlined  />
          </Button>
          <Button type='primary' size="small"  onClick={showModalHandler}>
            <DeleteOutlined />
          </Button>

        </Space >
      )
    },
  ];

  const deleteModal = (
    <Modal
      title={<div style={{ textAlign: 'center' }}>Confirm</div>}
      visible={isModalshow}
      onOk={handleOk}
      onCancel={handleCancel}
      centered
      bodyStyle={{
        padding: "20px", // Adjust padding for smaller size
      }}
      width={300} // Adjust modal width
      footer={
        <div className='flex justify-center gap-3'>
          <Button type="default" onClick={handleCancel} style={{ width: "30%" }}>
            Cancel
          </Button>
          <Button type="primary" onClick={handleOk} style={{ width: "30%" }}>
            Confirm
          </Button>
        </div>
      }
    >
      <p style={{ textAlign: 'center' }}>Are you sure you want to delete this?</p>
    </Modal>
  )

  return (
    <div style={{ padding: '15px', border: '1px solid #ccc', borderRadius: '8px', backgroundColor: "#FFFFFF" }}>
      <Row gutter={24} style={{ padding: "5px", borderRadius: '4px' }} className='flex justify-between'>
        <Col span={10}  >
          <Space direction="horizontal" size={15} >
            <h5 className='font-bold'>Tariff</h5>
          </Space>
        </Col>
        <Col span={14} style={{ textAlign: "right" }} >
          <Input
            placeholder="Search"
            prefix={<SearchOutlined />}
            className="w-auto lg:w-2/5"
          />
        </Col>
      </Row>
      <Table columns={columns} scroll={{ x: "max-content" }} dataSource={data} style={{ marginTop: '10px' }} />

      <TariffModalForm visible={modalVisible} onClose={handleModalClose} editData={editData} />
      {/* <EditTariff visible={isModaView} onClose={CloseModal} /> */}

      {/* view */}
      <Modal
        title={
          <div style={{ display: "flex", justifyContent: "center" }}>
            <span>View Tariff</span>
          </div>
        }
        open={isModalVisible}
        onCancel={handleCloseModal}
        bodyStyle={{
          padding: "40px", // Adjust padding for smaller size
        }}
        width={450}
        footer={null}
        centered
      >

        <Row gutter={24}>
          <Col span={24}>
            <div
              style={{
                display: "flex",
                width: 320,
                border: "1px solid #d9d9d9",
                borderRadius: 4,
                marginBottom: 9,
                marginLeft: "auto",
                marginRight: "auto"
              }}>
              <div
                style={{
                  flex: 1,
                  padding: "4px 11px",
                  backgroundColor: "#f5f5f5",
                  borderRight: "1px solid #d9d9d9",
                  display: "flex",
                  alignItems: "left",
                }}
              >
                Auction:
              </div>
              <div
                style={{
                  flex: 1,
                  padding: "4px 11px",
                  display: "flex",
                  alignItems: "left",
                }}
              >
                SC - GREENVILLE
              </div>
            </div>
          </Col>
          <Col span={24}>
            <div
              style={{
                display: "flex",
                width: 320,
                border: "1px solid #d9d9d9",
                borderRadius: 4,
                marginBottom: 9,
                marginLeft: "auto",
                marginRight: "auto"
              }}
            >
              <div
                style={{
                  flex: 1,
                  padding: "4px 11px",
                  backgroundColor: "#f5f5f5",
                  borderRight: "1px solid #d9d9d9",
                  display: "flex",
                  alignItems: "left",
                }}
              >
                State:
              </div>
              <div
                style={{
                  flex: 1,
                  padding: "4px 11px",
                  display: "flex",
                  alignItems: "left",
                }}
              >
                SOUTH CAROLINA
              </div>
            </div>
          </Col>
          <Col span={24}>
            <div
              style={{
                display: "flex",
                width: 320,
                border: "1px solid #d9d9d9",
                borderRadius: 4,
                marginBottom: 9,
                marginLeft: "auto",
                marginRight: "auto"
              }}
            >
              <div
                style={{
                  flex: 1,
                  padding: "4px 11px",
                  backgroundColor: "#f5f5f5",
                  borderRight: "1px solid #d9d9d9",
                  display: "flex",
                  alignItems: "left",
                }}
              >
                Ports:
              </div>
              <div
                style={{
                  flex: 1,
                  padding: "4px 11px",
                  display: "flex",
                  alignItems: "left",
                }}
              >
                SAVANNAH
              </div>
            </div>
          </Col>
          <Col span={24}>
            <div
              style={{
                display: "flex",
                width: 320,
                border: "1px solid #d9d9d9",
                borderRadius: 4,
                marginBottom: 14,
                marginLeft: "auto",
                marginRight: "auto",
              }}
            >
              <div
                style={{
                  flex: 1,
                  padding: "4px 11px",
                  backgroundColor: "#f5f5f5",
                  borderRight: "1px solid #d9d9d9",
                  display: "flex",
                  alignItems: "left",
                }}
              >
                USD:
              </div>
              <div
                style={{
                  flex: 1,
                  padding: "4px 11px",
                  display: "flex",
                  alignItems: "left",
                }}
              >
                $ 425.00
              </div>
            </div>
          </Col>
          <Col span={24}>
            <div
              style={{
                display: "flex",
                width: 320,
                border: "1px solid #d9d9d9",
                borderRadius: 4,
                marginBottom: 20,
                marginLeft: "auto",
                marginRight: "auto",
              }}
            >
              <div
                style={{
                  flex: 1,
                  padding: "4px 11px",
                  backgroundColor: "#f5f5f5",
                  borderRight: "1px solid #d9d9d9",
                  display: "flex",
                  alignItems: "left",
                }}
              >
                Code: SC
              </div>
              <div
                style={{
                  flex: 1,
                  padding: "4px 11px",
                  display: "flex",
                  alignItems: "left",
                }}
              >
                SC
              </div>
            </div>
          </Col>
        </Row>
        <div
          className="flex justify-center"
          style={{ borderTop: "1px solid #d9d9d9", paddingTop: "20px" }}
        >
          <EditOutlined style={{ cursor: "pointer", fontSize: "20px" }} />
        </div>

      </Modal>

      {/* delete */}
      {deleteModal}
      {/* <FormModal visible={visible} onClose={onClose} /> */}

    </div>
  )
}
export default TowingTarf
