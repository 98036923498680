import React from 'react'
import { Table, Space, Button, Row, Col, Input } from 'antd';
import { EyeOutlined, SearchOutlined } from '@ant-design/icons'
function InvoiceDue() {
  const columns = [
    {
      title: "#",
      dataIndex: '#',
      key: '_id',
      render: (text, record, index) => index + 1,
    },
    {
      title: "Invoice",
      dataIndex: 'Invoice',
      key: 'Invoice',
    },
    {
      title: "Invoice Date",
      dataIndex: 'InvoiceDate',
      key: 'InvoiceDate',
    },
    {
      title: "status",
      dataIndex: 'status',
      key: 'status',
    },
    {
      title: "View",
      title: 'View',
      key: 'View',
      render: (text, { _id }) => (
        <Space size='small'>
          <Button type='primary' size="small">
            <EyeOutlined />
          </Button>
        </Space >
      )
    },
  ];
  const data = [
    {
      key: '1',
      Invoice: "lorem ipsum",
      InvoiceDate: "lorem ipsum",
      status: 'John Brown',

    },
    {
      key: '2',
      Invoice: "lorem ipsum",
      InvoiceDate: "lorem ipsum",
      status: 'Jim Green',
    },
    {
      key: '3',
      Invoice: "lorem ipsum",
      InvoiceDate: "lorem ipsum",
      status: 'Joe Black',
    },
    {
      key: '4',
      Invoice: "lorem ipsum",
      InvoiceDate: "lorem ipsum",
      status: 'Joe Black',
    },

  ];
  return (
    <div style={{ padding: '20px', border: '1px solid #ccc', borderRadius: '8px' }}>
      <Row gutter={16}>
        <Col span={12}>
          <h1 style={{ fontWeight: "bold" }}>Invoice Due</h1>
        </Col>
        <Col span={12} style={{ textAlign: "right" }}     >
          <h1 style={{ fontWeight: "bold" }}   >Amount: $0</h1>
        </Col>
      </Row>
      <Row gutter={24} style={{ marginTop: '20px', background: "#D9D9D9", padding: "10px", borderRadius: '4px' }} className='flex justify-between'>
        <Col span={10}  >
          <Space direction="horizontal" size={10} >
            <img src="/images/ve.png" alt="" />
            <img src="/images/ve2.png" alt="" />
            <img src="/images/ve3.png" alt="" />
            <img src="/images/ve4.png" alt="" />
            <img src="/images/v35.png" alt="" />
          </Space>
        </Col>
        <Col span={14} style={{ textAlign: "right" }} >
          <Input
            placeholder="Search"
            prefix={<SearchOutlined />}
            style={{ width: 200 }}
          />
        </Col>
      </Row>
      <Row gutter={24}>
      <Col span={24} >
      <Table columns={columns} dataSource={data} style={{ marginTop: '20px' }} />
      </Col>
      </Row>
    </div>
  )
}
export default InvoiceDue
