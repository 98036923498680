import React, { useEffect, useState } from 'react';
import { Button, Col, Form, Input, Row, DatePicker, Upload } from 'antd';
import { Select, Typography } from 'antd';
import { useParams } from 'react-router-dom';

const { Option } = Select;
const { Title } = Typography;

const dummyData = [
    { id: '1', name: 'vehile' },
    { id: '2', name: 'invoice_no' },
    { id: '3', name: 'operating_port' },
    { id: '4', name: 'towing_location' },
    { id: '5', name: 'operating_port' },
    { id: '6', name: 'carrier_name' },
    { id: '7', name: 'year' },
    { id: '8', name: 'invoice_no' },
    { id: '9', name: 'invoice_no' },
    { id: '10', name: 'invoice_no' },
    { id: '12', name: 'invoice_no' },

];

function EmployeeForm() {
  const { id } = useParams();  // Get 'id' from the URL
    console.log("employeeform",id)

    const [form] = Form.useForm();
    const [selectedEntities, setSelectedEntities] = useState([]);
    
    const handleSelectChange = (value) => {
        setSelectedEntities(value);
    };

    const calculateWidth = (dataLength) => {
        const baseWidth = 400; // Default width
        const additionalWidth = dataLength * 20; // Add extra width for more data
        return baseWidth + additionalWidth;
    };
    const isEditMode = id !== undefined;
    useEffect(() => {
        setSelectedEntities(dummyData.map((entity) => entity.id));
    }, []);

    return (
        <div
            style={{
                padding: '0 14px',
                border: '1px solid #ccc',
                borderRadius: '8px',
                backgroundColor: '#FFFFFF',
            }}
        >
            <Row
                gutter={26}
                style={{
                    backgroundColor: '#FBFBFB',
                    marginBottom: 15,
                    padding: '10px',
                    borderRadius: '4px',
                    borderBottom: '1px solid #E8E8E9',
                }}
            >
                <Col span={12}>
                    <h1 style={{ fontWeight: 'bold' }}> {isEditMode ? 'Edit Employee' : 'Add Employee'}</h1>
                </Col>
            </Row>

            <Form
                requiredMark={false}
                   layout="vertical"
                form={form}
            >
                <Row gutter={24}>
                    <Col span={6}>
                        <Form.Item label="Name">
                            <Input placeholder="" />
                        </Form.Item>
                    </Col>

                    <Col span={6}>
                        <Form.Item label="Address">
                            <Input placeholder="" />
                        </Form.Item>
                    </Col>

                    <Col span={6}>
                        <Form.Item label="CNIC">
                            <Input placeholder="28-Oct-2024" />
                        </Form.Item>
                    </Col>

                    <Col span={6}>
                        <Form.Item label="Date of Birth ">
                            <Input placeholder="0" />
                        </Form.Item>
                    </Col>

                    <Col span={6}>
                        <Form.Item label=" Date of Joining">
                            <DatePicker style={{ width: '100%' }} placeholder="Select Date" />
                        </Form.Item>
                    </Col>

                    <Col span={6}>
                        <Form.Item label="Qualification">
                            <Input placeholder="" />
                        </Form.Item>
                    </Col>

                    <Col span={6}>
                        <Form.Item label="Salary">
                            <Input placeholder="" />
                        </Form.Item>
                    </Col>

                    <Col span={6}>
                        <Form.Item label="Designation">
                            <Input placeholder="" />
                        </Form.Item>
                    </Col>

                    <Col span={6}>
                        <Form.Item label="Username">
                            <Input placeholder="" />
                        </Form.Item>
                    </Col>

                    <Col span={6}>
                        <Form.Item label="Password">
                            <Input placeholder="" />
                        </Form.Item>
                    </Col>

                    <Col span={6}>
                        <Form.Item label="Profile Image" style={{ width: '100%' }}>
                            <Upload listType="picture" beforeUpload={() => false} style={{ width: '100%' }}>
                                <Input
                                    style={{
                                        width: '100%',
                                    }}
                                />
                            </Upload>
                        </Form.Item>
                    </Col>

                    <Col span={24} style={{ display: 'flex', justifyContent: 'center' }}>
                        <div style={{ width: calculateWidth(dummyData.length), margin: '30px' }}>
                            <Form.Item >
                                <Title level={5}>Permissions</Title>
                                <Select
                                    mode="multiple"
                                    style={{ width: '100%' }}
                                    placeholder="Select entities"
                                    value={selectedEntities}
                                    onChange={handleSelectChange}
                                >
                                    {dummyData.map((entity) => (
                                        <Option key={entity.id} value={entity.id}   >
                                            {entity.name}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </div>
                    </Col>

                    <Col span={24}>
                        <Form.Item style={{ textAlign: 'center', marginTop: 10 }}>
                            <Button type="primary" style={{ width: '50%' }}>
                            {isEditMode ? 'EDIT EMPLOYEE' : 'ADD EMPLOYEE'}
                            </Button>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </div>
    );
}

export default EmployeeForm;
