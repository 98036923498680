import React from "react";
import LoginForm from "../../components/LoginForm";

const Login = () => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        height: "100vh",
        border: "12px #ccc",
        boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
        borderRadius: "8px",
        overflow: "hidden",
      }}>
      {/* Left section with heading, paragraph, and image */}
      <div
        style={{
          flex: 1,
          border: "12px #ccc",
          padding: "15px",
          paddingTop: "2px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          paddingLeft: "50px",
          background: `
      linear-gradient(140.09deg, rgba(0, 0, 0, 0.9) 0%, rgba(0, 0, 0, 0.95) 100%), 
      url('bg.png')`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          borderRadius: "8px",
          overflow: "hidden",
        }}
      >
        <h1
          style={{
            fontSize: "48px",
            fontWeight: "bold",
            marginBottom: "10px",
            marginLeft: "2px",
            color: "white",
          }}
        >
          Welcome
          <div style={{ fontSize: "48px" }}>
            back to{" "}
            <span
              style={{
                background:
                  "linear-gradient(90deg, #500600 0%, #5A0600 24.06%, #B60D00 100%)",
                WebkitBackgroundClip: "text",
                backgroundClip: "text",
                color: "transparent",
                fontSize: "48px",
              }}
            >
              Ctrade
            </span>
          </div>

        </h1>
        <p
          style={{
            fontSize: "16px",
            lineHeight: "1.5",
            marginBottom: "15px",
            color: "white",
          }}
        >
          Effortlessly manage and streamline your operations with a few clicks. Access real-time data, manage tasks, and stay updated with ease. Login now to get started and enjoy a seamless experience designed to boost productivity and keep you connected.
        </p>
        <p
          style={{
            fontSize: "16px",
            lineHeight: "1.5",
            marginBottom: "15px",
            color: "white",
          }}
        >
          Your journey to smarter begins here.
        </p>
      </div>
      {/* Right section with Login Form */}
      <div
        style={{
          flex: 1, // Take equal space with the left section
          border: "12px #ccc", // Border around the right section
          padding: "20px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          borderRadius: "8px", // Optional rounded corners for the right section
          overflow: "hidden", // Prevent overflow and scroll
        }}
      >
        <div style={{ width: "100%", maxWidth: "400px" }}>
          <LoginForm />
        </div>
      </div>
    </div>
  );
};

export default Login;
