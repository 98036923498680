import React from 'react'
import {  Button, Row, Col, Table, Space, Select, Input, Divider } from 'antd';
import { EditOutlined, SearchOutlined, SendOutlined } from '@ant-design/icons'

function SaveViewContainer() {
  const fieldData = [
    { label: "Container ID", value: "" },
    { label: "Location", value: "" },
    { label: "Destination", value: "" },
    { label: "Container Size", value: "" },
    { label: "Booking No", value: "" },
    { label: "Container No", value: "" },
    { label: "Posted on ", value: "" },
    { label: "Last Updated", value: "" },
  ]
    const columns = [
        {
          title:" S.no",
          dataIndex: " S.no",
          key: " S.no",
          render: (text, record, index) => index + 1,
        },
        {
          title: "Vehicle",
          dataIndex: 'Vehicle',
          key: 'Vehicle',
        },
        {
          title: "Chassis_no",
          dataIndex: 'Chassis_no',
          key: 'Chassis_no',
        },
        {
          title: "Engine Type",
          dataIndex: 'Engine_Type',
          key: 'Engine_Type',
        },
        {
          title: "Value",
          dataIndex: 'Value',
          key: 'Value',
        },
        {
          title: "Weight",
          dataIndex: 'Weight',
          key: 'Weight',
        },
       
        {
          title: 'Action',
          key: 'Action',
          render: (text, { _id }) => (

            <div style={{display: 'flex', alignItems: 'center',gap:8 }}>
            <span
              style={{
               
                backgroundColor: '#2D3748',
                borderRadius:"5px",
                color: 'white',
                padding: '2px 5px',
                fontSize: '12px',
              }}
            >
              0 Off Load
            </span>
                <Button type='primary' size="small">
                  <EditOutlined />
                </Button>
                </div>      
          )
        },
    
      ];
      const data= [
        {
          key: '13574',
          Vehicle: "FORD",
          Chassis_no: "1FDUF5HT0RDA27180",
          Engine_Type: "",
          Value: "",
          Weight: "3439",
        },
        {
            key: '13574',
            Vehicle: "FORD",
            Chassis_no: "1FDUF5HT0RDA27180",
            Engine_Type: "",
            Value: "",
            Weight: "3439",
          },
       
      ]
  return (
    <>
      <Row gutter={24} wrap={false}>
        <Col flex="280px">
          <div style={{ padding: 0, border: "1px solid #ccc", padding: 10, borderRadius: 5, backgroundColor: "#FFFFFF" }}>
            <div style={{ textAlign: 'center' }}>
              <img
                // src="/images/image 8.png"
                alt="No images found"
                style={{
                  width: '320px',
                  height: '200px',
                  borderRadius: '8px',
                  objectFit: 'cover',
                }}
              />
              <Button
                type="primary"
                style={{ marginTop: '10px' }}
              >
                Download Image
              </Button>
            </div>
            <Divider/>
            <div style={{textAlign:"center" }} >
            <SendOutlined style={{fontSize:"20px" ,border: "1px solid #ccc", padding:8,borderRadius:"10px"}}/>
          </div>
          </div>
        </Col>

        <Col flex="1">
          <div style={{ padding: '15px', border: '1px solid #ccc', borderRadius: '8px', backgroundColor: "#FFFFFF" }}>
            <Row gutter={24}>
            {fieldData.map((field, index) => (
                <Col span={12}>
                  <div
                    style={{
                      display: "flex",
                      width: 450,
                      border: "1px solid #d9d9d9",
                      borderRadius: 4,
                      marginBottom: 2,
                    }}
                  >
                    <div
                      style={{
                        flex: 1,
                        padding: "4px 11px",
                        backgroundColor: "#f5f5f5",
                        borderRight: "1px solid #d9d9d9",
                      }}
                    >
                      {field.label}
                    </div>
                    <p
                      style={{
                        flex: 1,
                        margin: 0,
                        padding: "4px 11px",
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      {field.value}
                    </p>
                  </div>
                </Col>
              ))}
           
            
            </Row>
            <div>
             <Row gutter={24} style={{ marginTop: '50px', background: "#FBFBFB", padding: "10px", borderRadius: '4px' }} className='flex justify-between'>
        <Col span={10}  >
          <Space direction="horizontal" size={10} >
            <p>Show</p>
            <Select defaultValue="10"
            //   options={options}
              size="small"
              style={{
                width: 60,
                height: 25,
                fontSize: 14,
              }} />
            <p>entries</p>
          </Space>
        </Col>

        <Col span={14} style={{ textAlign: "right" }} >
          <Input
            placeholder="Search"
            prefix={<SearchOutlined />}
            className="w-auto lg:w-2/5"
          />
        </Col>
      </Row>
              <Table columns={columns} scroll={{ x: "max-content" }} dataSource={data} style={{ marginTop: '5px' }} />
             </div>
            
          </div>
         
        </Col>
      </Row>
      
      <style>
        {`
  .profile-info {
    display: flex;
    flex-direction: column; /* Stack items vertically */
    gap: 5px; /* Space between each <p> element */
  }

  .profile-info p {
    display: flex; /* Makes each <p> a flex container */
  
  }

  .profile-info strong {
   margin-right: 10px; /* Space between <strong> and <p> content */
    width: 100px; /* You can adjust this value to ensure equal space between all labels */
  }

  
`}
      </style>
    </>
  )
}

export default SaveViewContainer

