import React from 'react'
import { Table, Space, Row, Col, Input, Select, Button } from 'antd';
import { DeleteOutlined, EditOutlined, EyeOutlined, SearchOutlined } from '@ant-design/icons'
import "./vehical.css"
function Vechical() {
  const options = [
    {
      value: '10',
      label: '10',
    },
    {
      value: '9',
      label: '9',
    },
  ]
  const columns = [
    {
      title: "Id",
      dataIndex: 'Id',
      key: '_id',
      render: (text, record, index) => index + 1,
    },
    {
      title: "Customer",
      dataIndex: 'Customer',
      key: 'Customer',
    },
    {
      title: "Free Days",
      dataIndex: 'FreeDays',
      key: 'FreeDays',
    },
    {
      title: "Per Day Charges($)",
      dataIndex: 'PerDayCharges',
      key: 'PerDayCharges',
    },
    {
      title: "Days Left",
      dataIndex: 'DaysLeft',
      key: 'DaysLeft',
    },
    {
      title: "Storage Charges($)",
      dataIndex: 'StorageCharges',
      key: 'StorageCharges',
    },
    {
      title: "Car Status",
      dataIndex: 'CarStatus',
      key: 'CarStatus',
    },
    {
      title: "User Id",
      dataIndex: 'UserId',
      key: 'UserId',
    },
    {
      title: "Lot #",
      dataIndex: 'Lot',
      key: 'Lot',
    },
    {
      title: "Invoice No",
      dataIndex: 'InvoiceNo',
      key: 'InvoiceNo',
    },
    {
      title: "Invoice Amount",
      dataIndex: 'InvoiceAmount',
      key: 'InvoiceAmount',
    },{
      title: "Product",
      dataIndex: 'Product',
      key: 'Product',
    },{
      title: "Vehicle",
      dataIndex: 'Vehicle',
      key: 'Vehicle',
    },
    {
      title: "Year",
      dataIndex: 'Year',
      key: 'Year',
    },{
      title: "Chasis No",
      dataIndex: 'ChasisNo',
      key: 'ChasisNo',
    },{
      title: "Date Rcd",
      dataIndex: 'DateRcd',
      key: 'DateRcd',
    },{
      title: "Operating Port",
      dataIndex: 'OperatingPort',
      key: 'OperatingPort',
    },{
      title: "Towing Location",
      dataIndex: 'TowingLocation',
      key: 'TowingLocation',
    },{
      title: "Booking No",
      dataIndex: 'BookingNo',
      key: 'BookingNo',
    },{
      title: "Carrier Name",
      dataIndex: 'CarrierName',
      key: 'CarrierName',
    },{
      title: "Storage",
      dataIndex: 'Storage',
      key: 'Storage',
    },{
      title: "Bol",
      dataIndex: 'Bol',
      key: 'Bol',
    },{
      title: "Title",
      dataIndex: 'Title',
      key: 'Title',
    },{
      title: "Keys",
      dataIndex: 'Keys',
      key: 'Keys',
    },{
      title: "Notes",
      dataIndex: 'Notes',
      key: 'Notes',
    },{
      title: "Container Number",
      dataIndex: 'ContainerNumber',
      key: 'ContainerNumber',
    },{
      title: "Tracking Number",
      dataIndex: 'TrackingNumber',
      key: 'TrackingNumber',
    },{
      title: "Arrival Date",
      dataIndex: 'ArrivalDate',
      key: 'ArrivalDate',
    },
    {
      title: "Release Status",
      dataIndex: 'ReleaseStatus',
      key: 'ReleaseStatus',
    },{
      title: "Destination",
      dataIndex: 'Destination',
      key: 'Destination',
    },{
      title: "File ref",
      dataIndex: 'Fileref',
      key: 'Fileref',
    },{
      title: "Price",
      dataIndex: 'Price',
      key: 'Price',
    },{
      title: "Buying Charges",
      dataIndex: 'BuyingCharges',
      key: 'BuyingCharges',
    },{
      title: "Arrival Date",
      dataIndex: 'ArrivalDate',
      key: 'ArrivalDate',
    },{
      title: "Selling Charges",
      dataIndex: 'SellingCharges',
      key: 'SellingCharges',
    },{
      title: "Yard Charges",
      dataIndex: 'YardCharges',
      key: 'YardCharges',
    },{
      title: "Transportation",
      dataIndex: 'Transportation',
      key: 'Transportation',
    },{
      title: "Labour",
      dataIndex: 'Labour',
      key: 'Labour',
    },{
      title: "Material",
      dataIndex: 'Material',
      key: 'Material',
    },{
      title: "Chassis",
      dataIndex: 'Chassis',
      key: 'Chassis',
    },
    {
      title: 'Action',
      key: 'Action',
      render: (text, { _id }) => (
        <Space size='small'>

          {/* <Link to="/edit-customer"> */}
            <Button type='primary' size="small">
              <EditOutlined />
            </Button>
          {/* </Link> */}

          {/* <Link to="/view-customer"> */}
            <Button type='primary' size="small">
              <EyeOutlined />
            </Button>
          {/* </Link> */}

          <Button type='primary' size="small">
            <DeleteOutlined />
          </Button>
        </Space >
      )
    },
  ];
  const data = [
    {
      key: '13574',
      Customer: "MEHMOOD",
      FreeDays: "60",
      PerDayCharges: "7$",
      DaysLeft: "59",
      StorageCharges: "13574",
      CarStatus: "$43",
      UserId: "141",
      Lot: "",
      InvoiceNo: "",
      InvoiceAmount:"0",
      Product:"DAKOTA",
      Vehicle:"DODGE",
      Year:"2005",
      ChasisNo:"1D7HW48N85S231917",
      DateRcd:"2024-10-30",
      OperatingPort:"HOUSTON",
      Title:"YES",
      Keys:"YES",
      Fileref:"UP-168",
      BuyingCharges:"0",
      SellingCharges:"0",
      YardCharges:"0"
    },
    {
      key: '13574',
      Customer: "MEHMOOD",
      FreeDays: "60",
      PerDayCharges: "7$",
      DaysLeft: "59",
      StorageCharges: "13574",
      CarStatus: "$43",
      UserId: "141",
      Lot: "",
      InvoiceNo: "",
      InvoiceAmount:"0",
      Product:"DAKOTA",
      Vehicle:"DODGE",
      Year:"2005",
      ChasisNo:"1D7HW48N85S231917",
      DateRcd:"2024-10-30",
      OperatingPort:"HOUSTON",
      Title:"YES",
      Keys:"YES",
      Fileref:"UP-168",
      BuyingCharges:"0",
      SellingCharges:"0",
      YardCharges:"0"
    },
    {
      key: '13574',
      Customer: "MEHMOOD",
      FreeDays: "60",
      PerDayCharges: "7$",
      DaysLeft: "59",
      StorageCharges: "13574",
      CarStatus: "$43",
      UserId: "141",
      Lot: "",
      InvoiceNo: "",
      InvoiceAmount:"0",
      Product:"DAKOTA",
      Vehicle:"DODGE",
      Year:"2005",
      ChasisNo:"1D7HW48N85S231917",
      DateRcd:"2024-10-30",
      OperatingPort:"HOUSTON",
      Title:"YES",
      Keys:"YES",
      Fileref:"UP-168",
      BuyingCharges:"0",
      SellingCharges:"0",
      YardCharges:"0"
    },
    {
      key: '13574',
      Customer: "MEHMOOD",
      FreeDays: "60",
      PerDayCharges: "7$",
      DaysLeft: "59",
      StorageCharges: "13574",
      CarStatus: "$43",
      UserId: "141",
      Lot: "",
      InvoiceNo: "",
      InvoiceAmount:"0",
      Product:"DAKOTA",
      Vehicle:"DODGE",
      Year:"2005",
      ChasisNo:"1D7HW48N85S231917",
      DateRcd:"2024-10-30",
      OperatingPort:"HOUSTON",
      Title:"YES",
      Keys:"YES",
      Fileref:"UP-168",
      BuyingCharges:"0",
      SellingCharges:"0",
      YardCharges:"0"
    },
    {
      key: '13574',
      Customer: "MEHMOOD",
      FreeDays: "60",
      PerDayCharges: "7$",
      DaysLeft: "59",
      StorageCharges: "13574",
      CarStatus: "$43",
      UserId: "141",
      Lot: "",
      InvoiceNo: "",
      InvoiceAmount:"0",
      Product:"DAKOTA",
      Vehicle:"DODGE",
      Year:"2005",
      ChasisNo:"1D7HW48N85S231917",
      DateRcd:"2024-10-30",
      OperatingPort:"HOUSTON",
      Title:"YES",
      Keys:"YES",
      Fileref:"UP-168",
      BuyingCharges:"0",
      SellingCharges:"0",
      YardCharges:"0"
    },
  ];
  return (
    <div style={{ padding: '15px', border: '1px solid #ccc', borderRadius: '8px', backgroundColor: "#FFFFFF" }}>
      <Row gutter={16}>
        <Col span={12}>
          <h1 style={{ fontWeight: "bold" }}>Vehicles</h1>
        </Col>

      </Row>
      <Row gutter={24} style={{ marginTop: '20px', background: "#F2F2F7", padding: "10px", borderRadius: '4px' }} className='flex justify-between'>
        <Col span={10}  >
          <Space direction="horizontal" size={10} >
            <p>Show</p>
            <Select defaultValue="10"
              options={options}
              size="small"
              // dropdownMatchSelectWidth={false} // Prevent dropdown from inheriting the small width
              style={{
                width: 60,
                height: 25,
                fontSize: 14,
              }} />
            <p>entries</p>
          </Space>
        </Col>

        <Col span={14} style={{ textAlign: "right" }} >
          <Input
            placeholder="Search"
            prefix={<SearchOutlined />}
            className="w-auto lg:w-2/5"
          />
        </Col>
      </Row>
      <Row gutter={24}>
        <Col span={24}>
        
      <Table columns={columns} dataSource={data} scroll={{ x: "max-content"}} style={{ marginTop: '20px' }}/>
        </Col>
      </Row>
    </div>
  )
}

export default Vechical

