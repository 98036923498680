
import React from 'react'
import { Table, Row, Col, Select, Form } from 'antd';

function ReportInvoice() {
  const options = [];
  for (let i = 10; i < 36; i++) {
    options.push({
      value: i.toString(36) + i,
      label: i.toString(36) + i,
    });
  }
  const handleChange = (value) => {
    console.log(`selected ${value}`);
  };

  const columns = [
    {
      title: "Month",
      dataIndex: 'Month',
      key: 'Month',
    },
    {
      title: "Sales",
      dataIndex: 'Sales',
      key: 'Sales',
    }, {
      title: "Collections",
      dataIndex: 'Collections',
      key: 'Collections',
    }, {
      title: "Payments",
      dataIndex: 'Payments',
      key: 'Payments',
    }, {
      title: "Details",
      dataIndex: 'Details',
      key: 'Details',
      render: (text, { _id }) => (
        <a href='' style={{ color: "#B83D3D" }}>
          Details
        </a>
      )
    },

  ];
  const data = [
    {
      Month: 'Jan',
      Sales: "$0.00",
      Collections: "$112,047.40",
      Payments: "$0.00",
      Details: "Detail",
    },
    {
      Month: 'Jan',
      Sales: "$0.00",
      Collections: "$112,047.40",
      Payments: "$0.00",
      Details: "Detail",
    },
    {
      Month: 'Jan',
      Sales: "$0.00",
      Collections: "$112,047.40",
      Payments: "$0.00",
      Details: "Detail",
    },
    {
      Month: 'Jan',
      Sales: "$0.00",
      Collections: "$112,047.40",
      Payments: "$0.00",
      Details: "Detail",
    },
    {
      Month: 'Jan',
      Sales: "$0.00",
      Collections: "$112,047.40",
      Payments: "$0.00",
      Details: "Detail",
    },
  ];
  return (
    <>
      <Form layout="horizontal" style={{ width: '100%' }}>
        <Form.Item
          label="Choose Report: "
          wrapperCol={{ flex: '1' }}
          style={{
            width: '50%',
          }}
        >
          <Select
            mode="tags"
            style={{
              width: '100%',
              height: '35px',
              fontSize: '16px',
            }}
            placeholder="Invoices"
            onChange={handleChange}
            options={options}
          />
        </Form.Item>
      </Form>

      <div style={{ padding: '15px', border: '1px solid #ccc', borderRadius: '8px', backgroundColor: "#FFFFFF" }}>
        <Row gutter={16}>
          <Col span={12}>
            <h1 style={{ fontWeight: "bold" }}>MONTHLY FLOW</h1>
          </Col>

        </Row>
        <Table columns={columns} scroll={{ x: "max-content" }} dataSource={data} style={{ marginTop: '10px' }} />
      </div>
    </>
  )
}

export default ReportInvoice

