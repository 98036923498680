
import { Table, Space, Row, Col, Input, Tag, Modal, Form, Select, Button } from 'antd';
import { SearchOutlined } from '@ant-design/icons'
import { CiSquarePlus } from "react-icons/ci";

import React, { useState } from 'react'

function VendorPaymentList() {
    const [isModalShow, setIsModalShow] = useState(false);

    const showModal = () => setIsModalShow(true);
  
    const handleOk = () => {
      setIsModalShow(false)
    }
    const handleCancel = () => {
      setIsModalShow(false)
    }

    const columns = [
        {
            title: "Invoice Number",
            dataIndex: 'InvoiceNumber',
            key: 'InvoiceNumber',
        },
        {
            title: "Booking Number",
            dataIndex: 'BookingNumber',
            key: 'BookingNumber',
        },
        {
            title: "Vendor Name",
            dataIndex: 'VendorName',
            key: 'VendorName',
        },
        {
            title: "Vendor Amount",
            dataIndex: 'VendorAmount',
            key: 'VendorAmount',
        },
        {
            title: "Paid Amount",
            dataIndex: 'PaidAmount',
            key: 'PaidAmount',
        },
        {
            title: "Due Amount",
            dataIndex: 'DueAmount',
            key: 'DueAmount',
        },
        {
            title: "Payment Date",
            dataIndex: 'PaymentDate',
            key: 'PaymentDate',
        },
        {
            title: "Status",
            dataIndex: 'Status',
            key: 'Status',
            render: (status) => {
                let color = "";
                switch (status) {
                    case "Complete":
                        color = "#06D652";
                        break;
                    case "Not Paid":
                        color = "#F04949";
                        break;
                    case "Partially Paid":
                        color = "#D68606";
                        break;
                    default:
                        color = "blue"; // Fallback color
                }
                return <Tag color={color} style={{ border: `1px solid ${color}`, padding: '5px' }}>{status}</Tag>;
            },
        },

        {
            title: 'Action',
            key: 'Action',
            render: (text, { _id }) => (
                <Space size='small'> 
                        <CiSquarePlus size={30} style={{ cursor: 'pointer' }} onClick={showModal}/>
                </Space >
            )
        },
    ];
    const data = [
        {
            InvoiceNumber: "3103",
            BookingNumber: "-",
            VendorName: "SEAMATES",
            VendorAmount: "2650",
            PaidAmount: "2650",
            DueAmount: "0",
            PaymentDate: "10 Mar,2023",
            Status: "Partially Paid"
        },
        {
            InvoiceNumber: "3103",
            BookingNumber: "-",
            VendorName: "SEAMATES",
            VendorAmount: "2650",
            PaidAmount: "2650",
            DueAmount: "0",
            PaymentDate: "10 Mar,2023",
            Status: "Complete"
        },
        {
            InvoiceNumber: "3103",
            BookingNumber: "-",
            VendorName: "SEAMATES",
            VendorAmount: "2650",
            PaidAmount: "2650",
            DueAmount: "0",
            PaymentDate: "10 Mar,2023",
            Status: "Partially Paid"
        },
        {
            InvoiceNumber: "3103",
            BookingNumber: "-",
            VendorName: "SEAMATES",
            VendorAmount: "2650",
            PaidAmount: "2650",
            DueAmount: "0",
            PaymentDate: "10 Mar,2023",
            Status: "Complete"
        },
        {
            InvoiceNumber: "3103",
            BookingNumber: "-",
            VendorName: "SEAMATES",
            VendorAmount: "2650",
            PaidAmount: "2650",
            DueAmount: "0",
            PaymentDate: "10 Mar,2023",
            Status: "Not Paid"
        },
        {
            InvoiceNumber: "3103",
            BookingNumber: "-",
            VendorName: "SEAMATES",
            VendorAmount: "2650",
            PaidAmount: "2650",
            DueAmount: "0",
            PaymentDate: "10 Mar,2023",
            Status: "Complete"
        },
        {
            InvoiceNumber: "3103",
            BookingNumber: "-",
            VendorName: "SEAMATES",
            VendorAmount: "2650",
            PaidAmount: "2650",
            DueAmount: "0",
            PaymentDate: "10 Mar,2023",
            Status: "Not Paid"
        },
        {
            InvoiceNumber: "3103",
            BookingNumber: "-",
            VendorName: "SEAMATES",
            VendorAmount: "2650",
            PaidAmount: "2650",
            DueAmount: "0",
            PaymentDate: "10 Mar,2023",
            Status: "Complete"
        },
    ];
    return (
        <div style={{ padding: '15px', border: '1px solid #ccc', borderRadius: '8px', backgroundColor: "#FFFFFF" }}>
            <Row gutter={24}>
                <Col span={10}>
                    <h1 style={{ fontWeight: "bold" }}>Vendors Payment List</h1>
                </Col>
                <Col span={14} style={{ textAlign: "right" }} >
                    <Input
                        placeholder="Search"
                        prefix={<SearchOutlined />}
                        className="w-auto lg:w-2/5"
                    />
                </Col>
            </Row>

            <Modal
                title={<div style={{ width: "100%" }}>Add Transaction</div>}
                open={isModalShow}
                onOk={handleOk}
                onCancel={handleCancel}
                footer={null}
                closable={true}
                width={700}
                bodyStyle={{ padding: "15px" }}
            >
                <Form layout="vertical" requiredMark={false}>
                    <Row gutter={24}>
                        <Col span={8}>
                            <Form.Item
                                label="Invoice Number"
                                name="Invoice Number"
                                rules={[{ required: true, message: "Please enter the number of allowed appointments" }]}
                            >
                                <Input type="number" placeholder="Enter number" />
                            </Form.Item>
                        </Col>
                        
                        <Col span={8}>
                        <Form.Item
                                label="Vendor"
                                name="Vendor"
                                rules={[{ required: true, message: "Please select an appointment type" }]}
                            >
                                <Select placeholder="Select type">
                                    <Select.Option value="Online">Online</Select.Option>
                                    <Select.Option value="Offline">Offline</Select.Option>
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item
                                label="Booking Number"
                                name="Booking_Number"
                                rules={[{ required: true, message: "Please enter the start time" }]}
                            >
                                <Input placeholder="Enter start time" />
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item
                                label="Vendor Amount"
                                name="Vendor_Amount"
                                rules={[{ required: true, message: "Please enter the start time" }]}
                            >
                                <Input placeholder="Enter start time" />
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item
                                label="Amount Due"
                                name="Amount_Due"
                                rules={[{ required: true, message: "Please enter the end time" }]}
                            >
                                <Input placeholder="Enter end time" />
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item
                                label="Pay Amount"
                                name="Pay_Amount"
                                rules={[{ required: true, message: "Please enter the end time" }]}
                            >
                                <Input placeholder="Enter end time" />
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item
                                label="Payment Date"
                                name="Payment_Date"
                                rules={[{ required: true, message: "Please select an appointment type" }]}
                            >
                                <Select placeholder="Select type">
                                    <Select.Option value="Online">Online</Select.Option>
                                    <Select.Option value="Offline">Offline</Select.Option>
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item
                                label="Mode Of Payment"
                                name="Mode_Of_Payment"
                                rules={[{ required: true, message: "Please select an appointment type" }]}
                            >
                                <Select placeholder="Select type">
                                    <Select.Option value="Online">Online</Select.Option>
                                    <Select.Option value="Offline">Offline</Select.Option>
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item
                                label="Payment Status"
                                name="Payment_Status"
                                rules={[{ required: true, message: "Please select an appointment type" }]}
                            >
                                <Select placeholder="Select type">
                                    <Select.Option value="Online">Online</Select.Option>
                                    <Select.Option value="Offline">Offline</Select.Option>
                                </Select>
                            </Form.Item>
                        </Col>
                        {/* </Row> */}
                        <Col span={24}>
                            <Form.Item>
                                <div style={{ textAlign: "center", marginTop: "12px" }}>
                                    <Button
                                        type="primary"
                                        htmlType="submit"
                                        style={{ width: "60%" }}
                                        onClick={handleOk}
                                    >
                                        Update
                                    </Button>
                                </div>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Modal>

            <Table columns={columns} scroll={{ x: "max-content" }} dataSource={data} style={{ marginTop: '10px' }} />
        </div>
    )
}

export default VendorPaymentList
