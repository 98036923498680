import React from 'react';
import { Space, Button, Card, Row, Col, } from 'antd';
import TableBook from './TableBook';
import TableContainer from './TableContainer';
import MissingContainer from './MissingContainer';
import Vechicalyard from './Vechicalyard';
import NoTitleTable from './NoTitleTable';
import InvoiceDue from './InvoiceDue';
import InvoiceChart from './InvoiceChart';
import InvoicesOverdue from './InvoicesOverdue';
import CustomerOverdue from './CustomerOverdue';

export default function Dashboard() {
  return  (
    <div className="dashboard-container ">
      {/* Top Cards Section */}
      <Space direction="horizontal"  wrap className="flex justify-between gap-18 mb-10 responsive-space">
        <Card style={{ minWidth: 300, flex: 1 }} bodyStyle={{ padding: '8px' }}>
          <div className="flex justify-between" style={{ height: '100%' }}>
            <div>
              <p>Total Customers</p>
              <h4 style={{fontWeight:"bold",fontSize:"20px"}}>819</h4>
            </div>
            <Button type="primary">
              <img src="/images/db1.png" alt="icon" />
            </Button>
          </div>
        </Card>
        <Card style={{ minWidth: 300, flex: 1 }} bodyStyle={{ padding: '8px' }}>
          <div className="flex justify-between">
            <div>
              <p>Total Vehicles</p>
              <h4 style={{fontWeight:"bold",fontSize:"20px"}}>12,980</h4>
            </div>
            <Button type="primary">
              <img src="/images/tr2.png" alt="icon" />
            </Button>
          </div>
        </Card>
        <Card style={{ minWidth: 300, flex: 1 }} bodyStyle={{ padding: '8px' }}>
          <div className="flex justify-between">
            <div>
              <p>Total Saved Load Plans</p>
              <h4 style={{fontWeight:"bold",fontSize:"20px"}}>+45</h4>
            </div>
            <Button type="primary">
              <img src="/images/db3.png" alt="icon" />
            </Button>
          </div>
        </Card>
      </Space>

      {/* Tables Section */}
      <Row gutter={[24, 24]}>
        <Col xs={24} lg={12}>
          <div className="table-container">
            <TableBook />
          </div>
        </Col>
        <Col xs={24} lg={12}>
          <div className="table-container">
            <TableContainer />
          </div>
        </Col>
      </Row>

      {/* Components Section */}
      <div className="flex flex-col gap-12 mt-10">
        <MissingContainer />
        <Vechicalyard />
        <NoTitleTable />
      </div>

      {/* Invoice Section */}
      <Row gutter={[24, 24]} className="mt-8">
        <Col xs={24} lg={12} style={{ display: 'flex', flexDirection: 'column' }}>
          <div style={{ flex: 1 }}>
            <InvoiceDue />
          </div>
        </Col>
        <Col xs={24} lg={12} style={{ display: 'flex', flexDirection: 'column' }}>
          <div style={{ flex: 1 }}>
            <InvoiceChart />
          </div>
        </Col>
      </Row>

      {/* Overdue Section */}
      <div className="mt-10">
        <InvoicesOverdue />
      </div>
      <Row gutter={[24, 24]} className="mt-8">
        <Col xs={24} lg={12}>
          <CustomerOverdue />
        </Col>
        <Col xs={24} lg={12}>
          <CustomerOverdue />
        </Col>
      </Row>
    </div>
  );
};
  






