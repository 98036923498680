import React from 'react'
import { Table, Space, Row, Col, Input } from 'antd';
import { SearchOutlined } from '@ant-design/icons'
function InvoicesOverdue() {
  const columns = [
    {
      title: "#",
      dataIndex: '#',
      key: '_id',
      render: (text, record, index) => index + 1,
    },
    {
      title: "User",
      dataIndex: 'User',
      key: 'User',
    },
    {
      title: "Invoice",
      dataIndex: 'Invoice',
      key: 'Invoice',
    },

    {
      title: "Payment Type",
      dataIndex: 'PaymentType',
      key: 'PaymentType',
    },
    {
      title: "Invoice Amount",
      dataIndex: "InvoiceAmount",
      key: "InvoiceAmount",
    },
    {
      title: "Amount Paid",
      dataIndex: "AmountPaid",
      key: "AmountPaid",
    },
    {
      title: "Pullout Date",
      dataIndex: "PulloutDate",
      key: "PulloutDate",
    },
    {
      title: "ETA Date",
      dataIndex: "ETADate",
      key: "ETADate",
    },
    {
      title: "Days",
      dataIndex: "Days",
      key: "Days",
    },
    {
      title: "Days OverDue",
      dataIndex: "DaysOverDue",
      key: "DaysOverDue",
    },
  ];
  const data = [
    {
      key: '1',
      User: 'John Brown',
      Invoice: "5467",
      PaymentType: "ETA",
      InvoiceAmount: "2250",
      AmountPaid: "0",
      PulloutDate: "10 Oct 2024",
      ETADate: "",
      Days: "15",
      DaysOverDue: "-4"
    },
    {
      key: '2',
      User: 'John Brown',
      Invoice: "5467",
      PaymentType: "ETA",
      InvoiceAmount: "2250",
      AmountPaid: "0",
      PulloutDate: "10 Oct 2024",
      ETADate: "",
      Days: "15",
      DaysOverDue: "-4"
    },
    {
      key: '3',
      User: 'John Brown',
      Invoice: "5467",
      PaymentType: "ETA",
      InvoiceAmount: "2250",
      AmountPaid: "0",
      PulloutDate: "10 Oct 2024",
      ETADate: "",
      Days: "15",
      DaysOverDue: "-4"
    },
  ];
  return (
    <div style={{ padding: '20px', border: '1px solid #ccc', borderRadius: '8px' }}>
      <Row gutter={16}>
        <Col span={12}>
          <h1 style={{ fontWeight: "bold" }}>Invoices Overdue</h1>
        </Col>
      </Row>
      <Row gutter={24} style={{ marginTop: '20px', background: "#D9D9D9", padding: "10px", borderRadius: '4px' }} className='flex justify-between'>
        <Col span={10}  >
          <Space direction="horizontal" size={10} >
            <img src="/images/ve.png" alt="" />
            <img src="/images/ve2.png" alt="" />
            <img src="/images/ve3.png" alt="" />
            <img src="/images/ve4.png" alt="" />
            <img src="/images/v35.png" alt="" />
          </Space>
        </Col>
        <Col span={14} style={{ textAlign: "right" }} >
          <Input
            placeholder="Search"
            prefix={<SearchOutlined />}
            style={{ width: 200 }}
          />
        </Col>
      </Row>
      <Row gutter={24}>
      <Col span={24} >
      <Table columns={columns}  scroll={{ x: "max-content" }}  dataSource={data} style={{ marginTop: '20px' }} />
      </Col>
      </Row>
    </div>
  )
}
export default InvoicesOverdue
