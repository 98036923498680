import ajaxService from "./ajax-service";

class CustomerService {

  async fetch(page) {
    const response = await ajaxService.get(`/customers?page=${page}`);
    if (response.success ??false) {
      return response 
    }
    
  }
  async fetchCustomerStatus(id) {
    const response = await ajaxService.get(`/customers/status/${id}`);
    if (response.success) {
      return response; 
    } 
  }

  async deleteCustomer(id) {
    const response = await ajaxService.get(`/customers/delete/${id}`);
    if (response.success) {
      return response; 
    } 
  }
}

const customerService = new CustomerService();
export default customerService;
