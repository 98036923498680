import { Button, Checkbox, Col, DatePicker, Form, Input, Modal, Row, TimePicker } from 'antd'
import React from 'react'

export default function ScheduleForm({visible, onClose, editData, record }) {
   const [form] = Form.useForm();
    React.useEffect(() => {
      if (editData && record) {
        form.setFieldsValue(record);
      } else {
        form.resetFields();
      }
    }, [editData, record, form]);
  
    const handleSubmit = (values) => {
      if (editData) {
        console.log('Editing record:', values);
      } else {
        console.log('Adding new record:', values);
      }
      onClose();
    };
  
  return (
    <div>
        <Modal
        title={<div style={{ width: "100%" }}> {editData ? 'Edit Booking Schedule' : 'Add Booking Schedule'}</div>}
        visible={visible}
        // onOk={handleOk}
        onCancel={onClose}
        footer={null}
        closable={true}
        width={600}
        bodyStyle={{ padding: "15px" }}
      >
        <Form layout="vertical" requiredMark={false}  onFinish={handleSubmit}>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item label="Date" name="date" rules={[{ required: true, message: "Please select a date" }]}>
                <DatePicker style={{ width: "100%" }} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="Start time"
                name="start_time"
                rules={[{ required: true, message: "Please select a start time" }]} >
                <TimePicker style={{ width: "100%" }} />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                label="End time"
                name="end_time"
                rules={[{ required: true, message: "Please select an end time" }]}>
                <TimePicker style={{ width: "100%" }} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="Allowed appointments"
                name="allowed_appointments"
                rules={[{ required: true, message: "Please enter the number of allowed appointments" }]} >
                <Input type="number" placeholder="Enter number" />
              </Form.Item>
            </Col>
          </Row>
          <Form.Item>
            <Checkbox>Appointment Active</Checkbox>
          </Form.Item>
          <Form.Item>
            <div style={{ textAlign: "center" }} >
            <Button type="primary" htmlType="submit" style={{ width: "60%", textAlign: "center" }} >
            {editData ? ' Update Booking Schedule' : 'Add Booking Schedule'}
           
            </Button>
            </div>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  )
}
