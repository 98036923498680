import React, { useState } from 'react'
import { Table, Space, Button, Row, Col, Modal, Form, Checkbox, Input } from 'antd';
import { CiSquarePlus } from "react-icons/ci";
import { EditOutlined, EyeOutlined, DeleteOutlined } from '@ant-design/icons'
function ContainerSize() {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isModalShowOpen, setIsModalShowOpen] = useState(false);
    const [isModalVisible, setIsModalVisible] = useState(false);

    const showModal = () => setIsModalOpen(true);
    const openModal = () => setIsModalShowOpen(true)

    const handleOk = () => {
        setIsModalOpen(false)
        setIsModalShowOpen(false)
    }
    const handleCancel = () => {
        setIsModalOpen(false)
        setIsModalShowOpen(false)
    }

    const columns = [
        {
            title: "Id",
            dataIndex: 'Id',
            key: 'Id',
            render: (text, record, index) => index + 1,
        },
        {
            title: "Country",
            dataIndex: 'Country',
            key: 'Country',
        },
        {
            title: "Name",
            dataIndex: 'Name',
            key: 'Name',
        },
        {
            title: "Status",
            dataIndex: 'Status',
            key: 'Status',
            render: (text, { _id }) => (

                <Button style={{ backgroundColor: "#06D652 ", color: "white" }}>
                    Active
                </Button>
            )
        },
        {
            title: 'Actions',
            key: 'Actions',
            render: (text, { _id }) => (
                <Space size='small'>
                    <Button type='primary' size="small" onClick={openModal}>
                        <EditOutlined />
                    </Button>
                    <Button type='primary' size="small">
                        <EyeOutlined />
                    </Button>
                    <Button type='primary' size="small">
                        <DeleteOutlined />
                    </Button>
                </Space >
            )
        },
    ];
    const data = [
        {
            key: '1',
            Country: 'Libya',
            Name: 'AL KHUMS',

        },
        {
            key: '1',
            Country: 'Libya',
            Name: 'AL KHUMS',

        },
        {
            key: '1',
            Country: 'Libya',
            Name: 'AL KHUMS',

        },

    ];
    return (
        <div style={{ padding: '5px', border: '1px solid #ccc', borderRadius: '8px', backgroundColor: "#FFFFFF" }}>
            <Row
                gutter={30}
                style={{ padding: '5px', borderRadius: '4px' }}
                className="flex items-center"
            >
                <Col flex="auto">
                    <Space direction="horizontal" size={10}>
                        <h5 className="font-bold">Container sizes</h5>
                    </Space>
                </Col>

                <Col flex="none" style={{ display: 'flex', alignItems: 'center', gap: '15px', justifyContent: 'flex-end' }}>
                    {/* Plus Icon */}

                    <CiSquarePlus size={30} style={{ cursor: 'pointer' }} onClick={showModal} />

                </Col>
            </Row>

            {/* ADD container size */}
            <Modal
                title={<div style={{ textAlign: "center", width: "100%" }}>ADD CONTAINER SIZE</div>}
                width={450}
                bodyStyle={{
                    padding: "10px", // Adjust padding for smaller size
                }}
                open={isModalOpen}
                onOk={handleOk}
                onCancel={handleCancel}
                footer={null}
            >
                <Form layout="vertical" requiredMark={false}>
                    <Form.Item label="Size" name="size" rules={[{ required: true, message: "Please enter a size" }]}>
                        <Input placeholder="Enter size" />
                    </Form.Item>
                    <Form.Item>
                        <Checkbox>Payment Collection Type</Checkbox>
                    </Form.Item>
                    <Form.Item>
                        <Button type="primary" htmlType="submit" onClick={handleOk} style={{ width: "100%" }}>
                            Add
                        </Button>
                    </Form.Item>
                </Form>
            </Modal>
            {/* Edit container size */}
            <Modal
                title={<div style={{ textAlign: "center", width: "100%" }}>EDIT CONTAINER SIZE</div>}
                width={450}
                bodyStyle={{
                    padding: "10px", // Adjust padding for smaller size
                }}
                open={isModalShowOpen}
                onOk={handleOk}
                onCancel={handleCancel}
                footer={null}
            >
                <Form layout="vertical" requiredMark={false}>
                    <Form.Item label="Size" name="size" rules={[{ required: true, message: "Please enter a size" }]}>
                        <Input placeholder="Enter size" />
                    </Form.Item>
                    <Form.Item>
                        <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
                            <span>Status</span>
                            <Checkbox />
                        </div>
                    </Form.Item>
                    <Form.Item>
                        <Button type="primary" htmlType="submit" onClick={handleOk} style={{ width: "100%" }}>
                            Add
                        </Button>
                    </Form.Item>
                </Form>
            </Modal>

            <Modal
                title={<div style={{ textAlign: 'center' }}>Confirm</div>}
                visible={isModalVisible}
                onOk={handleOk}
                onCancel={handleCancel}
                centered
                bodyStyle={{
                    padding: "20px",
                }}
                width={300}
                footer={
                    <div className='flex justify-center gap-3'>
                        <Button type="default" onClick={handleCancel} style={{ width: "30%" }}>
                            Cancel
                        </Button>
                        <Button type="primary" onClick={handleOk} style={{ width: "30%" }}>
                            Confirm
                        </Button>
                    </div>
                }
            >
                <p style={{ textAlign: 'center' }}>Are you sure you want to delete this?</p>
            </Modal>
            <Table columns={columns} scroll={{ x: "max-content" }} dataSource={data} style={{ marginTop: '5px' }} />
        </div>
    )
}
export default ContainerSize
