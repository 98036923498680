
import React, { useEffect, useState } from 'react';
import { Button, Col, Form, Input, Row, DatePicker, Checkbox } from 'antd';
import { Select, Typography } from 'antd';
import { useParams } from 'react-router-dom';

const { Option } = Select;
const { Title } = Typography;

const dummyData = [
    { id: '1', name: 'vehicle' },
    { id: '2', name: 'invoice_no' },
    { id: '3', name: 'operating_port' },
    { id: '4', name: 'towing_location' },
    { id: '5', name: 'operating_port' },
    { id: '6', name: 'carrier_name' },
    { id: '7', name: 'year' },
    { id: '8', name: 'invoice_no' },
    { id: '9', name: 'invoice_no' },
    { id: '10', name: 'invoice_no' },
    { id: '12', name: 'invoice_no' },
];

function CustomerForm() {
    const { id } = useParams();  // Get 'id' from the URL
console.log("customerform",id)
    const [form] = Form.useForm();
    const [selectedEntities, setSelectedEntities] = useState([]);

    const handleSelectChange = (value) => {
        setSelectedEntities(value);
    };

    const calculateWidth = (dataLength) => {
        const baseWidth = 400;
        const additionalWidth = dataLength * 20;
        return baseWidth + additionalWidth;
    };

    useEffect(() => {
        if (id) {
            // Fetch the customer data if it's an edit scenario (this could be replaced with an API call)
            setSelectedEntities(dummyData.map((entity) => entity.id)); // Set default selected entities
        }
    }, [id]);

    const isEditMode = id !== undefined;

    return (
        <div style={{ padding: '0 14px', border: '1px solid #ccc', borderRadius: '8px', backgroundColor: '#FFFFFF' }}>
            <Row gutter={26} style={{ backgroundColor: '#FBFBFB', marginBottom: 15, padding: '10px', borderRadius: '4px', borderBottom: '1px solid #E8E8E9' }}>
                <Col span={12}>
                    <h1 style={{ fontWeight: 'bold' }}>{isEditMode ? 'Edit Customer' : 'Add Customer'}</h1>
                </Col>
            </Row>

            <Form
                requiredMark={false}
                form={form}
              layout="vertical"
               
            >
                <Row gutter={24}>
                    <Col span={6}>
                        <Form.Item label="Name">
                            <Input placeholder={isEditMode ? "Customer Name" : "MAHMOOD"} />
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item label="Email">
                            <Input placeholder={isEditMode ? "Customer Email" : "wally724@yahoo.com"} />
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item label="Password" name="password" rules={[{ required: true, message: 'Please input your password!' }]}>
                            <Input.Password placeholder="Enter password" />
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item label="Confirm Password" name="confirmPassword" dependencies={['password']} rules={[{
                            required: true, message: 'Please confirm your password!',
                        }, ({ getFieldValue }) => ({
                            validator(_, value) {
                                if (!value || getFieldValue('password') === value) {
                                    return Promise.resolve();
                                }
                                return Promise.reject(new Error('Passwords do not match!'));
                            }
                        })]}>
                            <Input.Password placeholder="Confirm password" />
                        </Form.Item>
                    </Col>

                    {/* Other fields */}
                    <Col span={6}><Form.Item label="Address"><Input placeholder="Sharjah" /></Form.Item></Col>
                    <Col span={6}><Form.Item label="Phone"><Input placeholder="+12345678900" /></Form.Item></Col>
                    <Col span={6}><Form.Item label="Secondary Email"><Input placeholder="" /></Form.Item></Col>
                    <Col span={6}>
                        <Form.Item label="Country" name="Country" rules={[{ required: true, message: 'Please input!' }]}>
                            <Select placeholder="United Arab Emirates" />
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item label="Contact Start">
                            <DatePicker style={{ width: '100%' }} placeholder="mm/dd/yyyy" />
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item label="Contact End">
                            <DatePicker style={{ width: '100%' }} placeholder="mm/dd/yyyy" />
                        </Form.Item>
                    </Col>

                    <Col span={6}>
                        <Form.Item label="Contract Days">
                            <Input placeholder="0" />
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item label="Contact Charges/day">
                            <Input placeholder="0" />
                        </Form.Item>
                    </Col>

                    <Col span={24} style={{ display: 'flex', justifyContent: 'center' }}>
                        <div style={{ width: calculateWidth(dummyData.length), margin: '30px' }}>
                            <Form.Item>
                                <Title level={5}>Permission</Title>
                                <Select
                                    mode="multiple"
                                    style={{ width: '100%' }}
                                    placeholder="Select entities"
                                    value={selectedEntities}
                                    onChange={handleSelectChange}
                                >
                                    {dummyData.map((entity) => (
                                        <Option key={entity.id} value={entity.id}>{entity.name}</Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </div>
                    </Col>
                </Row>
            </Form>

            {/* Email and Agreement Section */}
            <div style={{ margin: '40px', padding: '0 14px', border: '1px solid #ccc', borderRadius: '8px', backgroundColor: '#FFFFFF', marginTop: "20px", paddingBottom: 20 }}>
                <Row gutter={24}>
                    <Col span={24} style={{ paddingBottom: 10, paddingTop: 10, borderBottom: '1px solid #E8E8E9', backgroundColor: '#FBFBFB' }}>
                        <Row style={{ backgroundColor: '#FBFBFB' }}>
                            <Col span={6}><h3 style={{ fontWeight: 'bold' }}>Email</h3></Col>
                            <Col span={18}><p style={{ fontWeight: 'bold' }}>Emails (use comma to add multiple email IDs)</p></Col>
                        </Row>
                    </Col>

                    {/* Email Rows */}
                    <Col span={24} style={{ paddingBottom: 10, borderBottom: '1px solid #E8E8E9', backgroundColor: '#F2F2F7', paddingTop: 10 }}>
                        <Row>
                            <Col span={6}><h4 style={{ margin: 0 }}>General Emails</h4></Col>
                            <Col span={18}><Input placeholder="Enter General Email" /></Col>
                        </Row>
                    </Col>

                    {/* More Email Fields */}
                    <Col span={24} style={{ paddingBottom: 10, borderBottom: '1px solid #E8E8E9', backgroundColor: '#F4F7FE', paddingTop: 10 }}>
                        <Row>
                            <Col span={6}><h4 style={{ margin: 0 }}>Operation Emails</h4></Col>
                            <Col span={18}><Input placeholder="Enter Operation Email" /></Col>
                        </Row>
                    </Col>

                    <Col span={24} style={{ paddingBottom: 10, borderBottom: '1px solid #E8E8E9', backgroundColor: '#F2F2F7', paddingTop: 10 }}>
                        <Row>
                            <Col span={6}><h4 style={{ margin: 0 }}>Sales Emails</h4></Col>
                            <Col span={18}><Input placeholder="Sales Emails" /></Col>
                        </Row>
                    </Col>

                    <Col span={24} style={{ paddingBottom: 10, borderBottom: '1px solid #E8E8E9', backgroundColor: '#F4F7FE', paddingTop: 10 }}>
                        <Row>
                            <Col span={6}><h4 style={{ margin: 0 }}>Account Emails</h4></Col>
                            <Col span={18}><Input placeholder="Enter Account Email" /></Col>
                        </Row>
                    </Col>

                    {/* Checkbox Section */}
                    <Col span={24} style={{ marginBottom: '20px' }}>
                        <Row>
                            <Col span={24} className='flex flex-row justify-around mt-5'>
                                <Checkbox style={{ marginRight: '20px' }}>Send Email</Checkbox>
                                <Checkbox style={{ marginRight: '20px' }}>Invoice</Checkbox>
                                <Checkbox>Agreement</Checkbox>
                            </Col>
                        </Row>
                    </Col>

                    {/* Add Customer Button */}
                    <Col span={24} style={{ textAlign: 'center' }}>
                        <Button type="primary" style={{ width: '50%' }}>{isEditMode ? 'Update Customer' : 'Add Customer'}</Button>
                    </Col>
                </Row>
            </div>
        </div>
    );
}

export default CustomerForm;




// add customer Dashboard
// const [form] = Form.useForm();
// const [formLayout, setFormLayout] = useState('vertical');
// const onFormLayoutChange = ({ layout }) => {
//   setFormLayout(layout);  }
// return (

// <div style={{ padding: '15px', border: '1px solid #ccc', borderRadius: '8px' ,backgroundColor:"#FFFFFF"}}>
// <Form
// requiredMark={false}
//   layout={formLayout}
//   form={form}
//   initialValues={{
//     layout: formLayout,
//   }}
//   onValuesChange={onFormLayoutChange}
// >
// <Row gutter={24} >
// <Col span={8}>
//   <Form.Item label="Invoice Number">
//     <Input placeholder="5536" />
//   </Form.Item>
// </Col >
// <Col span={8}>
//   <Form.Item label="Invoice Amount">
//     <Input placeholder="150" />
//   </Form.Item>
// </Col >

// <Col span={8}>
//   <Form.Item label="Ref. Number">
//     <Input placeholder="input placeholder" />
//   </Form.Item>
// </Col >
// <Col span={8}>
//   <Form.Item label="Payment Date">
//     <Input placeholder="28-Oct-2024" />
//   </Form.Item>
// </Col >
// <Col span={8}>
//   <Form.Item label="Transaction Amount">
//     <Input placeholder="0" />
//   </Form.Item>
// </Col >
// <Col span={8}>
//   <Form.Item label="Balance">
//     <Input placeholder="0" />
//   </Form.Item>
// </Col >

// <Col span={8}>
// <Form.Item
//     label="Mode Of Payment"
//     name="Mode Of Payment"
//     rules={[
//         {
//             required: true,
//             message: 'Please input!',
//         },
//     ]}
//     >
//     <Select placeholder="Bank Transfer"/>
//   </Form.Item>
//       </Col>
//       <Col span={8}>
// <Form.Item
//     label="Bank"
//     name="Bank"
//     rules={[
//         {
//             required: true,
//             message: 'Please input!',
//         },
//     ]}
//     >
//     <Select placeholder="" />
//   </Form.Item>
//       </Col>
      
//       <Col span={8}>
// <Form.Item
//     label="Payment Status"
//     name="Payment Status"
//     rules={[
//         {
//             required: true,
//             message: 'Please input!',
//         },
//     ]}
//     >
//     <Select placeholder="Not paid" />
//   </Form.Item>
//       </Col>
//       <Col span={24}>
//       <Form.Item style={{ textAlign: 'center',marginTop:30 }}>
//         <Button type="primary" style={{ width: '50%' }}>Update</Button>
//       </Form.Item>
//     </Col>
// </Row>
// </Form>
// </div>
