import React from 'react'
import { Table, Space, Button, Row, Col, } from 'antd';
import { EditOutlined, FilePdfOutlined, FileAddOutlined, EyeOutlined, DeleteOutlined } from '@ant-design/icons'
import { Link } from 'react-router-dom';
function SaveContainer() {
    const columns = [
        {
            title: "#",
            dataIndex: '#',
            key: '_id',
            render: (text, record, index) => index + 1,
        },
        {
            title: "Container ID",
            dataIndex: 'ContainerID',
            key: 'ContainerID',
        },
        {
            title: "Container Number",
            dataIndex: 'ContainerNumber',
            key: 'ContainerNumber',
        },
        {
            title: "Location",
            dataIndex: 'Location',
            key: 'Location',
        },


        {
            title: 'Actions',
            key: 'Actions',
            render: (text, record) => (
                <Space direction="horizontal" size="small">
                <Space size='small'>
                    <Link to={"/loadings/save-containers/edits"}>
                    <Button type='primary' size="small">
                        <EditOutlined />
                    </Button>
                    </Link>
                    <Link to={"/loadings/save-containers/views"}>
                    <Button type='primary' size="small">
                        <EyeOutlined />
                    </Button>
                    </Link>

                    <Button type='primary' size="small">
                        <DeleteOutlined />
                    </Button>
                    <Link to={"/loadings/generates-plans"}>
                    <Button type='primary' size="small">
                        <FilePdfOutlined />
                    </Button>
                    </Link>
                    <Button type='primary' size="small">
                        <FileAddOutlined />
                    </Button>
                </Space >



{record.isDockReceiptGenerated && (
    <span style={{ color: "red", fontWeight: "bold",border:"1px solid #ccc", padding:5 }}>
       "Dock Receipt Generated"
    </span>
)}
    </Space >
            )
        },
        
    ];
    const data = [
        {
            key: '1',
            ContainerID: 'HOUSTON 755-102',
            ContainerNumber: 'TCNU5226866',
            Location: 'HOUSTON 755',
            isDockReceiptGenerated: true, 
        },
        {
            key: '1',
            ContainerID: 'HOUSTON 755-102',
            ContainerNumber: 'TCNU5226866',
            Location: 'HOUSTON 755',
            isDockReceiptGenerated: false, 
        },
        {
            key: '1',
            ContainerID: 'HOUSTON 755-102',
            ContainerNumber: 'TCNU5226866',
            Location: 'HOUSTON 755',
            isDockReceiptGenerated: false, 
        },
        {
            key: '1',
            ContainerID: 'HOUSTON 755-102',
            ContainerNumber: 'TCNU5226866',
            Location: 'HOUSTON 755',
            isDockReceiptGenerated: true, 
        },
        {
            key: '1',
            ContainerID: 'HOUSTON 755-102',
            ContainerNumber: 'TCNU5226866',
            Location: 'HOUSTON 755',
            isDockReceiptGenerated: false, 
        },
        {
            key: '1',
            ContainerID: 'HOUSTON 755-102',
            ContainerNumber: 'TCNU5226866',
            Location: 'HOUSTON 755',
            isDockReceiptGenerated: true, 
        },
        {
            key: '1',
            ContainerID: 'HOUSTON 755-102',
            ContainerNumber: 'TCNU5226866',
            Location: 'HOUSTON 755',
            isDockReceiptGenerated: false, 
        },
    ];
    return (
        <div style={{ padding: '15px', border: '1px solid #ccc', borderRadius: '8px', backgroundColor: "#FFFFFF" }}>
            <Row gutter={16}  >
                <Col span={10}  >
                    <h5 className='font-bold'> Saved Container</h5>
                </Col>
               
            </Row>
            <Table columns={columns} scroll={{ x: "max-content" }}dataSource={data} style={{ marginTop: '10px' }} />
        </div>
    )
}
export default SaveContainer
