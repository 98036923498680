import React from 'react'
import { Space, Button, Row, Col } from 'antd';
import { EyeOutlined, EditOutlined } from '@ant-design/icons'

const fieldData = [
  { label: 'Customer', value: '' },
  { label: 'Title Number', value: '' },
  { label: 'Car Status', value: '' },
  { label: 'Title State', value: '' },
  { label: 'Lot #', value: '' },
  { label: 'Keys', value: 'Enter country name' },
  { label: 'Invoice No', value: 'Enter country name' },
  { label: 'Notes', value: 'Enter country name' },
  { label: 'Invoice Amount', value: '' },
  { label: 'Container No', value: '' },
  { label: 'Cutting Car', value: '' },
  { label: 'Tracking No', value: '' },
  { label: 'Product', value: '' },
  { label: 'Arrival Date', value: '' },
  { label: 'Vehicle', value: '' },
  { label: 'Release Status', value: '' },
  { label: 'Vehicle Type', value: '' },
  { label: 'Destination', value: '' },
  { label: 'Engine Type', value: '' },
  { label: 'Container Size', value: '' },
  { label: 'Year', value: '' },
  { label: 'File Ref', value: '' },
  { label: 'Chassis No', value: '' },
  { label: 'Shipping Class Id', value: '' },
  { label: 'Date Rcd', value: '' },
  { label: 'Price', value: '' },
  { label: 'Value', value: '' },
  { label: 'Towing Charges', value: '' },
  { label: 'Weight', value: '' },
  { label: 'Freight Charges', value: '' },
  { label: 'Operating Port', value: '' },
  { label: 'Buying Charges', value: '' },
  { label: 'Towing Location', value: '' },
  { label: 'Selling Charges', value: '' },
  { label: 'Booking No', value: '' },
  { label: 'Yard Charges', value: '' },
  { label: 'Carrier Name', value: '' },
  { label: 'Loading Charges', value: '' },
  { label: 'Storage', value: '' },
  { label: 'Transportation', value: '' },
  { label: 'Bol', value: '' },
  { label: 'Labour', value: '' },
  { label: 'BOS', value: '' },
  { label: 'Material', value: '' },
  { label: 'Title Owner', value: '' },
  { label: 'Chasis', value: '' },
  { label: 'Title', value: '' },
  { label: 'Quotation Id', value: '' },

];
function ViewVehical() {

  return (
    <>
     <Row gutter={[24, 24]} wrap>
     <Col xs={24} sm={24} md={8} lg={6} xl={6} flex="280px">
          <div style={{ padding: 0, border: "1px solid #ccc", padding: 10, borderRadius: 5, backgroundColor: "#FFFFFF" }}>

            <div style={{ textAlign: 'center' }}>
              <img
                src="/images/image 8.png"
                alt="Profile"
                style={{
                  width: '320px',
                  height: '200px',
                  borderRadius: '8px',
                  objectFit: 'cover',
                }}
              />
              <Button
                type="primary"
                style={{ marginTop: '10px' }}
              >
                Download Image
              </Button>
            </div>
            <div style={{
              marginTop: '20px',
              border: '1px solid #f0f0f0',
              borderRadius: '8px',
              borderBottom: '1px solid #f0f0f0',
              backgroundColor: "#FFFFFF"
            }}>

              <div
                style={{
                  backgroundColor: '#F04949',
                  padding: '15px',
                  borderTopLeftRadius: '8px',
                  borderTopRightRadius: '8px',

                }}
              >
                <h3 style={{ margin: 0, color: 'white', textAlign: "center" }}>Customer Details</h3>
              </div>

              <div
                style={{
                  padding: '20px',
                }}
              >
                <div
                  style={{
                    alignItems: 'center',
                    marginBottom: '15px',
                  }}
                  className='flex flex-col gap-4'
                >
                  <p style={{ margin: 0 }}>wally724@yahoo.com</p>
                  <Space>
                    <EyeOutlined style={{ fontSize: '16px', cursor: 'pointer' }} />
                    <EditOutlined style={{ fontSize: '16px', cursor: 'pointer' }} />
                  </Space>
                </div>
                <div
                  className='profile-info'
                >
                  <p >
                    <strong>Phone:</strong> 12345678900
                  </p>
                  <p >
                    <strong>Town:</strong> Sharjah
                  </p>
                  <p >
                    <strong>City:</strong> Sharjah
                  </p>
                  <p >
                    <strong>Country:</strong> United Arab Emirates
                  </p>
                </div>
              </div>

            </div>
          </div>
        </Col>

        <Col flex="1">
          <div style={{ padding: '15px', border: '1px solid #ccc', borderRadius: '8px', backgroundColor: "#FFFFFF" }}>
          <Row gutter={24}>

        {fieldData.map((field, index) => (
          <Col span={12}>
          <div style={{
            display: 'flex', width: 320, border: '1px solid #d9d9d9', borderRadius: 4, marginBottom: 2
          }}>
            <div style={{
              flex: 1, padding: '4px 11px', backgroundColor: '#f5f5f5', borderRight: '1px solid #d9d9d9'
            }}>
              {field.label}
            </div>
            <p style={{
              flex: 1, margin: 0, padding: '4px 11px', display: 'flex', alignItems: 'center'
            }}>
              {field.value}
            </p>
          </div>
        </Col>
        ))}
      </Row>
          </div>
        </Col>

      </Row>
      
      <style>
        {`
  .profile-info {
    display: flex;
    flex-direction: column; /* Stack items vertically */
    gap: 5px; /* Space between each <p> element */
  }

  .profile-info p {
    display: flex; /* Makes each <p> a flex container */
  
  }

  .profile-info strong {
   margin-right: 10px; /* Space between <strong> and <p> content */
    width: 100px; /* You can adjust this value to ensure equal space between all labels */
  }

  
`}
      </style>
    </>
  )
}

export default ViewVehical
