import React, { useState } from 'react'
import { Button,  Col, Form, Input, Row, Select, Table } from 'antd';
import { CiSquarePlus } from "react-icons/ci";
import { Option } from 'antd/es/mentions';
import { DeleteOutlined } from '@ant-design/icons'

export default function AddCoastngExpenses() {
    const [showAllRows, setShowAllRows] = useState(false);
    const [showAllRows2, setShowAllRows2] = useState(false);

    const [form] = Form.useForm();
    const columns = [
        {
            title: "#",
            dataIndex: '#',
            key: '#',
            render: (text, record, index) => index + 1,
        },
        {
            title: "Vendor",
            dataIndex: 'Vendor',
            key: 'Vendor',
            render: (text, record) => (
                <Select
                    defaultValue={text}
                    style={{ width: '100%' }}
                >
                    <Option value="User1">User1</Option>
                    <Option value="User2">User2</Option>
                    <Option value="User3">User3</Option>
                </Select>
            ),
        },
        {
            title: "Amount",
            dataIndex: "Amount",
            key: "Amount",
            render: (text) => (
                <Input
                    defaultValue={text}
                    style={{ width: "100%" }}
                />
            ),
        },
        {
            title: "Description",
            dataIndex: "Description",
            key: "Description",
            render: (text) => (
                <Input
                    defaultValue={text}
                    style={{ width: "100%" }}
                />
            ),
        },
        {
            title:(<div>
            <DeleteOutlined style={{fontSize:"18px"}}/>
        </div>),
            key: 'Delete',
            render: (text, { _id }) => (
                <Button type='primary' size="small">
                    <DeleteOutlined />
                </Button>
            )
        },
    ]
    const columns2 = [
        {
            title: "#",
            dataIndex: '#',
            key: '#',
            render: (text, record, index) => index + 1,
        },
        {
            title: "Vendor",
            dataIndex: 'Vendor',
            key: 'Vendor',
            render: (text, record) => (
                <Select
                    defaultValue={text}
                    style={{ width: '100%' }}
                >
                    <Option value="User1">User1</Option>
                    <Option value="User2">User2</Option>
                    <Option value="User3">User3</Option>
                </Select>
            ),
        },
        {
            title: "Amount",
            dataIndex: "Amount",
            key: "Amount",
            render: (text) => (
                <Input
                    defaultValue={text}
                    style={{ width: "100%" }}
                />
            ),
        },
        {
            title: "Description",
            dataIndex: "Description",
            key: "Description",
            render: (text) => (
                <Input
                    defaultValue={text}
                    style={{ width: "100%" }}
                />
            ),
        },
        {
            title:(<div>
            <DeleteOutlined style={{fontSize:"18px"}}/>
        </div>),
            key: 'Delete',
            render: (text, { _id }) => (
                <Button type='primary' size="small">
                    <DeleteOutlined />
                </Button>
            )
        },
    ]
    const data = [
        {
            key: '13574',
            ShippingLine: '',

            Destination: '',
            ContainerSize: '',
            Vehicle: '',
            FrightCharges: '',
            NoOfVehicles: '',
            LoadingCharges: "",

        },
        {
            key: '13574',
            ShippingLine: '',

            Destination: '',
            ContainerSize: '',
            Vehicle: '',
            FrightCharges: '',
            NoOfVehicles: '',
            LoadingCharges: "",

        },
        {
            key: '13574',
            ShippingLine: '',

            Destination: '',
            ContainerSize: '',
            Vehicle: '',
            FrightCharges: '',
            NoOfVehicles: '',
            LoadingCharges: "",

        },
    ]
    const data2 = [
        {
            key: '13574',
            ShippingLine: '',

            Destination: '',
            ContainerSize: '',
            Vehicle: '',
            FrightCharges: '',
            NoOfVehicles: '',
            LoadingCharges: "",

        },
        {
            key: '13574',
            ShippingLine: '',

            Destination: '',
            ContainerSize: '',
            Vehicle: '',
            FrightCharges: '',
            NoOfVehicles: '',
            LoadingCharges: "",

        },
        {
            key: '13574',
            ShippingLine: '',

            Destination: '',
            ContainerSize: '',
            Vehicle: '',
            FrightCharges: '',
            NoOfVehicles: '',
            LoadingCharges: "",

        },
    ]

    const handlePlusClick = () => {
        setShowAllRows(true);
    };
    const handlePlusClick2 = () => {
        setShowAllRows2(true);
    };
    const displayedData = showAllRows ? data : data.slice(0, 2);
    const displayedData2 = showAllRows2 ? data2 : data2.slice(0, 2);

    return (
        <div style={{ padding: '15px', border: '1px solid #ccc', borderRadius: '8px', backgroundColor: "#FFFFFF" }}>
            <Form
                requiredMark={false}
                form={form}
                layout="vertical"
                initialValues={{ layout: "vertical" }}
            >
                <Row gutter={24} >
                    <Col span={24}>
                        <h1 style={{ fontWeight: 'bold', }} className='pt-2  pb-5'>Add Expenses</h1>
                    </Col>  
                    <Col span={8}>
                        <Form.Item label="Invoice Number">
                            <Input placeholder="" />
                        </Form.Item>
                    </Col >
                   
                    <Col span={8}>
                        <Form.Item label="Booking Number">
                            <Input placeholder="" />
                        </Form.Item>
                    </Col >
                    <Col span={8}>
                        <Form.Item label="Container Number">
                            <Input placeholder="" />
                        </Form.Item>
                    </Col >
                    <Col span={8}>
                        <Form.Item label="Transport Charges">
                            <Input placeholder="" />
                        </Form.Item>
                    </Col >
                    <Col span={8}>
                        <Form.Item label="Material Charges">
                            <Input placeholder="" />
                        </Form.Item>
                    </Col >
                    <Col span={8}>
                        <Form.Item
                            label="Labour Charges"
                            name="Labour Charges"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please input!',
                                },
                            ]}
                        >
                            <Select placeholder="OTHER" />
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item label="Extra /Buying Charges">
                            <Input placeholder="" />
                        </Form.Item>
                    </Col >
                    <Col span={8}>
                        <Form.Item label="Selling Charges">
                            <Input placeholder="" />
                        </Form.Item>
                    </Col >
                    <Col span={8}>
                        <Form.Item label="Freight Booking Charges">
                            <Input placeholder="" />
                        </Form.Item>
                    </Col >
                </Row>
              
                <Row gutter={24} >
                <Col span={12}>
                <div style={{ padding: '10px', border: '1px solid #ccc', borderRadius: '8px', backgroundColor: "#FFFFFF",  marginTop: 20 }}>
                    <h1 style={{fontWeight:"bold"}}>ADD FRIGHT FORWARD DETAILS</h1>
                    <Table columns={columns} scroll={{ x: "max-content" }} dataSource={displayedData} pagination={false} rowKey="Id" style={{ marginTop: '10px' }} />
                    <div style={{ marginTop: 20 }} className='flex flex-col justify-center items-center'>
                        <CiSquarePlus style={{ cursor: 'pointer', fontSize: '25px' }} onClick={handlePlusClick} />
                    </div>
                </div>
                </Col >
                <Col span={12}>
                <div style={{ padding: '10px', border: '1px solid #ccc', borderRadius: '8px', backgroundColor: "#FFFFFF", marginTop: 20 }}>
                <h1 style={{fontWeight:"bold"}}>ADD TRANSPORTATION DETAILS</h1>
                    <Table columns={columns2} scroll={{ x: "max-content" }} dataSource={displayedData2} pagination={false} rowKey="Id" style={{ marginTop: '10px' }} />
                    <div style={{ marginTop: 20 }} className='flex flex-col justify-center items-center'>
                        <CiSquarePlus style={{ cursor: 'pointer', fontSize: '25px' }} onClick={handlePlusClick2} />
                    </div>
                </div>
                </Col >
                </Row>
            </Form>
        </div>
    )
}
