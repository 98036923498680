
import { Table, Space, Button, } from 'antd';
import { EyeOutlined, EditOutlined, DeleteOutlined, SendOutlined } from '@ant-design/icons'

import React from 'react'

function BlForm() {
  const options = [
    {
      value: '10',
      label: '10',
    },
    {
      value: '9',
      label: '9',
    },
  ]
  const columns = [
    {
      title: "#",
      dataIndex: '#',
      key: '#   ',
      render: (text, record, index) => index + 1,
    },
    {
      title: "Booking No",
      dataIndex: 'BookingNo',
      key: 'BookingNo',
    },
    {
      title: "Container No",
      dataIndex: 'ContainerNo',
      key: 'ContainerNo',
    },

    {
      title: 'Action',
      key: 'Action',
      render: (text, { _id }) => (
        <Space size='small'>
          <Button type='primary' size="small">
            <EditOutlined />
          </Button>
          <Button type='primary' size="small">
            <EyeOutlined />
          </Button>
          <Button type='primary' size="small">
            <DeleteOutlined />
          </Button>
          <Button type='primary' size="small">
            <SendOutlined />
          </Button>
        </Space >
      )
    },
  ];
  const data = [
    {
      key: '135',
      BookingNo: "230197307",
      ContainerNo: "MSKU1140851",

    },
    {
      key: '135',
      BookingNo: "230197307",
      ContainerNo: "MSKU1140851",

    },
    {
      key: '135',
      BookingNo: "230197307",
      ContainerNo: "MSKU1140851",

    },
    {
      key: '135',
      BookingNo: "230197307",
      ContainerNo: "MSKU1140851",

    },
    {
      key: '135',
      BookingNo: "230197307",
      ContainerNo: "MSKU1140851",

    },
    {
      key: '135',
      BookingNo: "230197307",
      ContainerNo: "MSKU1140851",

    },
    {
      key: '135',
      BookingNo: "230197307",
      ContainerNo: "MSKU1140851",

    },
  ];
  return (
    <div style={{ padding: '15px', border: '1px solid #ccc', borderRadius: '8px', backgroundColor: "#FFFFFF" }}>

      <Table columns={columns} dataSource={data} />
    </div>
  )
}

export default BlForm

