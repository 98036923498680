import React from "react";
import "./DockReceipt.css";

export const DockReceipt = () => {
  return (
    <div className="container">
      <h4 className="titleText">DOCK RECEIPT</h4>
      <div className="containerInner">
        <div className="containerLeft">
          <div className="cellOne">
            <h4>
              2. Exporter (Principal or seller-licensee and address including
              ZIP Code)
            </h4>
            <h5>
              US TRANS LLC / CTRADE USA INC
              <br />
              7815 SNOWDEN ST., HOUSTON TX-77028 EIN: 84-4109779
              <br />
              EMAIL: cservice@ctrade.usPH:
            </h5>
          </div>

          <div className="cellOne">
            <h4>3. Consigned To:</h4>
            <h5>
              US TRANS LLC
              <br />
              SHALVA NUTSUBIDZE STREET, 82, TBILISI. GEORGIA
              <br />
              TAX ID 454412387 PH: 0322222802, +19173419352
              <br />
              EMAIL: gltransllc@gmail.com
            </h5>
          </div>

          <div className="cellOne">
            <h4>
              4. Notify Party / Intermediate Consignee (Name and Address):
              <br />
              Tel:SAME AS CONSIGNEE
            </h4>
          </div>

          <div className="containerTwo">
            <div className="cellTwo">
              <h4>12. Pre-Carriage By</h4>
            </div>
            <div className="cellTwo">
              <h4>13. Place of Receipt by Pre-Carrier</h4>
            </div>
            <div className="cellTwo">
              <h4>14. Exporting Carrier</h4>
              <h5>SEASPAN SAGON / 20E44</h5>
            </div>
            <div className="cellTwo">
              <h4>15. Port of Loading/Export</h4>
              <h5>HOUSTON, TX.</h5>
            </div>
            <div className="cellTwo">
              <h4>16. Foreign Port of Unloading (vessel and air only)</h4>
              <h5>46337 - POTI, GEORGIA</h5>
            </div>
            <div className="cellTwo">
              <h4>17. Place of Delivery by On-Carrier</h4>
            </div>
          </div>
        </div>
        {/* ///////////////////////// */}
        <div className="containerRight">
          <div className="containerTwo">
            <div className="cellTwo">
              <h4>5. Document No. (Carrier Booking No.)</h4>
              <h5>69314970</h5>
            </div>
            <div className="cellTwo">
              <h4>5a. Bill of Loading No</h4>
            </div>
            <div className="borderSetting">
              <div className="cellTwo">
                <h4>6. Export References</h4>
              </div>
            </div>
            <div className="cellTwo">
              <h4>6a. Reference No.</h4>
            </div>
          </div>
          <div className="borderSetting">
            <div className="cellOne">
              <h4>7. Forwarding Agent (Name and Address)</h4>
              <h5>
                GLOBEX SHIPPING USA.
                <br />
                4750 E MOODY BLVD SUIT-214,, BUNNELL, FL 32110
              </h5>
            </div>
          </div>
          <div className="borderSettingTwo">
            <div className="cellOne">
              <h4>8. Point (State) or Origin or FTZ Number</h4>
            </div>
          </div>
          <div className="borderSettingTwo">
            <div className="cellOne">
              <h4>9. Also Notify and/or Additional Routing Instructions</h4>
              <h5>ETD:11/09/2024</h5>
            </div>
          </div>
          <div style={{ height: "13.1vh", display: "inline-block" }}>
            <div className="cellOne">
              <h4></h4>
            </div>
          </div>
          <div className="containerTwo">
            <div className="cellTwo">
              <h4>10. Loading Pier / Termina</h4>
              <h5>BARBOURS CUT</h5>
            </div>
            <div className="cellTwo">
              <h4>10a. Closing Date</h4>
              <h5>2024-11-04</h5>
            </div>
            <div className="borderSetting">
              <div className="cellTwo">
                <h4>11. Type of Move</h4>
              </div>
            </div>
            <div className="cellTwo">
              <h4>11a. Containerized(Vessel only)</h4>
              <h5>YES</h5>
            </div>
          </div>
        </div>
      </div>
      {/* ///////////////////////// */}
      <div className="containerBottom">
        <div className="blockOne">
          <h4>Marks and Numbers(18).</h4>
          <div className="seperator"></div>
          <h5>CONTAINER NO. :</h5>
          <h5>SEAL :</h5>
        </div>
        <div>
          <div className="containerBlock">
            <div className="blockOne">
              <h4>Marks and Numbers(18).</h4>
              <div className="seperator"></div>
              <h5>CONTAINER NO. :</h5>
              <h5>SEAL :</h5>
            </div>
            <div className="blockTwo">
              <h4>Marks and Numbers(18).</h4>
              <div className="seperator"></div>
              <h5>CONTAINER NO. :</h5>
              <h5>SEAL :</h5>
            </div>
            <div className="blockThree">
              <h4>Marks and Numbers(18).</h4>
              <div className="seperator"></div>
              <h5>CONTAINER NO. :</h5>
              <h5>SEAL :</h5>
            </div>
            <div className="blockThree">
              <h4>Marks and Numbers(18).</h4>
              <div className="seperator"></div>
              <h5>CONTAINER NO. :</h5>
              <h5>SEAL :</h5>
            </div>
          </div>
          <div className="containerBottomTwo">
            <div className="blockFour">
              <h5>
                This is to certify that all the vehicles inside this container
                have been drained of fuel and run until stalled.
                <br />
                Batteries have been disconnected and cables taped back to
                prevent movement & electrical short.
              </h5>
            </div>
          </div>
        </div>
        <div className="blockFive"></div>
      </div>
    </div>
  );
};

export default DockReceipt;