import React, { useState } from 'react';
import { Layout, theme, Typography } from 'antd';
import Sidebar from './sidebar';
import MainHeader from './header';
import { Breadcrumb } from 'antd';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import "../../src/pages/vechicals/breadcrumb.css";
import ExportModal from '../pages/vechicals/ExportModal';
import AddTariff from '../pages/towingtarf/ModalForm';
import AddSchedule from '../pages/schedule/ScheduleForm';
import ModalForm from '../pages/towingtarf/ModalForm';
import ScheduleForm from '../pages/schedule/ScheduleForm';

const { Content } = Layout;
const { Title } = Typography;

export default function MainLayout({ children, title }) {
    const navigate = useNavigate()

  const { token: { borderRadiusLG } } = theme.useToken();
  const [isExportModalVisible, setIsExportModalVisible] = useState(false);
  const [isAddModalVisible, setIsAddModalVisible] = useState(false);
  const [isAddModalSchedule, setIsAddModalSchedule] = useState(false);
  const [editData, setEditData] = useState(null);
  
  
  const location = useLocation();

  const showExportModal = () => {
    setIsExportModalVisible(true);
  };
  const showAddModal = () => {
    setIsAddModalVisible(true);
      setEditData(null); // Reset editData for add mode
  };
  const showAddschedule=()=>{
    setIsAddModalSchedule(true)
  }

const handleScheduleCancel=()=>{
  setIsAddModalSchedule(false)
}
const handleScheduleConfirm = () => {
  setIsAddModalSchedule(false); // Close the modal
};

  const handleExportCancel = () => {
    setIsExportModalVisible(false);
  };

  const handleExportConfirm = () => {
    setIsExportModalVisible(false); // Close the modal
  };

  const handleAddCancel = () => {
    setIsAddModalVisible(false);
  };

  const breadcrumbMap = {
    "/vehicles": [
      { title: "Browse", path: "/vehicles" },
      { title: "Add", path: "/vehicles/adds" },
      { title: "Export", onClick: () => showExportModal() },
    ],
    "/vehicles/adds": [
      { title: "Browse", path: "/vehicles" },
      { title: "Add", path: "/vehicles/adds" },
      { title: "Export", onClick: () => showExportModal() },
    ],
    "/tariffs": [
      { title: "Browse", path: "/tariffs" },
      { title: "Add", onClick: () => showAddModal() },
    ],
    "/vehicles/views": [
      { title: "Browse", path: "/vehicles" },
      { title: "Add", path: "/vehicles/adds" },
      { title: "Export", path: "/vehicles/adds" },
    ],
    "/customers": [
      { title: "Browse", path: "/customers" },
      { title: "Add",   onClick:() => navigate("/customers/form")},
    ],

    "/customers/form": [
      { title: "Browse", path: "/customers" },
      { title: "Add",   onClick:() => navigate("/customers/form") ,  path: "/customers/form"},
    ],

    "/employees": [
      { title: "Browse", path: "/employees" },
      { title: "Add",  onClick:() => navigate("/employees/form"),path: "/employees/form"  },
    ],
    "/employees/form": [
      { title: "Browse", path: "/employees" },
      { title: "Add", onClick:() => navigate("/employees/form"),path: "/employees/form" },
    ],
    "/loadings": [
      { title: "Load Plans", path: "/loadings" },
      { title: "Saved Plans", path: "/loadings/save-containers" },
      { title: "Add", path: "/loadings/adds" },
      { title: "Clients Request", path: "/loadings/clients" },
      { title: "IMO’s", path: "/loadings/imos" },
    ],
    "/loadings/imos": [
      { title: "Load Plans", path: "/loadings" },
      { title: "Saved Plans", path: "/loadings/save-containers" },
      { title: "Add", path: "/loadings/adds" },
      { title: "Clients Request", path: "/loadings/clients" },
      { title: "IMO’s", path: "/loadings/imos" },
    ],
    
    "/loadings/save-containers":[
      { title: "Load Plans", path: "/loadings" },
      { title: "Saved Plans", path: "/loadings/save-containers" },
      { title: "Add", path: "/loadings/adds" },
      { title: "Clients Request", path: "/loadings/clients" },
      { title: "IMO’s", path: "/loadings/imos" },
    ],
    "/loadings/adds": [
      { title: "Load Plans", path: "/loadings" },
      { title: "Saved Plans", path: "/loadings/save-containers" },
      { title: "Add", path: "/loadings/adds" },
      { title: "Clients Request", path: "/loadings/clients" },
      { title: "IMO’s", path: "/loadings/imos" },
    ],
    "/loadings/clients": [
      { title: "Load Plans", path: "/loadings" },
      { title: "Saved Plans", path: "/loadings/save-containers" },
      { title: "Add", path: "/loadings/adds" },
      { title: "Clients Request", path: "/loadings/clients" },
      { title: "IMO’s", path: "/loadings/imos" },
    ],
  
    "/vendors": [
      { title: "Browse Vendors", path: "/vendors" },
      { title: "Add Vendors" , onClick:() =>navigate("/vendors/form"),path: "/vendors/form" },
      { title: "Browse Vendor Quotation", path: "/vendors/quotations" },
      { title: "Add Vendor Quotation ",onClick:() =>navigate("/vendors/quotations/form"), path: "/vendors/quotations/form" },
    ],
    "/vendors/form": [
      { title: "Browse Vendors", path: "/vendors" },
      { title: "Add Vendors", onClick:() =>navigate("/vendors/form"),path: "/vendors/form" },
      { title: "Browse Vendor Quotation", path: "/vendors/quotations" },
      { title: "Add Vendor Quotation ",onClick:() =>navigate("/vendors/quotations/form"), path: "/vendors/quotations/form" },
    ],
    "/vendors/quotations": [
      { title: "Browse Vendors", path: "/vendors" },
      { title: "Add Vendors",onClick:() =>navigate("/vendors/form"),path: "/vendors/form"  },
      { title: "Browse Vendor Quotation", path: "/vendors/quotations" },
      { title: "Add Vendor Quotation ",onClick:() =>navigate("/vendors/quotations/form"), path: "/vendors/quotations/form" },
    ],
    "/vendors/quotations/adds": [
      { title: "Browse Vendors", path: "/vendors" },
      { title: "Add Vendors", onClick:() =>navigate("/vendors/form"),path: "/vendors/form"  },
      { title: "Browse Vendor Quotation", path: "/vendors/quotations" },
      { title: "Add Vendor Quotation ", onClick:() =>navigate("/vendors/quotations/form"), path: "/vendors/quotations/form" },
    ],
    "/settings": [
      { title: "Destinations", path: "/settings" },
      { title: "Containers size", path: "/containers" },
      { title: "Logs", path: "/logs" },
    ],
    "/containers": [
      { title: "Destinations", path: "/settings" },
      { title: "Containers size", path: "/containers" },
      { title: "Logs", path: "/logs" },
    ],
    "/logs": [
      { title: "Destinations", path: "/settings" },
      { title: "Containers size", path: "/containers" },
      { title: "Logs", path: "/logs" },
    ],
    "/books": [
      { title: "Browse", path: "/books" },
      { title: "Add", onClick: () => showAddschedule()  },
    ],

    "/quotations": [
      { title: "Browse", path: "/quotations" },
      { title: "Add", onClick:() => navigate("/quotations/form"),path: "/quotations/form" },
    ],
    "/quotations/form": [
      { title: "Browse", path: "/quotations" },
      { title: "Add",onClick:() => navigate("/quotations/form"),  path: "/quotations/form" },
    ],
  };

  const breadcrumbItems = breadcrumbMap[location.pathname] || [];

  return (
    <Layout hasSider style={{ minHeight: "100vh" }}>
      <Sidebar />
      <Layout style={{ marginLeft: 250, background: '#FBFBFB' }}>
        <MainHeader />
        {title && <Title style={{ padding: '16px 30px', fontSize: '20px', margin: '10px 0 0 0' }}>
          {title === "Dashboard" ? (
            <>
              <div className='flex flex-col gap-6'>
                <h3 className='flex flex-col gap-2'>
                  <span>
                    Hi <span className="text-base inline">Username,</span>
                  </span>
                  <div>
                    Welcome To <span style={{ color: '#B83D3D' }}>CTrade</span>
                  </div>
                </h3>
                <p>{title}</p>
              </div>
            </>
          ) : (
            title
          )}</Title>}
        <Content style={{ margin: '8px 30px 0', overflow: 'initial' }}>
          <div className="breadcrumb-container">
            <Breadcrumb separator="|">
              {breadcrumbItems.map((item, index) => (
                <Breadcrumb.Item key={index} className={`breadcrumb-item ${location.pathname === item.path ? 'active-link' : ''}`}>

                  {item.onClick ? (
                    <a
                      href="#"
                      onClick={(e) => {
                        e.preventDefault();
                        item.onClick(); // Call the onClick handler
                      }}
                    >
                      {item.title}
                    </a>
                  ) : (
                    <Link to={item.path}>{item.title}</Link>
                  )}
                </Breadcrumb.Item>))}
            </Breadcrumb>

            <ExportModal
              isVisible={isExportModalVisible}
              onCancel={handleExportCancel}
              onConfirm={handleExportConfirm}
            />
            <ModalForm
              visible={isAddModalVisible}
              onClose={handleAddCancel}
               editData={editData}
            />

            <ScheduleForm
            visible={isAddModalSchedule} onClose={handleScheduleCancel} editData={editData}
            />
          </div>
          <div style={{ padding: "5", background: '#FBFBFB', borderRadius: borderRadiusLG }} className="mt-3">
            {children}
          </div>
        </Content>
      </Layout>
    </Layout>
  );
}
