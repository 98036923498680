import React, { useEffect, useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import { Form, Input, Button, Typography, Checkbox, notification } from "antd";
import { MailOutlined, LockOutlined, CloseCircleOutlined } from "@ant-design/icons";
import { useLocation } from "react-router-dom";
import { authService } from "../services";

const { Title } = Typography;

const LoginForm = ({ onLogin }) => {
  const [loading, setLoading] = useState(false);
  let location = useLocation();
  const navigate = useNavigate();

  const onFinish = async (values) => {
    setLoading(true);
    const response = await authService.loginPost(
      values.email,
      values.password,
      values.remember
    );
    setLoading(false);
    const { success, data } = response;
    if (success??false) {
      localStorage.setItem("token", data.token);
      navigate("/");
    } else {
      notification.open({
        message: response.message,
        icon: <CloseCircleOutlined style={{ color: "red" }} />,
      });
    }

  };

  const onFinishFailed = (errorInfo) => {
    console.error("Failed:", errorInfo);
  };

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      navigate("/", { replace: true }); // Redirect to the dashboard or home
    }
  }, [navigate]);

  return (
    <div className="login-container">
      <div style={{ marginBottom: 15 }}>
        <div style={{ display: "flex", justifyContent: "center",marginBottom:20 }}>
          <img
            src="/images/ctrade-logo.png"
            alt="ctrade-Logo"
            className="h-10 w-auto"
            style={{ width: "170px", height: "90px" }}
          />
        </div>
        <Title id="title" style={{ fontSize: "20px" }}>
          Sign in
        </Title>
      </div>

      <Form
        layout="vertical"
        style={{ width: "350px" }}
        name="basic"
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        initialValues={{ email: location.state?.email ?? null }}       
      >
        <Form.Item
          className="login-form-item mb-4"

          name="email"
          rules={[
            { type: "email", message: "The input is not valid email!" },
            { required: true, message: "Please input your email!" },
          ]}
        >
          <Input
            prefix={<MailOutlined />}
            type="email"
            style={{ borderRadius: "16px", height: "40px" }}
            placeholder="Enter your email"
            allowClear
          />
        </Form.Item>

        <Form.Item
          className="login-form-item"
          name="password"
          rules={[{ required: true, message: "Please input your password!" }]}
        >
          <Input.Password
            prefix={<LockOutlined />}
            style={{ borderRadius: "16px", height: "40px" }}
            placeholder="Enter your password"
            allowClear
          />
        </Form.Item>

        <Form.Item name="remember" valuePropName="checked" initialValue={true}>
          <Checkbox style={{ marginBottom: "10px" }}> Remember Me</Checkbox>

          <p style={{ fontSize: "12px", overflow: "hidden" }}>
            By signing in, you agree to Ctrade’s{" "}
            <a href="" className="underline">
              User Agreement
            </a>{" "}
            and{" "}
            <a href="" className="underline">
              Privacy Policy
            </a>
          </p>
        </Form.Item>
        <Form.Item className="login-form-item" style={{ marginBottom: 8 }}>
          <Button
            style={{
              width: "350px",
              borderRadius: "16px",
              height: "40px",
            }}
            type="primary"
            htmlType="submit"
            loading={loading}
          >
            LOGIN
          </Button>
        </Form.Item>

        <div
          style={{ display: "flex", justifyContent: "space-between", marginBottom: 0 }}
        >
          <Link to={"/SignUp"} className="text-blue hover:underline text-black">
            Create An Account?
          </Link>
        
            <Link
              href="/forget"
              className="text-blue hover:underline text-black"
            >
              Forgot Password?
            </Link>
      
        </div>
      </Form>
      {/* ForgetPassword Modal */}
    </div>
  );
};
export default LoginForm;
