import React from "react";
import { Modal, Button, Form, DatePicker } from "antd";

const ExportModal = ({ isVisible, onCancel, onConfirm }) => {

  return (
    <Modal
      title={<div style={{ textAlign: 'center' }}>Select Date Range</div>}
      open={isVisible} // Use the prop to control visibility
      onCancel={onCancel}
      footer={null}
      bodyStyle={{ padding: "20px" }}
      width={400}
    >
      <Form layout="vertical" requiredMark={false}>
        <Form.Item
          label="Select Date Range"
          name="dateRange"
          rules={[{ required: true, message: "Please select a date range!" }]}
          className="mt-4"
        >
          <DatePicker.RangePicker style={{ width: "100%" }} />
        </Form.Item>
        <div className="mt-6">
          <Button type="primary" onClick={onConfirm} style={{ width: "100%" }}>
            Download
          </Button>
        </div>
      </Form>
    </Modal>
  );
};

export default ExportModal;