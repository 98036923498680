import React from 'react'
import { Col, Row, Divider } from 'antd'
import { EditOutlined, DeleteOutlined } from '@ant-design/icons';

export default function ViewEmployee() {
  return (
    <div style={{ padding: '0 14px', border: '1px solid #ccc', borderRadius: '8px', backgroundColor: '#FFFFFF', paddingBottom: "20px" }}>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          marginRight: "auto",
          marginLeft: "auto",

          padding: '10px',
          borderRadius: '8px',
          width: '200px', // Adjust width as needed
          textAlign: 'center',
        }}
      >
        {/* Round Image */}
        <div
          style={{
            width: '60px',
            height: '60px',
            borderRadius: '50%',
            overflow: 'hidden',
            marginBottom: '15px',
            marginRight: "auto",
            marginLeft: "auto",
          }}
        >
          <img
            src="/images/empview.png"
            alt="User"
            style={{
              width: '100%',
              height: '100%',
              objectFit: 'contain',
            }}
          />
        </div>

        {/* Name */}
        <div
          style={{
            fontSize: '13px',
            fontWeight: 'bold',
            marginBottom: '5px',
          }}
        >
          ASSAD BASHARAT INAYAT

        </div>

        {/* Email */}
        <div
          style={{
            fontSize: '10px',
            color: '#888',
            marginBottom: '20px',
          }}
        >
          CS4@CTRADE.US
        </div>

        {/* Edit and Delete Icons */}
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            width: '100%',
          }}
        >
          <EditOutlined style={{ fontSize: '20px', cursor: 'pointer', marginRight: '10px' }} />
          <DeleteOutlined style={{ fontSize: '20px', cursor: 'pointer' }} />
        </div>
      </div>
      <Divider />
      <Row gutter={24}>
        <Col span={24}>
          <div
            style={{
              display: "flex",
              width: "100%",
              border: "1px solid #d9d9d9",
              borderRadius: 4,
              marginBottom: 2,
            }}
          >
            <div
              style={{
                flexBasis: "20%",
                padding: "4px 11px",
                backgroundColor: "#f5f5f5",
                borderRight: "1px solid #d9d9d9",
              }}
            >
              Address
            </div>
            <p
              style={{
                flexBasis: "40%", // This makes it less than 50% of the parent
                flexGrow: 1, // Prevent the flex behavior
                width: "200px", // Adjust width as needed
                margin: 0,
                padding: "4px 11px",
                display: "flex",
                alignItems: "center",
              }}
            >
              House no L-159, street no 5, Sector 6-C, Mehran town korangi industrial area karachi.
            </p>
          </div>
        </Col>

        <Col span={24}>
          <div
            style={{
              display: "flex",
              width: "100%",
              border: "1px solid #d9d9d9",
              borderRadius: 4,
              marginBottom: 2
            }}
          >
            <div
              style={{
                padding: "4px 11px",
                backgroundColor: "#f5f5f5",
                borderRight: "1px solid #d9d9d9",
                flexBasis: "20%",
              }}
            >
              CNIC
            </div>
            <p
              style={{
                flexBasis: "40%", // This makes it less than 50% of the parent
                flexGrow: 1,
                margin: 0,
                padding: "4px 11px",
                display: "flex",
                alignItems: "center",
              }}
            >
              4230190110365
            </p>
          </div>
        </Col>
        <Col span={24}>
          <div
            style={{
              display: "flex",
              width: "100%",
              border: "1px solid #d9d9d9",
              borderRadius: 4,
              marginBottom: 2,
            }}
          >
            <div
              style={{
                flexBasis: "20%",
                padding: "4px 11px",
                backgroundColor: "#f5f5f5",
                borderRight: "1px solid #d9d9d9",
              }}
            >
              Date Of Birth
            </div>
            <p
              style={{
                flexBasis: "40%", // This makes it less than 50% of the parent
                flexGrow: 1, // Prevent the flex behavior
                width: "200px", // Adjust width as needed
                margin: 0,
                padding: "4px 11px",
                display: "flex",
                alignItems: "center",
              }}
            >
              1992-03-21
            </p>
          </div>
        </Col>

        <Col span={24}>
          <div
            style={{
              display: "flex",
              width: "100%",
              border: "1px solid #d9d9d9",
              borderRadius: 4,
              marginBottom: 2
            }}
          >
            <div
              style={{
                padding: "4px 11px",
                backgroundColor: "#f5f5f5",
                borderRight: "1px solid #d9d9d9",
                flexBasis: "20%",
              }}
            >
              Qualification
            </div>
            <p
              style={{
                flexBasis: "40%", // This makes it less than 50% of the parent
                flexGrow: 1,
                margin: 0,
                padding: "4px 11px",
                display: "flex",
                alignItems: "center",
              }}
            >
              I COM
            </p>
          </div>
        </Col>

        <Col span={24}>
          <div
            style={{
              display: "flex",
              width: "100%",
              border: "1px solid #d9d9d9",
              borderRadius: 4,
              marginBottom: 2,
            }}
          >
            <div
              style={{
                flexBasis: "20%",
                padding: "4px 11px",
                backgroundColor: "#f5f5f5",
                borderRight: "1px solid #d9d9d9",
              }}
            >
              Salary
            </div>
            <p
              style={{
                flexBasis: "40%", // This makes it less than 50% of the parent
                flexGrow: 1, // Prevent the flex behavior
                width: "200px", // Adjust width as needed
                margin: 0,
                padding: "4px 11px",
                display: "flex",
                alignItems: "center",
              }}
            >
              1234
            </p>
          </div>
        </Col>

        <Col span={24}>
          <div
            style={{
              display: "flex",
              width: "100%",
              border: "1px solid #d9d9d9",
              borderRadius: 4,
              marginBottom: 2
            }}
          >
            <div
              style={{
                padding: "4px 11px",
                backgroundColor: "#f5f5f5",
                borderRight: "1px solid #d9d9d9",
                flexBasis: "20%",
              }}
            >
              Designation
            </div>
            <p
              style={{
                flexBasis: "40%", // This makes it less than 50% of the parent
                flexGrow: 1,
                margin: 0,
                padding: "4px 11px",
                display: "flex",
                alignItems: "center",
              }}
            >
              CUSTOMER SERVICE
            </p>
          </div>
        </Col>

        <Col span={24}>
          <div
            style={{
              display: "flex",
              width: "100%",
              border: "1px solid #d9d9d9",
              borderRadius: 4,
              marginBottom: 2
            }}
          >
            <div
              style={{
                padding: "4px 11px",
                backgroundColor: "#f5f5f5",
                borderRight: "1px solid #d9d9d9",
                flexBasis: "20%",
              }}
            >
              Status
            </div>
            <p
              style={{
                flexBasis: "40%", // This makes it less than 50% of the parent
                flexGrow: 1,
                margin: 0,
                padding: "4px 11px",
                display: "flex",
                alignItems: "center",
              }}
            >
              In-Active
            </p>
          </div>
        </Col>


        <Col span={24}>
          <div
            style={{
              display: "flex",
              width: "100%",
              border: "1px solid #d9d9d9",
              borderRadius: 4,
              marginBottom: 2
            }}
          >
            <div
              style={{
                padding: "4px 11px",
                backgroundColor: "#f5f5f5",
                borderRight: "1px solid #d9d9d9",
                flexBasis: "20%",
              }}
            >
              Permissions
            </div>
            <p
              style={{
                flexBasis: "40%", // This makes it less than 50% of the parent
                flexGrow: 1,
                margin: 0,
                padding: "4px 11px",
                display: "flex",
                alignItems: "center",
              }}
            >
              VIEW_DASHBOARD , VIEW_VEHICLES , ADD_VEHICLE , DELETE_VEHICLE , EXPORT_VEHICLE , VIEW_CUSTOMERS , ADD_CUSTOMER , DELETE_CUSTOMER , VIEW_VENDORS , ADD_VENDOR , DELETE_VENDOR , VIEW_EMPLOYEES , ADD_EMPLOYEES , DELETE_EMPLOYEES , VIEW_LOADPLANS , ADD_LOADPLAN , DELETE_LOADPLAN , VIEW_INVOICES , ADD_INVOICE , DELETE_INVOICE , APPROVE_INVOICE , VIEW_BLFORM , ADD_BLFORM , DELETE_BLFORM , VIEW_ACCOUNTS , ADD_TRANSACTION , BROWSE_TRANSACTION , VIEW_LEDGER , VIEW_APPOINTMENTS , ADD_APPOINTMENTS , CHANGE_APPOINTMENT_STATUS , VIEW_BOOKING , ADD_BOOKING , DELETE_BOOKING , VIEW_SETTINGS , VIEW_QUOTATIONS , SEND_EMAIL , VIEW_INVENTORY_REPORTS , VIEW_LOADED_DETAILS , VIEW_VENDOR_QUOTATION , ADD_VENDOR_QUOTATION , ADD_LOADPLAN_VENDORS , VIEW_OPERATION_DASHBOARD , VIEW_CUSTOMER_AJAX , ADD_IMO , VIEW_IMO , EDIT_IMO , DELETE_IMO ,
            </p>
          </div>
        </Col>
      </Row>

    </div>
  )
}
