
import { Table, Space, Button, Row, Col, Input, Select, Modal, Form, DatePicker } from 'antd';
import { SearchOutlined, FilePdfOutlined } from '@ant-design/icons'
import { CiSquarePlus } from "react-icons/ci";
import { Divider } from 'antd';
import React, { useState } from 'react'

function DebitNotes() {
  const [isModalShow, setIsModalShow] = useState(false);

  const showModal = () => setIsModalShow(true);

  const handleOk = () => {
    setIsModalShow(false)
  }
  const handleCancel = () => {
    setIsModalShow(false)
  }


  const options = [
    {
      value: '10',
      label: '10',
    },
    {
      value: '9',
      label: '9',
    },
  ]
  const columns = [
    {
      title: "Id",
      dataIndex: 'Id',
      key: '_id',
      render: (text, record, index) => index + 1,
    },
    {
      title: "Company",
      dataIndex: 'Company',
      key: 'Company',
    },
    {
      title: "Customer",
      dataIndex: 'Customer',
      key: 'Customer',
    },
    {
      title: "Invoice#",
      dataIndex: 'Invoice',
      key: 'Invoice',
    },
    {
      title: "Amount",
      dataIndex: 'Amount',
      key: 'Amount',
    },
    {
      title: "Expense",
      dataIndex: 'Expense',
      key: 'Expense',
    },
    {
      title: "Issue Date",
      dataIndex: 'IssueDate',
      key: 'IssueDate',
    },
    {
      title: "Description",
      dataIndex: 'Description',
      key: 'Description',
    },

    {
      title: 'Action',
      key: 'Action',
      render: (text, { _id }) => (
        <Space size='small'>
          <Button type='primary' size="small">
            <FilePdfOutlined />
          </Button>
        </Space >
      )
    },
  ];
  const data = [
    {
      key: 'id',
      Company: "-",
      Customer: "0254-04-22",
      Invoice: "N/A",
      Expense: "N/A",
      Amount: "2500.00",
      IssueDate: "2024-09-16",
      Description: "CREDIT NOTE FOR EXTRA PAYMENT RECEIVED ON WIRE FOR CONTAINER NO HASU4292421 Send"
    },
    {
      key: 'id',
      Company: "-",
      Customer: "0254-04-22",
      Invoice: "N/A",
      Expense: "N/A",
      Amount: "2500.00",
      IssueDate: "2024-09-16",
      Description: "CREDIT NOTE FOR EXTRA PAYMENT RECEIVED ON WIRE FOR CONTAINER NO HASU4292421 Send"
    },
    {
      key: 'id',
      Company: "-",
      Customer: "0254-04-22",
      Invoice: "N/A",
      Expense: "N/A",
      Amount: "2500.00",
      IssueDate: "2024-09-16",
      Description: "CREDIT NOTE FOR EXTRA PAYMENT RECEIVED ON WIRE FOR CONTAINER NO HASU4292421 Send"
    },
    {
      key: 'id',
      Company: "-",
      Customer: "0254-04-22",
      Invoice: "N/A",
      Expense: "N/A",
      Amount: "2500.00",
      IssueDate: "2024-09-16",
      Description: "CREDIT NOTE FOR EXTRA PAYMENT RECEIVED ON WIRE FOR CONTAINER NO HASU4292421 Send"
    },
    {
      key: 'id',
      Company: "-",
      Customer: "0254-04-22",
      Invoice: "N/A",
      Expense: "N/A",
      Amount: "2500.00",
      IssueDate: "2024-09-16",
      Description: "CREDIT NOTE FOR EXTRA PAYMENT RECEIVED ON WIRE FOR CONTAINER NO HASU4292421 Send"
    },
  ];
  return (
    <div style={{ padding: '15px', border: '1px solid #ccc', borderRadius: '8px', backgroundColor: "#FFFFFF" }}>
      <Row gutter={24}>
        <Col span={24}>
          <h1 style={{ fontWeight: "bold" }}>Debit Notes</h1>
        </Col>
      </Row>
      <Divider style={{ marginTop: 15 }} />
      <Col style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
        
          <CiSquarePlus size={30} style={{ cursor: 'pointer' }} onClick={showModal} />
     
      </Col>
      <Row gutter={24} style={{ marginTop: '15px', background: "#F2F2F7", padding: "10px", borderRadius: '4px' }} className='flex justify-between'>
        <Col span={10}  >
          <Space direction="horizontal" size={12} >
            <p>Show</p>
            <Select defaultValue="10"
              options={options}
              size="small"
              // dropdownMatchSelectWidth={false} // Prevent dropdown from inheriting the small width
              style={{
                width: 60, // Ensure the select is square-like
                height: 25, // Equal height for a square appearance
                fontSize: 14,
              }} />
            <p>entries</p>
          </Space>
        </Col>

        <Col span={14} style={{ textAlign: "right" }} >
          <Input
            placeholder="Search"
            prefix={<SearchOutlined />}
             className="w-auto lg:w-2/5"
          />
        </Col>
      </Row>

      <Modal
        title={<div style={{ width: "100%" }}>Create Debit Note</div>}
        open={isModalShow}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={null}
        closable={true}
        width={700}
        bodyStyle={{ padding: "15px" }} >
        <Form layout="vertical" requiredMark={false}>
          <Row gutter={24}>
            <Col span={8}>
              <Form.Item
                label="Select Company"
                name="SelectCompany"
                rules={[{ required: true, message: "Please select an appointment type" }]}>
                <Select placeholder="Select Inter-Company">
                  <Select.Option value="Online">Online</Select.Option>
                  <Select.Option value="Offline">Offline</Select.Option>
                </Select>
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                label="Select Customer"
                name="SelectCustomer"
                rules={[{ required: true, message: "Please select an appointment type" }]}>
                <Select placeholder="Select Inter-Company">
                  <Select.Option value="Online">Online</Select.Option>
                  <Select.Option value="Offline">Offline</Select.Option>
                </Select>
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                label="Select Invoice"
                name="SelectInvoice"
                rules={[{ required: true, message: "Please select an appointment type" }]}>
                <Select placeholder="Select Inter-Company">
                  <Select.Option value="Online">Online</Select.Option>
                  <Select.Option value="Offline">Offline</Select.Option>
                </Select>
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                label="Select Expense"
                name="SelectExpense"
                // rules={[{ required: true, message: "Please enter the number of allowed appointments" }]}
              >
                <Input type="string" placeholder="91099" />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                label="Amount"
                name="Amount"
                rules={[{ required: true, message: "Please enter the number of allowed appointments" }]}
              >
                <Input type="number" placeholder="91099" />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label="Issue Date" name="IssueDate" rules={[{ required: true, message: "Please select a date" }]}>
                <DatePicker style={{ width: "100%" }} />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                label="Description" >
                <Input.TextArea
                  placeholder="Enter special notes here..."
                  rows={4}
                />
              </Form.Item>
            </Col>
          </Row>
          <Col span={24} >
            <Form.Item style={{ marginTop: 10 ,textAlign:"center"}}>
              <Button type="primary" style={{ width: '70%' }}>Create Debit Note</Button>
            </Form.Item>
          </Col>
        </Form>
      </Modal>
      <Table columns={columns}scroll={{ x: "max-content" }} dataSource={data} style={{ marginTop: '10px' }} />
    </div>
  )
}

export default DebitNotes
