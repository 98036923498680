import React, { useState } from 'react'
import { Table, Space, Button, Row, Col, Modal } from 'antd';
import { EyeOutlined, EditOutlined, DeleteOutlined } from '@ant-design/icons'
import { Link ,useNavigate} from 'react-router-dom';

function Quotation() {
const navigate = useNavigate()

 const editQuotation = (id) => navigate(`/quotations/form/${id}`);
  const [isModalshow, setIsModalShow] = useState()

  const showModal = () => {
    setIsModalShow(true);
  };
  const handleOk = () => {
    setIsModalShow(false)
  }
  const handleCancel = () => {
    setIsModalShow(false)
  };

  const columns = [
    {
      title: "#",
      dataIndex: '#',
      key: '#',
      render: (text, record, index) => index + 1,
    },
    {
      title: "Name",
      dataIndex: 'Name',
      key: 'Name',
    },
    {
      title: "Date",
      dataIndex: 'Date',
      key: 'Date',
    },
    {
      title: "Validity",
      dataIndex: 'Validity',
      key: 'Validity',
    },

    {
      title: "Actions",
      key: 'Actions',
      render: (text, record) => (
        <Space size='small'>
     
          <Button type='primary' size="small"  onClick={() => editQuotation(record.id)}>
            <EditOutlined />
          </Button>
   
          <Link to={"/quotation-view"}>
            <Button type='primary' size="small">
              <EyeOutlined />
            </Button>
          </Link >

          <Button type='primary' size="small" onClick={showModal}>
            <DeleteOutlined />
          </Button>
        </Space >
      )
    },

  ];
  const data = [
    {
      key: '13574',
      Name: "MEHMOOD",
      Date: "2023-02-05",
      Validity: "2023-01-24",
    },
    {
      key: '13574',
      Name: "MEHMOOD",
      Date: "2023-02-05",
      Validity: "2023-01-24",
    },
    {
      key: '13574',
      Name: "MEHMOOD",
      Date: "2023-02-05",
      Validity: "2023-01-24",
    },
    {
      key: '13574',
      Name: "MEHMOOD",
      Date: "2023-02-05",
      Validity: "2023-01-24",
    },
    {
      key: '13574',
      Name: "MEHMOOD",
      Date: "2023-02-05",
      Validity: "2023-01-24",
    },
  ];

  return (
    <div style={{ padding: '15px', border: '1px solid #ccc', borderRadius: '8px', backgroundColor: "#FFFFFF" }}>
      <Row gutter={16}>
        <Col span={12}>
          <h1 style={{ fontWeight: "bold" }}>Quotations</h1>
        </Col>
      </Row>

{/* delete */}
      <Modal
        title={<div style={{ textAlign: 'center' }}>Confirm</div>}
        visible={isModalshow}
        onOk={handleOk}
        onCancel={handleCancel}
        centered
        bodyStyle={{
          padding: "20px",
        }}
        width={300}
        footer={
          <div className='flex justify-center gap-3'>
            <Button type="default" onClick={handleCancel} style={{ width: "30%" }}>
              Cancel
            </Button>
            <Button type="primary" onClick={handleOk} style={{ width: "30%" }}>
              Confirm
            </Button>
          </div>
        }
      >
        <p style={{ textAlign: 'center' }}>Are you sure you want to delete this Quotation?</p>

      </Modal>
      <Table columns={columns} scroll={{ x: "max-content" }} dataSource={data} style={{ marginTop: '20px' }} />
    </div>
  )
}

export default Quotation

