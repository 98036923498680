import React, { useEffect, useState } from 'react';
import { Button, Col, Form, Input, Row, DatePicker, Upload } from 'antd';
import { Select, Typography } from 'antd';

const { Option } = Select;
const { Title } = Typography;

const dummyData = [
    { id: '1', name: 'vehile' },
    { id: '2', name: 'invoice_no' },
    { id: '3', name: 'operating_port' },
    { id: '4', name: 'towing_location' },
    { id: '5', name: 'operating_port' },
    { id: '6', name: 'carrier_name' },
    { id: '7', name: 'year' },
    { id: '8', name: 'invoice_no' },
    { id: '9', name: 'invoice_no' },
    { id: '10', name: 'invoice_no' },
    { id: '12', name: 'invoice_no' },

];

function EditProfile() {

    const [form] = Form.useForm();
    const [formLayout, setFormLayout] = useState('vertical');
    const [selectedEntities, setSelectedEntities] = useState([]);

    const onFormLayoutChange = ({ layout }) => {
        setFormLayout(layout);
    };

    const handleSelectChange = (value) => {
        setSelectedEntities(value);
    };

    const calculateWidth = (dataLength) => {
        const baseWidth = 400; // Default width
        const additionalWidth = dataLength * 20; // Add extra width for more data
        return baseWidth + additionalWidth;
    };

    useEffect(() => {
        setSelectedEntities(dummyData.map((entity) => entity.id));
    }, []);

    return (
        <div style={{ padding: '0 14px', border: '1px solid #ccc', borderRadius: '8px', backgroundColor: '#FFFFFF' }}>
            <Row
                gutter={26}
                style={{
                    backgroundColor: '#FBFBFB', marginBottom: 15, padding: '10px', borderRadius: '4px', borderBottom: '1px solid #E8E8E9',
                }}
            >
                <Col span={12}>
                    <h1 style={{ fontWeight: 'bold' }}>Add Employee</h1>
                </Col>
            </Row>
            <Form
                requiredMark={false}
                layout={formLayout}
                form={form}
                initialValues={{ layout: formLayout }}
                onValuesChange={onFormLayoutChange}
            >
                <Row gutter={24}>
                    <Col span={8}>
                        <Form.Item label="Name">
                            <Input placeholder="" />
                        </Form.Item>
                    </Col>

                    <Col span={8}>
                        <Form.Item label="Email">
                            <Input placeholder="28-Oct-2024" />
                        </Form.Item>
                    </Col>

                    <Col span={8}>
                        <Form.Item label="Address">
                            <Input placeholder="" />
                        </Form.Item>
                    </Col>

                    <Col span={8}>
                        <Form.Item label="Town ">
                            <Input placeholder="0" />
                        </Form.Item>
                    </Col>

                    <Col span={8}>
                        <Form.Item label="City ">
                            <Input placeholder="0" />
                        </Form.Item>
                    </Col>

                    <Col span={8}>
                        <Form.Item
                            label="Country"
                            name="Country"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please input!',
                                },
                            ]}
                        >
                            <Select placeholder="Select Country" />
                        </Form.Item>
                    </Col>

                    <Col span={8}>
                        <Form.Item label="Phone">
                            <Input placeholder="" />
                        </Form.Item>
                    </Col>

                    <Col span={8}>
                        <Form.Item
                            label="Live Editing"
                            name="Live Editing"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please input!',
                                },
                            ]}
                        >
                            <Select placeholder="Disabled" />
                        </Form.Item>
                    </Col>

                    <Col span={24} style={{ display: 'flex', justifyContent: 'center' }}>
                        <div style={{ width: calculateWidth(dummyData.length), margin: '30px' }}>

                            <Form.Item >
                                <Title level={5}>View Columns</Title>
                                <Select
                                    mode="multiple"  // Allow multiple selections
                                    style={{ width: '100%' }}
                                    placeholder="Select entities"
                                    value={selectedEntities} // Pass the current selected entities here
                                    onChange={handleSelectChange}  // Handle changes to selection
                                >
                                    {dummyData.map((entity) => (
                                        <Option key={entity.id} value={entity.id}   >
                                            {entity.name}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </div>
                    </Col>

                    <Col span={24}>
                        <Form.Item style={{ textAlign: 'center', marginTop: 10 }}>
                            <Button type="primary" style={{ width: '50%' }}>
                                UPDATE PROFILE
                            </Button>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </div>
    );
}

export default EditProfile;
