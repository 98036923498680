import React from 'react'

import { Doughnut } from 'react-chartjs-2';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
ChartJS.register(ArcElement, Tooltip, Legend);
function InvoiceChart() {
    const data = {
        datasets: [{
          label: 'My First Dataset',
          data: [50, 60, 100],
          backgroundColor: [
            'rgba(48, 112, 245, 1)', // Blue
            'rgba(87, 190, 181, 1)', // Green
            'rgba(174, 89, 220, 1)'  // Purple
          ],
          hoverOffset: 4,
        }],
        labels: ['Invoice Amount $0 ', 'Pending Amount $0 ', 'Approval Pending Amount $0 '],
      };
    const config = {
        type: 'doughnut',
        data: data,
        cutout: '80%',
  
        plugins: {
            legend: {
              position: 'bottom',
            }
          }
      };
  return (
    <div style={{ padding: '20px', border: '1px solid #ccc', borderRadius: '8px',width: '100%'}}>
    <h3>Invoice Distribution</h3>
      <Doughnut data={data} options={config} />
    </div>
  )
}

export default InvoiceChart
