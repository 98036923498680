import React from 'react';
import { Col, Form, Input, Row, DatePicker, Table,  Space } from 'antd';
import { Select } from 'antd';
import {  SearchOutlined } from '@ant-design/icons'



function VendorContainerPayment() {
    const [form] = Form.useForm();
    const columns = [
        {
            title: "#",
            dataIndex: '#',
            key: '#',
            render: (text, record, index) => index + 1,
        },
        {
            title: "Container Id",
            dataIndex: 'IContainer_Id',
            key: 'Container_Id',
          
        },
        {
            title: "Vendor",
            dataIndex: "Vendor",
            key: "Vendor",
         
        },
        {
            title: "Description",
            dataIndex: "Description",
            key: "Description",
         
        },
        {
            title: "Fright Charges",
            dataIndex: "Fright_Charges",
            key: "Fright_Charges",
         
        }, {
            title: "Collection Amount($)",
            dataIndex: "Collection_Amount($)",
            key: "Collection_Amount($)",
         
               
        }, {
            title: "Chassis",
            dataIndex: "Chassis",
            key: "Chassis",
           
        },
      
        {
            title: "Total",
            dataIndex: "Total",
            key: "Total",
           
        },
    ]
    const data = [
    ]

    return (
        <div
            style={{
                padding: '0 14px',
                border: '1px solid #ccc',
                borderRadius: '8px',
                backgroundColor: '#FFFFFF',
            }}
        >
            <Row
                gutter={24}
                style={{

                    marginBottom: 15,
                    padding: '10px',
                    borderRadius: '4px',
                    borderBottom: '1px solid #E8E8E9',
                }}
            >
                <Col span={24}>
                    <h1 style={{ fontWeight: 'bold' }}>Add Receipt</h1>
                </Col>
            </Row>

            <Form
                requiredMark={false}
                layout="vertical"
                form={form} >
                <Row gutter={24}>
             
                    <Col span={6}>
                        <Form.Item label="Receipt Date">
                            <DatePicker style={{ width: '100%' }} placeholder="Select Date" />
                        </Form.Item>
                    </Col>

                    <Col span={6}>
                        <Form.Item label="Collection Date">
                            <DatePicker style={{ width: '100%' }} placeholder="Select Date" />
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item
                            label="Inter-Company"
                            name="Inter-Company"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please input!',
                                },
                            ]}
                        >
                            <Select placeholder="No Customer" />
                        </Form.Item>
                    </Col>
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <img src="/images/search.png" alt="" width={35}/>            
                    </div>
                    </Row>
                      </Form>

            <Col span={24}>
                    <Row gutter={22} style={{padding: '10px', marginTop: '10px', background: "#F2F2F7", borderRadius: '4px' }} className='flex justify-between'>
                        <Col span={10}  >
                            <Space direction="horizontal" size={10} >
                                <img src="/images/ve.png" alt="" />
                                <img src="/images/ve2.png" alt="" />
                                <img src="/images/ve3.png" alt="" />
                                <img src="/images/ve4.png" alt="" />
                                <img src="/images/v35.png" alt="" />
                            </Space>
                        </Col>
                        <Col span={14} style={{ textAlign: "right" }} >
                            <Input
                                placeholder="Search"
                                prefix={<SearchOutlined />}
                                style={{ width: 200 }}
                            />
                        </Col>
                    </Row>
                    <Table columns={columns} pagination={true} scroll={{ x: "max-content" }} dataSource={data} rowKey="Id" style={{ marginTop: '10px' }} />
                    <div className='flex justify-end gap-32 mt-5 mb-5' style={{fontWeight:"bold"}}>
                <p >
                Total Amount Collected :
                </p>
                <p>
                  $0
                </p>
              </div>
            </Col>
        </div >
    );
}

export default VendorContainerPayment;
