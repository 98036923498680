
import { Table, Space, Button, Row, Col, Input, Select, Modal, Form, DatePicker } from 'antd';
import { SearchOutlined, SendOutlined, FilePdfOutlined } from '@ant-design/icons'
import { CiSquarePlus } from "react-icons/ci";
import { Divider } from 'antd';
import React, { useState } from 'react'

function CreditNotes() {

  const [isModalShow, setIsModalShow] = useState(false);

  const showModal = () => setIsModalShow(true);

  const handleOk = () => {
    setIsModalShow(false)
  }
  const handleCancel = () => {
    setIsModalShow(false)
  }
  const options = [
    {
      value: '10',
      label: '10',
    },
    {
      value: '9',
      label: '9',
    },
  ]
  const options2 = [
    {
      value: 'Print',
      label: 'Print',
    },
    {
      value: 'Pdf',
      label: 'Pdf',
    },
    {
      value: 'CSV',
      label: 'CSV',
    },
    {
      value: 'Copy',
      label: 'Copy',
    },

  ]
  const columns = [
    {
      title: "Id",
      dataIndex: 'Id',
      key: '_id',
      render: (text, record, index) => index + 1,
    },
    {
      title: "Credit Number",
      dataIndex: 'CreditNumber',
      key: 'CreditNumber',
    },
    {
      title: "Issue Date",
      dataIndex: 'IssueDate',
      key: 'IssueDate',
    },
    {
      title: "Invoice",
      dataIndex: 'Invoice',
      key: 'Invoice',
    },
    {
      title: "Amount",
      dataIndex: 'Amount',
      key: 'Amount',
    },
    {
      title: "Description",
      dataIndex: 'Description',
      key: 'Description',
    },

    {
      title: 'Action',
      key: 'Action',
      render: (text, { _id }) => (
        <Space size='small'>
          <Button type='primary' size="small">
            <SendOutlined />
          </Button>
          <Button type='primary' size="small">
            <FilePdfOutlined />
          </Button>

        </Space >
      )
    },
  ];
  const data = [
    {
      key: 'id',
      CreditNumber: "7117",
      IssueDate: "0254-04-22",
      Invoice: "4458",
      Amount: "-75",
      Description: "CREDIT NOTE FOR EXTRA PAYMENT RECEIVED ON WIRE FOR CONTAINER NO HASU4292421 Send"
    },
    {
      key: 'id',
      CreditNumber: "7117",
      IssueDate: "0254-04-22",
      Invoice: "4458",
      Amount: "-75",
      Description: "CREDIT NOTE FOR EXTRA PAYMENT RECEIVED ON WIRE FOR CONTAINER NO HASU4292421 Send"
    },
    {
      key: 'id',
      CreditNumber: "7117",
      IssueDate: "0254-04-22",
      Invoice: "4458",
      Amount: "-75",
      Description: "CREDIT NOTE FOR EXTRA PAYMENT RECEIVED ON WIRE FOR CONTAINER NO HASU4292421 Send"
    },
    {
      key: 'id',
      CreditNumber: "7117",
      IssueDate: "0254-04-22",
      Invoice: "4458",
      Amount: "-75",
      Description: "CREDIT NOTE FOR EXTRA PAYMENT RECEIVED ON WIRE FOR CONTAINER NO HASU4292421 Send"
    },
    {
      key: 'id',
      CreditNumber: "7117",
      IssueDate: "0254-04-22",
      Invoice: "4458",
      Amount: "-75",
      Description: "CREDIT NOTE FOR EXTRA PAYMENT RECEIVED ON WIRE FOR CONTAINER NO HASU4292421 Send"
    },
  ];
  return (
    <div style={{ padding: '15px', border: '1px solid #ccc', borderRadius: '8px', backgroundColor: "#FFFFFF" }}>
      <Row gutter={16}>
        <Col span={12}>
          <h1 style={{ fontWeight: "bold" }}>Credit Notes</h1>
        </Col>
      </Row>
      <Divider style={{ marginTop: 15 }} />
      <Row gutter={24} style={{ marginTop: '5px', padding: "5px" }} className='flex justify-between'>
        <Col span={10}  >
          <Space direction="horizontal" size={10} >
            <p>Show</p>
            <Select defaultValue="10"
              options={options}
              size="small"
              // dropdownMatchSelectWidth={false} // Prevent dropdown from inheriting the small width
              style={{
                width: 60, // Ensure the select is square-like
                height: 25, // Equal height for a square appearance
                fontSize: 14,
              }} />
            <p>entries</p>
          </Space>
        </Col>
        <Col flex="none" style={{ display: 'flex', alignItems: 'center', gap: '15px', justifyContent: 'flex-end' }}>

          <CiSquarePlus size={30} style={{ cursor: 'pointer' }} onClick={showModal} />

        </Col>
      </Row>
      <Row gutter={24} style={{ marginTop: '10px', background: "#F2F2F7", padding: "10px", borderRadius: '4px' }} className='flex justify-between'>
        <Col span={12}  >
          <Space direction="horizontal" size={12} >
            <Select defaultValue="Print"
              options={options2}
              size="large"
              // dropdownMatchSelectWidth={false} // Prevent dropdown from inheriting the small width
              style={{
                width: "200px", // Ensure the select is square-like
                height: 30, // Equal height for a square appearance
                fontSize: 14,
              }}
            />
          </Space>
        </Col>

        <Col span={12} style={{ textAlign: "right" }} >
          <Input
            placeholder="Search"
            prefix={<SearchOutlined />}
            className="w-auto lg:w-2/5"
          />
        </Col>
      </Row>
      <Modal
        title={<div style={{ width: "100%" }}>Add Credit Note</div>}
        open={isModalShow}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={null}
        closable={true}
        width={500}
        bodyStyle={{ padding: "15px" }} >
        <Form layout="vertical" requiredMark={false}>
          <Row gutter={24}>
            <Col span={12}>
              <Form.Item
                label="Invoice Number"
                name="InvoiceNumber"
                rules={[{ required: true, message: "Please select an appointment type" }]}>
                <Select placeholder="Select Inter-Company">
                  <Select.Option value="Online">Online</Select.Option>
                  <Select.Option value="Offline">Offline</Select.Option>
                </Select>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="Credit Number"
                name="Credit Number"
                rules={[{ required: true, message: "Please enter the number of allowed appointments" }]}
              >
                <Input type="number" placeholder="91099" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="Amount"
                name="Amount"
                rules={[{ required: true, message: "Please enter the number of allowed appointments" }]}
              >
                <Input type="number" placeholder="91099" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="Date" name="Date" rules={[{ required: true, message: "Please select a date" }]}>
                <DatePicker style={{ width: "100%" }} />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                label="Description" >
                <Input.TextArea
                  placeholder="Enter special notes here..."
                  rows={5}
                />
              </Form.Item>
            </Col>
          </Row>
          <Col span={24}>
            <Form.Item style={{ marginTop: 20 }}>
              <Button type="primary" style={{ width: '100%' }}>Create Debit Note</Button>
            </Form.Item>
          </Col>
        </Form>
      </Modal>
      <Table columns={columns} scroll={{ x: "max-content" }} dataSource={data} style={{ marginTop: '10px' }} />
    </div>
  )
}
export default CreditNotes

