import React from 'react'
import { Table, Space, Button, Row, Col, Input } from 'antd';
import { EditOutlined, SearchOutlined } from '@ant-design/icons'
function NoTitleTable() {
  const columns = [
    {
      title: "#",
      dataIndex: '#',
      key: '_id',
      render: (text, record, index) => index + 1,
    },
    {
      title: "Title",
      dataIndex: 'Title',
      key: 'Title',

    },
    {
      title: "Date Rcd",
      dataIndex: 'DateRcd',
      key: 'DateRcd',

    },
    {
      title: "Product",
      dataIndex: 'Product',
      key: 'Product',
    },
    {
      title: "vechical",
      dataIndex: 'vechical',
      key: 'vechical',
    },
    {
      title: "Vin Number",
      dataIndex: 'VinNumber',
      key: 'VinNumber',
    },
    {
      title: "Customer",
      dataIndex: 'Customer',
      key: 'Customer',
    },
    {
      title: 'Edit',
      key: 'Edit',
      render: (text, { _id }) => (
        <Space size='small'>
          <Button type='primary' size="small">
            <EditOutlined />
          </Button>
        </Space >
      )
    },
  ];
  const data = [
    {
      key: '1',
      Title: "No",
      DateRcd: "05 Jun, 2023",
      status: 'John Brown',
      Product: 'New York No. 1 Lake Park',
      vechical: 'New York No. 1 Lake Park',
      VinNumber: '5TFHW5F1XGX515569',
      Customer: 'PRESTIGE SHIPPING INC'
    },
    {
      key: '2',
      Title: "No",
      DateRcd: "05 Jun, 2023",
      status: 'Jim Green',
      Product: 'London No. 1 Lake Park',
      vechical: 'London No. 1 Lake Park',
      VinNumber: '5TFHW5F1XGX515569',
      Customer: 'PRESTIGE SHIPPING INC'
    },
    {
      key: '3',
      Title: "No",
      DateRcd: "05 Jun, 2023",
      status: 'Joe Black',
      Product: 'Sydney No. 1 Lake Park',
      vechical: 'Sydney No. 1 Lake Park',
      VinNumber: '5TFHW5F1XGX515569',
      Customer: 'PRESTIGE SHIPPING INC'
    },
  ];
  return (
    <div style={{ padding: '20px', border: '1px solid #ccc', borderRadius: '8px' }}>
      <Row gutter={16}>
        <Col span={12}>
          <h1 style={{ fontWeight: "bold" }}>No Title (25 Days old)</h1>
        </Col>
      </Row>
      <Row gutter={24} style={{ marginTop: '20px', background: "#D9D9D9", padding: "10px", borderRadius: '4px' }} className='flex justify-between'>
        <Col span={10}  >
          <Space direction="horizontal" size={10} >
            <img src="/images/ve.png" alt="" />
            <img src="/images/ve2.png" alt="" />
            <img src="/images/ve3.png" alt="" />
            <img src="/images/ve4.png" alt="" />
            <img src="/images/v35.png" alt="" />
          </Space>
        </Col>
        <Col span={14} style={{ textAlign: "right" }} >
          <Input
            placeholder="Search"
            prefix={<SearchOutlined />}
            style={{ width: 200 }}
          />
        </Col>
      </Row>


      <Row gutter={24}>
      <Col span={24} >
      <Table columns={columns}  scroll={{ x: "max-content" }}  dataSource={data} style={{ marginTop: '20px' }} />
      </Col>
      </Row>
    </div>
  )
}
export default NoTitleTable