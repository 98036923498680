import React, { useState } from 'react'
import { Table, Space, Button, Row, Col, Input, Modal, Form, Select, Checkbox } from 'antd';
import { CiSquarePlus } from "react-icons/ci";
import { EditOutlined, SearchOutlined, EyeOutlined, DeleteOutlined } from '@ant-design/icons'
function Setting() {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isModalShow, setIsModalShow] = useState(false);
    const [isModalVisible, setIsModalVisible] = useState(false);

    const showModal = () => setIsModalOpen(true);
    const showEditModal = () => setIsModalShow(true);
    const showModalHandler = () => setIsModalVisible(true);

    const handleOk = () => {
        setIsModalVisible(false)
        setIsModalOpen(false)
        setIsModalShow(false)
    }
    const handleCancel = () => {
        setIsModalOpen(false);
        setIsModalVisible(false)
        setIsModalShow(false)

    };
    const columns = [
        {
            title: "Id",
            dataIndex: 'Id',
            key: 'Id',
            render: (text, record, index) => index + 1,
        },
        {
            title: "Country",
            dataIndex: 'Country',
            key: 'Country',
        },
        {
            title: "Name",
            dataIndex: 'Name',
            key: 'Name',
        },
        {
            title: "Status",
            dataIndex: 'Status',
            key: 'Status',
            render: (text, { _id }) => (

                <Button style={{ backgroundColor: "#06D652 ", color: "white" }}>
                    Active
                </Button>
            )
        },
        {
            title: 'Actions',
            key: 'Actions',
            render: (text, { _id }) => (
                <Space size='small'>
                    <Button type='primary' size="small" onClick={showEditModal}>
                        <EditOutlined />
                    </Button>
                    <Button type='primary' size="small">
                        <EyeOutlined />
                    </Button>
                    <Button type='primary' size="small" onClick={showModalHandler}>
                        <DeleteOutlined />
                    </Button>

                </Space >
            )
        },
    ];
    const data = [
        {
            key: '1',
            Country: 'Libya',
            Name: 'AL KHUMS',

        },
        {
            key: '1',
            Country: 'Libya',
            Name: 'AL KHUMS',

        },
        {
            key: '1',
            Country: 'Libya',
            Name: 'AL KHUMS',

        },
        {
            key: '1',
            Country: 'Libya',
            Name: 'AL KHUMS',

        }, {
            key: '1',
            Country: 'Libya',
            Name: 'AL KHUMS',

        },
    ];
    return (
        <div style={{ padding: '10px', border: '1px solid #ccc', borderRadius: '8px', backgroundColor: "#FFFFFF" }}>
            <Row
                gutter={30}
                style={{ padding: '5px', borderRadius: '4px' }}
                className="flex items-center"
            >
                {/* Left Section: Destinations Heading */}
                <Col flex="auto">
                    <Space direction="horizontal" size={15}>
                        <h5 className="font-bold">Destinations</h5>
                    </Space>
                </Col>

                {/* Right Section: Search Input and "+" Icon */}
                <Col flex="none" style={{ display: 'flex', alignItems: 'center', gap: '15px', justifyContent: 'flex-end' }}>
                    {/* Search Input */}
                    <Input
                        placeholder="Search"
                        prefix={<SearchOutlined />}
                        style={{ width: 200 }}
                    />
                    {/* Plus Icon */}

                    <CiSquarePlus size={30} style={{ cursor: 'pointer' }} onClick={showModal} />

                </Col>
            </Row>
            {/* plus icon */}
            <Modal
                open={isModalOpen}
                onOk={handleOk}
                onCancel={handleCancel}
                footer={null}
                closable={true}
                width={450}
                bodyStyle={{ paddingTop: "50px" }}
            >
                <Form layout="vertical" requiredMark={false}>
                    {/* Country Select Field */}
                    <Form.Item label="Country" name="country" rules={[{ required: true, message: "Please select a country" }]}>
                        <Select placeholder="Select a country">
                            <Select.Option value="usa">USA</Select.Option>
                            <Select.Option value="uk">UK</Select.Option>
                            <Select.Option value="india">India</Select.Option>
                        </Select>
                    </Form.Item>

                    {/* POD Input Field */}
                    <Form.Item label="POD" name="pod" rules={[{ required: true, message: "Please enter POD" }]}>
                        <Input placeholder="Enter POD" />
                    </Form.Item>

                    {/* Checkbox */}
                    <Form.Item>
                        <Checkbox>Payment Collection Type</Checkbox>
                    </Form.Item>

                    {/* Add Destination Button */}
                    <Form.Item>
                        <Button type="primary" htmlType="submit" style={{ width: "100%" }} onClick={handleOk}>
                            Add Destination
                        </Button>
                    </Form.Item>
                </Form>
            </Modal>

            {/* edit */}
            <Modal
                open={isModalShow}
                onOk={handleOk}
                onCancel={handleCancel}
                footer={null}
                closable={true}
                width={450}
                bodyStyle={{ paddingTop: "50px" }}
            >
                <Form layout="vertical" requiredMark={false}>
                    {/* Country Select Field */}
                    <Form.Item label="Country" name="country" rules={[{ required: true, message: "Please select a country" }]}>
                        <Select placeholder="Select a country">
                            <Select.Option value="usa">USA</Select.Option>
                            <Select.Option value="uk">UK</Select.Option>
                            <Select.Option value="india">India</Select.Option>
                        </Select>
                    </Form.Item>

                    {/* POD Input Field */}
                    <Form.Item label="POD" name="pod" rules={[{ required: true, message: "Please enter POD" }]}>
                        <Input placeholder="Enter POD" />
                    </Form.Item>

                    {/* Checkbox */}
                    <Form.Item>
                        <Checkbox>Payment Collection Type</Checkbox>
                    </Form.Item>

                    {/* Add Destination Button */}
                    <Form.Item>
                        <Button type="primary" htmlType="submit" style={{ width: "100%" }} onClick={handleOk}>
                            Update Desitnation
                        </Button>
                    </Form.Item>
                </Form>
            </Modal>

            {/* delete */}
            <Modal
                title={<div style={{ textAlign: 'center' }}>Confirm</div>}
                visible={isModalVisible}
                onOk={handleOk}
                onCancel={handleCancel}
                centered
                bodyStyle={{
                    padding: "20px",
                }}
                width={300}
                footer={
                    <div className='flex justify-center gap-3'>
                        <Button type="default" onClick={handleCancel} style={{ width: "30%" }}>
                            Cancel
                        </Button>
                        <Button type="primary" onClick={handleOk} style={{ width: "30%" }}>
                            Confirm
                        </Button>
                    </div>
                }
            >
                <p style={{ textAlign: 'center' }}>Are you sure you want to delete this?</p>
            </Modal>

            <Table columns={columns} scroll={{ x: "max-content" }} dataSource={data} style={{ marginTop: '5px' }} />
        </div>
    )
}
export default Setting
