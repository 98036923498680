import React, { useState } from 'react'
import { Button, Checkbox, Col, DatePicker, Form, Input, Row, Select, Table } from 'antd';
import { CiSquarePlus } from "react-icons/ci";
import { Option } from 'antd/es/mentions';
import { DeleteOutlined } from '@ant-design/icons'
import { useParams } from 'react-router-dom';

export default function QuotationForm() {
    const { id } = useParams();  // Get 'id' from the URL
    console.log("quotation", id)

    const [showAllRows, setShowAllRows] = useState(false);
    const [form] = Form.useForm();

    const isEditMode = id !== undefined;

    const columns = [
        {
            title: "Id",
            dataIndex: 'Id',
            key: 'Id',
            render: (text, record, index) => index + 1,
        },
        {
            title: "Shipping Line",
            dataIndex: 'ShippingLine',
            key: 'ShippingLine',
            render: (text, record) => (
                <Select
                    defaultValue={text}
                    style={{ width: '100%' }}
                >
                    <Option value="User1">User1</Option>
                    <Option value="User2">User2</Option>
                    <Option value="User3">User3</Option>
                </Select>
            ),
        },
        {
            title: "Destination",
            dataIndex: 'Destination',
            key: 'Destination',
            render: (text, record) => (
                <Select
                    defaultValue={text}
                    style={{ width: '100%' }}
                >
                    <Option value="User1">User1</Option>
                    <Option value="User2">User2</Option>
                    <Option value="User3">User3</Option>
                </Select>
            ),
        },

        {
            title: "Container Size",
            dataIndex: 'Container Size',
            key: 'ContainerSize',
            render: (text, record) => (
                <Select
                    defaultValue={text}
                    style={{ width: '100%' }} >
                    <Option value="SUV">SUV</Option>
                    <Option value="Sedan">Sedan</Option>
                    <Option value="Truck">Truck</Option>
                </Select>
            ),
        },
        {
            title: "Fright Charges",
            dataIndex: "Fright Charges",
            key: "FrightCharges",
            render: (text) => (
                <Input
                    defaultValue={text}
                    style={{ width: "100%" }}
                />
            ),
        },
        {
            title: "No Of Vehicles",
            dataIndex: "No Of Vehicles",
            key: "NoOfVehicles",
            render: (text) => (
                <Input
                    defaultValue={text}
                    style={{ width: "100%" }}
                />
            ),
        },
        {
            title: "Vehicle Type",
            dataIndex: 'Vehicle Type',
            key: 'Vehicle Type',
            render: (text, record) => (
                <Select
                    defaultValue={text}
                    style={{ width: '100%' }}
                >
                    <Option value="Petrol">Petrol</Option>
                    <Option value="Diesel">Diesel</Option>
                    <Option value="Electric">Electric</Option>
                </Select>
            ),
        },
        {
            title: "Loading Charges",
            dataIndex: "Loading Charges",
            key: "LoadingCharges",
            render: (text) => (
                <Input
                    defaultValue={text}
                    style={{ width: "100%" }}
                />
            ),
        },
        {
            title: "Is DG", // New checkbox column
            dataIndex: "Is DG",
            key: "Is DG",
            render: (text, record) => (
                <Checkbox
                    defaultChecked={text}
                    onChange={(e) => {
                        // Handle checkbox change if needed (e.g. update the record)
                        console.log('Checkbox value:', e.target.checked);
                    }}
                />
            ),
        },
        {
            title: (<div>
                <DeleteOutlined style={{ fontSize: "18px" }} />
            </div>),
            key: 'Delete',
            render: (text, { _id }) => (
                <Button type='primary' size="small">
                    <DeleteOutlined />
                </Button>
            )
        },

    ]
    const data = [
        {
            key: '13574',
            ShippingLine: '',

            Destination: '',
            ContainerSize: '',
            Vehicle: '',
            FrightCharges: '',
            NoOfVehicles: '',
            LoadingCharges: "",

        },
        {
            key: '13574',
            ShippingLine: '',

            Destination: '',
            ContainerSize: '',
            Vehicle: '',
            FrightCharges: '',
            NoOfVehicles: '',
            LoadingCharges: "",

        },
        {
            key: '13574',
            ShippingLine: '',

            Destination: '',
            ContainerSize: '',
            Vehicle: '',
            FrightCharges: '',
            NoOfVehicles: '',
            LoadingCharges: "",

        },
    ]
    const handlePlusClick = () => {
        setShowAllRows(true);
    };
    const displayedData = showAllRows ? data : data.slice(0, 2);

    return (
        <div style={{ padding: '15px', border: '1px solid #ccc', borderRadius: '8px', backgroundColor: "#FFFFFF" }}>
            <Form
                requiredMark={false}
                form={form}
                layout="vertical"
                initialValues={{ layout: "vertical" }}
            >
                <Row gutter={24} >
                    <Col span={24}>
                        <h1 style={{ fontWeight: 'bold', }} className='pt-2  pb-5'> {isEditMode ? 'Edit Quotation' : 'Add Quotation '}</h1>
                    </Col>
                    <Col span={6}>
                        <Form.Item
                            label="Select Customer "
                            name="Select Customer"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please input!',
                                },
                            ]}
                        >
                            <Select placeholder="OTHER" />
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item label="Quotation Date ">
                            <DatePicker style={{ width: '100%' }} placeholder="mm/dd/yyyy" />
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item label="Valid Date ">
                            <DatePicker style={{ width: '100%' }} placeholder="mm/dd/yyyy" />
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item label="Chassis Days">
                            <Input placeholder="" />
                        </Form.Item>
                    </Col >
                    <Col span={6}>
                        <Form.Item label="Chassis Charges">
                            <Input placeholder="" />
                        </Form.Item>
                    </Col >
                    <Col span={6}>
                        <Form.Item label="Storage Charges">
                            <Input placeholder="" />
                        </Form.Item>
                    </Col >
                    <Col span={6}>
                        <Form.Item label="Storage Days">
                            <Input placeholder="" />
                        </Form.Item>
                    </Col >
                    <Col span={6}>
                        <Form.Item label="Payment Days  ">
                            <Input placeholder="" />
                        </Form.Item>
                    </Col >

                </Row>

                <Row gutter={24} style={{ marginTop: '10px' }}>
                    <Col span={24}>
                        <p>Payment Collection Type</p>
                    </Col>
                    <Col span={3}>
                        <Form.Item name="ETA" valuePropName="checked">
                            <Checkbox>ETA</Checkbox>
                        </Form.Item>
                    </Col>
                    <Col span={4}>
                        <Form.Item name="InvoiceTerms" valuePropName="checked">
                            <Checkbox>Invoice Terms</Checkbox>
                        </Form.Item>
                    </Col>
                </Row>
                <div style={{ padding: '10px', border: '1px solid #ccc', borderRadius: '8px', backgroundColor: "#FFFFFF", margin: 40, marginTop: 10 }}>
                    <Table columns={columns} scroll={{ x: "max-content" }} dataSource={displayedData} pagination={false} rowKey="Id" style={{ marginTop: '10px' }} />

                    <div style={{ marginTop: 20 }} className='flex flex-col justify-center items-center'>
                        <CiSquarePlus style={{ cursor: 'pointer', fontSize: '25px' }} onClick={handlePlusClick} />
                    </div>

                </div>
                <Form.Item style={{ textAlign: 'center', marginTop: 30 }}>
                    <Button type="primary" style={{ width: '50%' }}> {isEditMode ? 'EDIT QUOTATION' : 'ADD QUOTATION '}</Button>
                </Form.Item>

            </Form>
        </div>
    )
}
