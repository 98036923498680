import { Button, Col, Form, Input, Row, Select, Table } from "antd";
import React from "react";

function GenerateLoadPlan() {
    const columns = [
        {
          title: "S.no",
          dataIndex: 'Sno',
          key: 'Sno',
          render: (text, record, index) => index + 1,
        },
        {
          title: "Ref#",
          dataIndex: 'Ref',
          key: 'Ref',
        },
        {
          title: "Year",
          dataIndex: 'Year',
          key: 'Year',
        },
        {
          title: "Make/Model",
          dataIndex: 'MakeModel',
          key: 'MakeModel',
        },
        {
          title: "Vin Numbers",
          dataIndex: 'VinNumbers',
          key: 'VinNumbers',
        },
        {
          title: "Keys",
          dataIndex: 'Keys',
          key: 'Keys',
        },
      
      ];
      const data= [
        {
          Sno: "1",
          Ref: "LT-75",
          Year: "2024",
          MakeModel: "FORD / SUPER DUTY F550",
          VinNumbers: "1FDUF5HT0RDA27180",
          Keys: "YES",
        },
        {
            Sno: "1",
            Ref: "LT-75",
            Year: "2024",
            MakeModel: "FORD / SUPER DUTY F550",
            VinNumbers: "1FDUF5HT0RDA27180",
            Keys: "YES",
          },
      ]
  return (
    <div style={{
        padding: '12px 14px',
        border: '1px solid #ccc',
        borderRadius: '8px',
        backgroundColor: '#FFFFFF',
        paddingBottom: 20
      }}>
        <Form
          requiredMark={false}
          layout="vertical"
          initialValues={{ layout: "vertical" }}
        >
          <Row gutter={24}>
            <Col span={5}>
              <Form.Item label="Load Number">
                <Input placeholder="" />
              </Form.Item>
            </Col>
            <Col span={5}>
              <Form.Item label="Container Number">
                <Input placeholder="" />
              </Form.Item>
            </Col>
            <Col span={5}>
              <Form.Item label="Seal Number">
                <Input placeholder="" />
              </Form.Item>
            </Col>
            <Col span={4}>
              <Form.Item
                label="Container Size"
                name="Container Size"
                rules={[
                  {
                    required: true,
                    message: "Please input!",
                  },
                ]}
              >
                <Select placeholder="" />
              </Form.Item>
            </Col>
            <Col span={5}>
              <Form.Item label="Verifier Name">
                <Input placeholder="" />
              </Form.Item>
            </Col>
          </Row>
       
      
        <Table
          columns={columns}
          scroll={{ x: "max-content" }}
          dataSource={data}
          style={{ marginTop: '20px' }}
        />
        
<Row gutter={16} align="bottom">

        <Col span={12}>
 <Form.Item label="Special Note:">
    <Input.TextArea placeholder="Enter special notes here..." rows={5} />
  </Form.Item>
        </Col>

        <Col span={12} style={{textAlign:"right"}}>
  <Form.Item>
    <Button type="primary"  style={{  width: '70%'}}>
      Generate PDF
    </Button>
  </Form.Item>
  </Col>
</Row>
</Form>
      </div>
      
  );
}

export default GenerateLoadPlan;
