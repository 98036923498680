import React, { useState } from 'react';
import { Col, Form, Input, Row, DatePicker ,Table,Button} from 'antd';
import { Select } from 'antd';
import { DeleteOutlined } from '@ant-design/icons'
import { CiSquarePlus } from "react-icons/ci";


const { Option } = Select;

function AddRecepit() {
    const [showAllRows, setShowAllRows] = useState(false);
    const [form] = Form.useForm();
    const columns = [
        {
            title: "#",
            dataIndex: '#',
            key: '#',
            render: (text, record, index) => index + 1,
        },
        {
            title: "Invoice Number",
            dataIndex: 'Invoice_Number',
            key: 'Invoice_Numberndor',
            render: (text, record) => (
                <Select
                    defaultValue={text}
                    style={{ width: '100%' }}
                >
                    <Option value="User1">User1</Option>
                    <Option value="User2">User2</Option>
                    <Option value="User3">User3</Option>
                </Select>
            ),
        },
        {
            title: "Description",
            dataIndex: "Description",
            key: "Description",
            render: (text) => (
              <Input.TextArea
                defaultValue={text}
                style={{ width: "100%" }}
                rows={3} // Adjust the number of rows to control the height
              />
            ),
          },
        {
            title: "Invoice Amount($)",
            dataIndex: "Invoice_Amount($)",
            key: "Invoice_Amount($)",
            render: (text) => (
                <Input
                    defaultValue={text}
                    style={{ width: "100%" }}
                />
            ),
        },
        {
            title: "Advance Amount($)",
            dataIndex: "Advance_Amount($)",
            key: "Advance_Amount($)",
            render: (text) => (
                <Input
                    defaultValue={text}
                    style={{ width: "100%" }}
                />
            ),
        }, {
            title: "Collection Amount($)",
            dataIndex: "Collection_Amount($)",
            key: "Collection_Amount($)",
            render: (text) => (
                <Input
                    defaultValue={text}
                    style={{ width: "100%" }}
                />
            ),
        }, {
            title: "Balance($)",
            dataIndex: "Balance($)",
            key: "Balance($)",
            render: (text) => (
                <Input
                    defaultValue={text}
                    style={{ width: "100%" }}
                />
            ),
        },
        {
            title: "Payment Status",
            dataIndex: "Payment Status",
            key: "Payment Status",
            render: (text) => (
                <Input
                    defaultValue={text}
                    style={{ width: "100%" }}
                />
            ),
        },
        {
            title: (<div>
                <DeleteOutlined style={{ fontSize: "18px" }} />
            </div>),
            key: 'Delete',
            render: (text, { _id }) => (
                <Button type='primary' size="small">
                    <DeleteOutlined />
                </Button>
            )
        },
    ]
    const data = [
        {
            key: '13574',
            ShippingLine: '',

            Destination: '',
            ContainerSize: '',
            Vehicle: '',
            FrightCharges: '',
            NoOfVehicles: '',
            LoadingCharges: "",

        },
        {
            key: '13574',
            ShippingLine: '',

            Destination: '',
            ContainerSize: '',
            Vehicle: '',
            FrightCharges: '',
            NoOfVehicles: '',
            LoadingCharges: "",

        },

    ]
    const columns2 = [
        {
            title: "#",
            dataIndex: '#',
            key: '#',
            render: (text, record, index) => index + 1,
        },
        {
            title: "Advanced Number",
            dataIndex: "Advanced_Number",
            key: "Advanced_Number",
            render: (text) => (
                <Input
                    defaultValue={text}
                    style={{ width: "100%" }}
                />
            ),
        },
       
        {
            title: "Description",
            dataIndex: "Description",
            key: "Description",
            render: (text) => (
              <Input.TextArea
                defaultValue={text}
                style={{ width: "100%" }}
                rows={3} // Adjust the number of rows to control the height
              />
            ),
          },
      
        {
            title: "Collection Amount($)",
            dataIndex: "Collection_Amount($)",
            key: "Collection_Amount($)",
            render: (text) => (
                <Input
                    defaultValue={text}
                    style={{ width: "100%" }}
                />
            ),
        }, 
        {
            title: (<div>
                <DeleteOutlined style={{ fontSize: "18px" }} />
            </div>),
            key: 'Delete',
            render: (text, { _id }) => (
                <Button type='primary' size="small">
                    <DeleteOutlined />
                </Button>
            )
        },
    ]
    const data2 = [
        {
            key: '13574',
            ShippingLine: '',

            Destination: '',
            ContainerSize: '',
            Vehicle: '',
            FrightCharges: '',
            NoOfVehicles: '',
            LoadingCharges: "",

        },
        {
            key: '13574',
            ShippingLine: '',

            Destination: '',
            ContainerSize: '',
            Vehicle: '',
            FrightCharges: '',
            NoOfVehicles: '',
            LoadingCharges: "",

        },

    ]
    const handlePlusClick = () => {
        setShowAllRows(true);
    };
    const displayedData = showAllRows ? data : data.slice(0, 1);
    const displayedData2 = showAllRows ? data2 : data2.slice(0, 1);

    return (
        <div
            style={{
                padding: '0 14px',
                border: '1px solid #ccc',
                borderRadius: '8px',
                backgroundColor: '#FFFFFF',
            }}
        >
            <Row
                gutter={26}
                style={{
                    // backgroundColor: '#FBFBFB',
                    marginBottom: 15,
                    padding: '10px',
                    borderRadius: '4px',
                    borderBottom: '1px solid #E8E8E9',
                }}
            >
                <Col span={12}>
                    <h1 style={{ fontWeight: 'bold' }}>Add Receipt</h1>
                </Col>
            </Row>

            <Form
                requiredMark={false}
                layout="vertical"
                form={form} >
                <Row gutter={24}>
                    <Col span={6}>
                        <Form.Item
                            label="Customer Name"
                            name="CustomerName"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please input!',
                                },
                            ]}
                        >
                            <Select placeholder="No Customer" />
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item label="Customer Ref. #">
                            <Input placeholder="" />
                        </Form.Item>
                    </Col>

                    <Col span={6}>
                        <Form.Item label="Receipt Date">
                            <DatePicker style={{ width: '100%' }} placeholder="Select Date" />
                        </Form.Item>
                    </Col>

                    <Col span={6}>
                        <Form.Item label="Collection Date">
                            <DatePicker style={{ width: '100%' }} placeholder="Select Date" />
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item
                            label="Collection Mode"
                            name="CollectionMode"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please input!',
                                },
                            ]}
                        >
                            <Select placeholder="No Customer" />
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item
                            label="Bank"
                            name="Bank"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please input!',
                                },
                            ]}
                        >
                            <Select placeholder="No Customer" />
                        </Form.Item>
                    </Col>

                    <Col span={8}>
                        <Form.Item
                            label="Inter-Company"
                            name="Inter-Company"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please input!',
                                },
                            ]}
                        >
                            <Select placeholder="No Customer" />
                        </Form.Item>
                    </Col>
           
<Col span={24}>

                <div style={{ padding: '10px', border: '1px solid #ccc', borderRadius: '8px', backgroundColor: "#FFFFFF",  marginTop: 60 }}>
                    <h1 style={{fontWeight:"bold"}}>Invoice Collection</h1>
                    <Table columns={columns} scroll={{ x: "max-content" }} dataSource={displayedData} pagination={false} rowKey="Id" style={{ marginTop: '10px' }} />
                    <div style={{ marginTop: 20 }} className='flex flex-col justify-center items-center'>
                        <CiSquarePlus style={{ cursor: 'pointer', fontSize: '25px' }} onClick={handlePlusClick} />
                    </div>
                    </div>
</Col>

<Col span={24}>
                    <div style={{ padding: '10px', border: '1px solid #ccc', borderRadius: '8px', backgroundColor: "#FFFFFF",  marginTop: 80 }}>
                    <h1 style={{fontWeight:"bold"}}>Advanced Collection </h1>
                    <Table columns={columns2} scroll={{ x: "max-content" }} dataSource={displayedData2} pagination={false} rowKey="Id" style={{ marginTop: '10px' }} />
                    <div style={{ marginTop: 20 }} className='flex flex-col justify-center items-center'>
                        <CiSquarePlus style={{ cursor: 'pointer', fontSize: '25px' }} onClick={handlePlusClick} />
                    </div>
                    </div>
                    </Col>
                    </Row>
            </Form>
        </div>
    );
}

export default AddRecepit;
