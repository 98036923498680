import React from "react";
import SignForm from "../../components/SignForm";

const Signup = () => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        height: "100vh",
        border: "12px #ccc",
        boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
        borderRadius: "8px",
        overflow: "hidden",
      }}
    >
      {/* Left section with heading, paragraph, and image */}
      <div
        style={{
          flex: 1,
          border: "12px #ccc",
          padding: "20px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          borderRadius: "8px",
          overflow: "hidden",
        }}
      >
        <div style={{ width: "100%", maxWidth: "400px" }}>
          <SignForm />
        </div>
      </div>
      <div
        style={{
          flex: 1,
          border: "12px #ccc",
          padding: "15px",
          paddingTop: "2px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          paddingLeft: "50px",
          background: `
      linear-gradient(140.09deg, rgba(0, 0, 0, 0.9) 0%, rgba(0, 0, 0, 0.95) 100%), 
      url('bg.png')`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          borderRadius: "8px",
          overflow: "hidden",
        }}
      >
        <p className="text-white mb-4 ml-4 text-sm font-bold">START FOR FREE</p>
        <h1
          style={{
            fontSize: "48px",
            fontWeight: "bold",
            marginBottom: "10px",
            marginLeft: "10px",
            color: "white",
            lineHeight: "50px"
          }}
        >
          Create
          <div style={{ fontSize: "48px" }}>
            an {" "} account
          </div>
        </h1>
        <p
          style={{
            fontSize: "16px",
            lineHeight: "1.5",
            marginBottom: "15px",
            color: "white",
            marginLeft: "10px",
          }}
        >
          Take the first step toward efficient and hassle-free management. Sign up now to unlock a suite of powerful tools that bring real-time data, task management, and productivity enhancements right to your fingertips. Whether you're organizing, tracking, or collaborating, Ctrade is designed to keep you connected and in control.  </p>
        <p
          style={{
            fontSize: "16px",
            lineHeight: "1.5",
            marginBottom: "15px",
            color: "white",
            marginLeft: "10px",
          }}
        >
          Start your journey to smarter management with us!
        </p>
      </div>
    </div>
  );
};

export default Signup;
