
import React, { useState } from 'react'
import { Table, Space, Row, Col, Input, Select, Button, Form, Modal, Checkbox, Upload } from 'antd';
import { CiSquarePlus } from "react-icons/ci";
import { DeleteOutlined, UploadOutlined } from '@ant-design/icons'
import { Option } from 'antd/es/mentions';

const data = [
    {
        key: '1',
        Id: '1',
        CarStatus: 'Available',
        User: 'User1',
        CuttingCar: false,
        Product: 'Product1',
        Vehicle: 'Toyota',
        Type: 'SUV',
        EngineType: 'Petrol',
        Year: '2023',
    },
    {
        key: '1',
        Id: '1',
        CarStatus: 'Available',
        User: 'User1',
        CuttingCar: true,
        Product: 'Product1',
        Vehicle: 'Toyota',
        Type: 'SUV',
        EngineType: 'Petrol',
        Year: '2023',
    },
    {
        key: '1',
        Id: '1',
        CarStatus: 'Available',
        User: 'User1',
        CuttingCar: false,
        Product: 'Product1',
        Vehicle: 'Toyota',
        Type: 'SUV',
        EngineType: 'Petrol',
        Year: '2023',
    },
    {
        key: '1',
        Id: '1',
        CarStatus: 'Available',
        User: 'User1',
        CuttingCar: true,
        Product: 'Product1',
        Vehicle: 'Toyota',
        Type: 'SUV',
        EngineType: 'Petrol',
        Year: '2023',
    },

];
function AddVehical() {
    const [form] = Form.useForm();
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedColumn, setSelectedColumn] = useState(null);
    const [columns, setColumns] = useState([
        {
            title: "Id",
            dataIndex: 'Id',
            key: '_id',
            render: (text, record, index) => index + 1,
        },
        {
            title: "Car Status",
            dataIndex: 'CarStatus',
            key: 'CarStatus',
        },
        {
            title: "User",
            dataIndex: 'User',
            key: 'User',
            render: (text, record) => (
                <Select
                    defaultValue={text}
                    onChange={(value) => handleSelectChange(value, record, 'User')}
                    style={{ width: '100%' }}
                >
                    <Option value="User1">User1</Option>
                    <Option value="User2">User2</Option>
                    <Option value="User3">User3</Option>
                </Select>
            ),
        },
        {
            title: "Lot#",
            dataIndex: 'Lot#',
            key: 'Lot#',
        },
        {
            title: "Cutting Car",
            dataIndex: 'CuttingCar',
            key: 'CuttingCar',
            render: (checked, record) => (
                <Checkbox checked={record.CuttingCar} />
            ),
        },
        {
            title: "Product",
            dataIndex: 'Product',
            key: 'Product',
        },
        {
            title: "Vehicle",
            dataIndex: 'Vehicle',
            key: 'Vehicle',
        },
        {
            title: "Type",
            dataIndex: 'Type',
            key: 'Type',
            render: (text, record) => (
                <Select
                    defaultValue={text}
                    onChange={(value) => handleSelectChange(value, record, 'Type')}
                    style={{ width: '100%' }} >
                    <Option value="SUV">SUV</Option>
                    <Option value="Sedan">Sedan</Option>
                    <Option value="Truck">Truck</Option>
                </Select>
            ),
        },
        {
            title: "Engine Type",
            dataIndex: 'EngineType',
            key: 'EngineType',
            render: (text, record) => (
                <Select
                    defaultValue={text}
                    onChange={(value) => handleSelectChange(value, record, 'EngineType')}
                    style={{ width: '100%' }}
                >
                    <Option value="Petrol">Petrol</Option>
                    <Option value="Diesel">Diesel</Option>
                    <Option value="Electric">Electric</Option>
                </Select>
            ),
        },
        {
            title: "Year",
            dataIndex: 'Year',
            key: 'Year',
        },
        {
            title: "Chasis No",
            dataIndex: 'ChasisNo',
            key: 'ChasisNo',
        }, {
            title: "Date Rcd",
            dataIndex: 'DateRcd',
            key: 'DateRcd',
        }, {
            title: "Value",
            dataIndex: 'Value',
            key: 'Value',
        }, {
            title: "Weight",
            dataIndex: 'Weight',
            key: 'Weight',
        }, {
            title: "Operating Port",
            dataIndex: 'OperatingPort',
            key: 'OperatingPort',
        },
        {
            title: "BOS",
            dataIndex: 'BOS',
            key: 'BOS',
            render: (text, record) => (
                <Select
                    defaultValue={text}
                    onChange={(value) => handleSelectChange(value, record, 'BOS')}
                    style={{ width: '100%' }}
                >
                    <Option value="Petrol">Petrol</Option>
                    <Option value="Diesel">Diesel</Option>
                    <Option value="Electric">Electric</Option>
                </Select>
            ),
        },
        {
            title: "Title Owner",
            dataIndex: 'TitleOwner',
            key: 'TitleOwner',
        },{
            title: "Title",
            dataIndex: 'Title',
            key: 'Title',
        },{
            title: "Title Number",
            dataIndex: 'TitleNumber',
            key: 'TitleNumber',
        },{
            title: "Keys",
            dataIndex: 'Keys',
            key: 'Keys',
        },{
            title: "Notes",
            dataIndex: 'Notes',
            key: 'Notes',
        },{
            title: "File Ref",
            dataIndex: 'FileRef',
            key: 'FileRef',
        },
        {
            title: "File Upload",
            dataIndex: 'FileUpload',
            key: 'FileUpload',
            render: (text, record) => (
                <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                    <Upload
                        showUploadList={false}
                        beforeUpload={(file) => {
                            handleFileChange(file, record);
                            return false; // Prevent default upload behavior
                        }}
                    >
                        <Button>
                            Choose File
                        </Button>
                    </Upload>
                    <span>
                        {record.FileUpload ? record.FileUpload.name : "No file chosen"}
                    </span>
                </div>
            ),}
    ])

    const [tableData, setTableData] = useState([
        {
            key: '1',
            Id: '1',
            CarStatus: 'Available',
            User: 'User1',
            CuttingCar: false,
            Product: 'Product1',
            Vehicle: 'Toyota',
            Type: 'SUV',
            EngineType: 'Petrol',
            Year: '2023',
        },
        {
            key: '1',
            Id: '1',
            CarStatus: 'Available',
            User: 'User1',
            CuttingCar: true,
            Product: 'Product1',
            Vehicle: 'Toyota',
            Type: 'SUV',
            EngineType: 'Petrol',
            Year: '2023',
        },
        {
            key: '1',
            Id: '1',
            CarStatus: 'Available',
            User: 'User1',
            CuttingCar: false,
            Product: 'Product1',
            Vehicle: 'Toyota',
            Type: 'SUV',
            EngineType: 'Petrol',
            Year: '2023',
        },
        {
            key: '1',
            Id: '1',
            CarStatus: 'Available',
            User: 'User1',
            CuttingCar: true,
            Product: 'Product1',
            Vehicle: 'Toyota',
            Type: 'SUV',
            EngineType: 'Petrol',
            Year: '2023',
        },
    
    ])
        
    const handleFileChange = (file, record) => {
        const updatedData = tableData.map((item) => {
            if (item.key === record.key) {
                return { ...item, FileUpload: file };
            }
            return item;
        });
        setTableData(updatedData);
    };

    const showModal = () => {
        setIsModalVisible(true);
    };
    const handleCancel = () => {
        setIsModalVisible(false);
        setIsModalOpen(false);
        form.resetFields();
    };
    const handlePlusClick = () => {
        setIsModalOpen(true);
    };
    const handleColumnSelect = (value) => {
        setSelectedColumn(value);
    };
    const handleProcess = () => {
        // Filter out the column with the selected key
        setColumns(prevColumns => prevColumns.filter(col => col.key !== selectedColumn));
        setIsModalVisible(false);
    };
    const handleSelectChange = (value, record, field) => {
        console.log(`Selected value: ${value} for record: `, record, ` field: ${field}`);
    };

    return (
        <div style={{ padding: '15px', border: '1px solid #ccc', borderRadius: '8px', backgroundColor: "#FFFFFF" }}>
            <Row gutter={24} style={{ borderBottom: '1px solid #E8E8E9', }}>
                <Col span={24}>
                    <h1 style={{ fontWeight: "bold", }}>Vehicles</h1>
                </Col>
            </Row>

            <div style={{ marginTop: 20 }}>
                <Form
                    requiredMark={false}
                    layout="horizontal"
                >
                    <Row gutter={[16, 24]}>
                        {/* First Column: Vin Input */}
                        <Col xs={24} sm={14}>
                            <Form.Item label="Approved Appointment Cars Vin #">
                                <Input placeholder="" />
                            </Form.Item>
                        </Col>

                        {/* Second Column: Add Button */}
                        <Col xs={24} sm={10}>
                            <Form.Item style={{ display: 'flex', justifyContent: 'center' }}>
                                <Button type="primary" style={{ textAlign: "center" }}>
                                    Add
                                </Button>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </div>
            <Row
      gutter={[24, 16]}
      style={{
        marginTop: "10px",
        background: "#F2F2F7",
        padding: "10px",
        borderRadius: "4px",
      }}
      align="middle"
    >
      {/* Left section */}
      <Col xs={24} md={8}>
        <Space size={10}>
          <CiSquarePlus
            style={{ cursor: "pointer", fontSize: "22px" }}
            onClick={handlePlusClick}
          />
          <DeleteOutlined
            style={{ cursor: "pointer", fontSize: "19px" }}
            onClick={showModal}
          />
        </Space>
      </Col>

      {/* Right section */}
      <Col xs={24} md={16} style={{ textAlign: "right" }}>
        <Space wrap>
          <Button type="primary">Add New Expense</Button>
          <Button type="primary">Add Payable Payments</Button>
        </Space>
      </Col>
    </Row>

            {/* Plus modal */}
            <Modal
                title={<div style={{ textAlign: 'center' }}>Add New Column</div>}
                visible={isModalOpen}
                onCancel={handleCancel}
                footer={null}
                bodyStyle={{
                    padding: "20px",

                }}
                width={400}
            >
                <Form form={form} layout="vertical"
                    requiredMark={false}>
                    <Form.Item

                        label="Column Name"
                        name="columnName"
                        rules={[{ required: true, message: 'Please enter the column name!' }]}
                    >
                        <Input placeholder="i.e Customer Name" />
                    </Form.Item>

                    <Form.Item
                        label="After"
                        name="After"
                        rules={[{ required: true, message: 'Please select a file name!' }]}
                    >
                        <Select placeholder="User Id">
                            <Option value="File1">File1</Option>
                            <Option value="File2">File2</Option>
                            <Option value="File3">File3</Option>
                        </Select>
                    </Form.Item>

                    <div className='flex justify-center gap-3 mt-9'>
                        <Button onClick={handleCancel} style={{ width: "50%" }}>Cancel</Button>
                        <Button type="primary" onClick={handleProcess} style={{ width: "50%" }}>
                            Process
                        </Button>
                    </div>
                </Form>
            </Modal>

            {/* delete modal */}
            <Modal
                title={<div style={{ textAlign: 'center' }}>Delete Column</div>}
                visible={isModalVisible}
                onCancel={handleCancel}
                footer={null}
                bodyStyle={{
                    padding: "20px",

                }}
                width={400} >
                <Form form={form} layout="vertical"
                    requiredMark={false}>
                    <Form.Item
                        label="Select Column"
                        name="Select Column"
                        rules={[{ required: true, message: 'Please select a file name!' }]} className='mt-5'>

                        <Select
                            placeholder="Select a Column to Delete"
                            onChange={handleColumnSelect}
                        >
                            {columns.map(col => (
                                <Option key={col.key} value={col.key}>
                                    {col.title}
                                </Option>
                            ))}
                        </Select>
                    </Form.Item>
                    <div className='flex justify-center gap-3 mt-10'>
                        <Button onClick={handleCancel} style={{ width: "50%" }}>Cancel</Button>
                        <Button type="primary" onClick={handleProcess} style={{ width: "50%" }}>
                            Process
                        </Button>
                    </div>
                </Form>
            </Modal>

            <Table columns={columns} scroll={{ x: "max-content" }} dataSource={tableData} rowKey="Id" style={{ marginTop: '10px' }} />
        </div>
    )
}
export default AddVehical

