// URL URL
export const LOCAL_URL = "http://192.168.100.162:8000";
// export const LOCAL_URL = "http://192.168.1.17:8000"; 
export const SERVER_URL = "https://be.ctrade.theguls.com";

export const BASE_URL = SERVER_URL;
// Api url
export const API_URL = BASE_URL + '/api'

// Role 
export const ADMIN = 'admin';
export const MEMBER = 'member';
export const CLIENT = 'client'

//vendor details is_dg

export const DG = "DG" //is_dg = 1 
export const NON_DG = "NON-DG" //is_dg = 0 