

import React from 'react'
import { Table, Space, Button, Row, Col, Input, Select, Card, Divider } from 'antd';
import { EyeOutlined, SearchOutlined, EditOutlined, DeleteOutlined } from '@ant-design/icons'
import { Link, useParams } from 'react-router-dom';

const { Meta } = Card;

function ViewCustomer() {

  const {id} = useParams()

  console.log(id)

  const options = [
    {
      value: '10',
      label: '10',
    },
    {
      value: '9',
      label: '9',
    },
  ]
  const columns = [
    {
      title: "#",
      dataIndex: '#',
      key: '#   ',
      render: (text, record, index) => index + 1,
    },
    {
      title: "Status",
      dataIndex: 'Status',
      key: 'Status',
    },
    {
      title: "Product",
      dataIndex: 'Product',
      key: 'Product',
    },
    {
      title: "Vehicle",
      dataIndex: 'Vehicle',
      key: 'Vehicle',
    },
    {
      title: "Year",
      dataIndex: 'Year',
      key: 'Year',
    },
    {
      title: "Chassis Number",
      dataIndex: 'ChassisNumber',
      key: 'ChassisNumber',
    },
    {
      title: "Tracking Number",
      dataIndex: 'TrackingNumber',
      key: 'TrackingNumber',
    },
    {
      title: 'Action',
      key: 'Action',
      render: (text, { _id }) => (
        <Space size='small'>
          <Button type='primary' size="small">
            <EditOutlined />
          </Button>

          <Link to={"/view-vehicle"}>
            <Button type='primary' size="small">
              <EyeOutlined />
            </Button>
          </Link>


          <Button type='primary' size="small">
            <DeleteOutlined />
          </Button>
        </Space >
      )
    },

  ];
  const data = [
    {
      key: 'id',
      Status: "MEHMOOD",
      Product: "60",
      Vehicle: "7$",
      Year: "59",
      ChassisNumber: "Inactive",
      TrackingNumber: "13574",
    },
    {
      key: 'id',
      Status: "MEHMOOD",
      Product: "60",
      Vehicle: "7$",
      Year: "59",
      ChassisNumber: "Inactive",
      TrackingNumber: "13574",
    },
    {
      key: 'id',
      Status: "MEHMOOD",
      Product: "60",
      Vehicle: "7$",
      Year: "59",
      ChassisNumber: "Inactive",
      TrackingNumber: "13574",
    },
    {
      key: 'id',
      Status: "MEHMOOD",
      Product: "60",
      Vehicle: "7$",
      Year: "59",
      ChassisNumber: "Inactive",
      TrackingNumber: "13574",
    },
    {
      key: 'id',
      Status: "MEHMOOD",
      Product: "60",
      Vehicle: "7$",
      Year: "59",
      ChassisNumber: "Inactive",
      TrackingNumber: "13574",
    },
  ]
  return (
    <>
      <Row gutter={24} wrap={false}>
        {/* First Column */}
        <Col flex="280px">
          <Card
            style={{ padding: '15px' }}
          >
            {/* First Section: Name and Email */}
            <Meta
              title="MAHMOOD"
              style={{ textAlign: 'center' }}
            />
            <div style={{ marginTop: '10px', textAlign: 'center' }}>
              <p> wally724@yahoo.com</p>
            </div>
            <Divider />
            <div className='profile-info'>
              <p><strong>Phone:</strong> 12345678900</p>
              <p><strong>Address:</strong> Sharjah</p>
              <p><strong>Town:</strong> Sharjah</p>
              <p><strong>City:</strong> Sharjah</p>
              <p><strong>Country:</strong> United Arab Emirates</p>
            </div>
            <Divider />
            <div style={{ textAlign: 'center' }}>
              <Space>
                <EditOutlined style={{ fontSize: '20px', cursor: 'pointer' }} />
                <DeleteOutlined style={{ fontSize: '20px', cursor: 'pointer' }} />
              </Space>
            </div>
          </Card>
        </Col>
        {/* Second Column */}
        <Col flex="1">
          <div style={{ padding: '15px', border: '1px solid #ccc', borderRadius: '8px', backgroundColor: "#FFFFFF" , }}>
            <Row gutter={12}>
              <Col span={12}>
                <h1 style={{ fontWeight: "bold" }}>Customer Vehicles</h1>
              </Col>
            </Row>
            <Row gutter={24} style={{ marginTop: '20px', background: "#F2F2F7", padding: "10px", borderRadius: '4px' }}>
              <Col span={10}>
                <Space direction="horizontal" size={10}>
                  <p>Show</p>
                  <Select
                    defaultValue="10"
                    options={options}
                    size="small"
                    style={{
                      width: 60,
                      height: 25,
                      fontSize: 14,
                    }}
                  />
                  <p>entries</p>
                </Space>
              </Col>
              <Col span={14} style={{ textAlign: "right" }}>
                <Input
                  placeholder="Search"
                  prefix={<SearchOutlined />}
                  style={{ width: 200 }}
                />
              </Col>
            </Row>
            <Table columns={columns} scroll={{ x: "max-content" }} dataSource={data} style={{ marginTop: '10px' }} />
          </div>
        </Col>
      </Row>
      <style>
        {`
  .profile-info {
    display: flex;
    flex-direction: column; /* Stack items vertically */
    gap: 15px; /* Space between each <p> element */
  }

  .profile-info p {
    display: flex; /* Makes each <p> a flex container */
    align-items: center; /* Vertically align <strong> and text */
  }

  .profile-info strong {
    margin-right: 25px; /* Space between <strong> and <p> content */
    width: 100px; /* You can adjust this value to ensure equal space between all labels */
    text-align: left; /* Optional: Align the <strong> to the left */
  }
`}
      </style>
    </>
  )
}

export default ViewCustomer

