import React from 'react'
import { Table, Space, Button, Row, Col, Input } from 'antd';
import { EditOutlined, SearchOutlined, EyeOutlined, } from '@ant-design/icons'
function LoadingImo() {
    const columns = [
        {
            title: "#",
            dataIndex: '#',
            key: '_id',
            render: (text, record, index) => index + 1,
        },
        {
            title: "Transportation No",
            dataIndex: 'TransportationNo',
            key: 'Transportation No',
        },
        {
            title: "Carrier No",
            dataIndex: 'CarrierNo',
            key: 'CarrierNo',
        },
        {
            title: "Container No",
            dataIndex: 'ContainerNo',
            key: 'ContainerNo',
        },
        {
            title: "Seal No",
            dataIndex: 'SealNo',
            key: 'SealNo',
        },
        {
            title: 'Actions',
            key: 'Actions',
            render: (text, { _id }) => (
                <Space size='small'>
                    <Button type='primary' size="small">
                        <EditOutlined />
                    </Button>
                    <Button type='primary' size="small">
                        <EyeOutlined />
                    </Button>

                </Space >
            )
        },
    ];
    const data = [
        {
            key: '1',
            TransportationNo: '230197307',
            CarrierNo: 'NAM6299735',
            ContainerNo: 'MSKU1140851',
            SealNo: "307315"
        },
        {
            key: '1',
            TransportationNo: '230197307',
            CarrierNo: 'NAM6299735',
            ContainerNo: 'MSKU1140851',
            SealNo: "307315"
        },
        {
            key: '1',
            TransportationNo: '230197307',
            CarrierNo: 'NAM6299735',
            ContainerNo: 'MSKU1140851',
            SealNo: "307315"
        },
        {
            key: '1',
            TransportationNo: '230197307',
            CarrierNo: 'NAM6299735',
            ContainerNo: 'MSKU1140851',
            SealNo: "307315"
        },
    ];
    return (
        <div style={{ padding: '15px', border: '1px solid #ccc', borderRadius: '8px', backgroundColor: "#FFFFFF" }}>
            <Row gutter={24} >
                <Col span={10}  >
                    <h5 className='font-bold'>IMO Generated</h5>
                </Col>
                <Col span={14} style={{ textAlign: "right" }} >
                    <Input
                        placeholder="Search"
                        prefix={<SearchOutlined />}
                       className="w-auto lg:w-2/5"
                    />
                </Col>
            </Row>
            <Table columns={columns} dataSource={data} scroll={{ x: "max-content" }} style={{ marginTop: '5px' }} />
        </div>
    )
}
export default LoadingImo
