import React from 'react'
import { Table, Space,  Button,  Row, Col, Input } from 'antd';
import { SearchOutlined, EyeOutlined } from '@ant-design/icons'
import { Link } from 'react-router-dom';
function LoadingClient() {
    const columns = [
        {
            title: "#",
            dataIndex: '#',
            key: '_id',
            render: (text, record, index) => index + 1,
        },
        {
            title: "Load Number",
            dataIndex: 'LoadNumber',
            key: 'LoadNumber',
        },
        {
            title: "Booking Number",
            dataIndex: 'BookingNumber',
            key: 'BookingNumber',
        },
        {
            title: "Location",
            dataIndex: 'Location',
            key: 'Location',
        },
        {
            title: "Status",
            dataIndex: 'Status',
            key: 'Status',
            render: (text, { _id }) => (

                <Button style={{ backgroundColor: "#E85474 ", color: "white" }}>
                    Reject
                </Button>
            )
        },
        {
            title: 'Actions',
            key: 'Actions',
            render: (text, { _id }) => (
               <Link to={"/loadings/clients/views"}>
                     <Button type='primary' size="small">
                        <EyeOutlined />
                    </Button>
               </Link>           
              
            )
        },
    ];
    const data = [
        {
            key: '1',
            LoadNumber: 'HOUSTON-65877',
            BookingNumber: '54566',
            Location: "HOUSTON"
        },
    ];
    return (
        <div style={{ padding: '15px', border: '1px solid #ccc', borderRadius: '8px', backgroundColor: "#FFFFFF" }}>
            <Row gutter={26}>
                <Col span={10}  >
                    <h5 className='font-bold'>Client Load Plans</h5>
                </Col>
                <Col span={14} style={{ textAlign: "right" }} >
                    <Input
                        placeholder="Search"
                        prefix={<SearchOutlined />}
                        className="w-auto lg:w-2/5"
                    />
                </Col>
            </Row>
            <Table columns={columns} scroll={{ x: "max-content" }} dataSource={data} style={{ marginTop: '5px' }} />
        </div>
    )
}
export default LoadingClient
